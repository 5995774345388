<template>
  <!-- 功能列表 -->
  <el-card>
    <el-row>
      <el-button
        style="background-color: #5e6cf4; color: #fff"
        @click="addDialog = true">添加功能</el-button>
    </el-row>
    <el-table
      v-loading="tableLoading"
      :data="tableData"
      style="width: 100%; margin-bottom: 8px"
      border=""
      :row-style="{ height: '40px' }"
      :cell-style="{ padding: '0', textAlign: 'center' }"
      :header-cell-style="{
        textAlign: 'center',
        backgroundColor: '#f5f7fa',
      }">
      <el-table-column prop="name" label="名称"> </el-table-column>
      <el-table-column prop="tag" label="标识"> </el-table-column>
      <el-table-column prop="type" label="类型"> </el-table-column> 
      <el-table-column prop="component" label="组件路径"> </el-table-column>
      <el-table-column prop="path" label="组件跳转路径"> </el-table-column>
      <el-table-column prop="icon" label="icon名称"> </el-table-column>
      <el-table-column prop="sort" label="菜单等级"> </el-table-column>
      <el-table-column prop="remark" label="备注"> </el-table-column>
      <el-table-column label="操作" width="110">
        <template v-slot="scope">
          <div class="ActionColumn"> 
            <el-link @click="Editdata(scope.row)" class="ActionColumn_h"  type="primary">编辑</el-link>
            <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                icon-color="red"
                title="确定删除吗？"
                @confirm="OKDelGroup(scope.row)" >
              <el-link  class="ActionColumn_h" slot="reference" type="primary">删除</el-link>
            </el-popconfirm>
          </div>
          <!-- <el-row :gutter="0">
            <el-col :span="14">
              <el-button
                type="success"
                plain
                icon="el-icon-edit-outline"
                size="small"
                @click="Editdata(scope.row)"
                >编辑</el-button
              >
            </el-col>
            <el-col :span="10">
              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                icon-color="red"
                title="确定删除吗？"
                @confirm="OKDelGroup(scope.row)"
              >
                <el-button
                  slot="reference"
                  type="danger"
                  plain
                  icon="el-icon-close"
                  size="small"
                  >删除</el-button
                >
              </el-popconfirm>
            </el-col>
          </el-row> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div style="text-align: center">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="postData.current"
        :page-size="postData.size"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 添加权限弹框 -->
    <el-drawer title="添加功能" :visible.sync="addDialog">
      <div class="From_item" style="margin-bottom: 10rpx">
        <div>名称</div>
        <el-input v-model="from.name" placeholder="请输入内容"></el-input>
      </div>
      <div class="From_item">
        <div>标识</div>
        <el-input v-model="from.tag" placeholder="请输入内容"></el-input>
      </div>
      <!-- <div class="From_item">
        <div>地址</div>
        <el-input v-model="from.tag" placeholder="请输入内容"></el-input>
      </div> -->
      <div class="From_item">
        <div>父节点</div>
        <el-select v-model="from.parentId" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="From_item">
        <div>类型</div>
        <el-select v-model="from.type" placeholder="请选择">
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="From_item">
        <div>是否需要操作权限</div>
        <p>
          <el-checkbox v-model="from.needOperationAuth">是</el-checkbox>
        </p>
      </div>
      <div class="From_item">
        <div>组件路径</div>
        <el-input v-model="from.component" placeholder="请输入内容"></el-input>
      </div>
      <div class="From_item">
        <div>组件跳转路径</div>
        <el-input v-model="from.path" placeholder="请输入内容"></el-input>
      </div>
      <div class="From_item">
        <div>菜单序号</div>
        <el-input v-model="from.sort" placeholder="请输入内容" type="number"></el-input>
      </div>      
      <div class="From_item">
        <div>备注</div>
        <el-input v-model="from.remark" placeholder="请输入内容"></el-input>
      </div>
      <!-- </from> -->
      <div class="dialog-footer">
        <el-button @click="addDialog = false">取 消</el-button>
        <el-button type="primary" @click="OKAdd()">确 定</el-button>
      </div>
    </el-drawer>  

    <!-- 编辑框 -->
    <el-drawer title="编辑功能" :visible.sync="EditDialog">
      <div class="From_item" style="margin-bottom: 10rpx">
        <div>名称</div>
        <el-input v-model="EditFrom.name" placeholder="请输入内容"></el-input>
      </div>
      <div class="From_item">
        <div>标识</div>
        <el-input v-model="EditFrom.tag" placeholder="请输入内容"></el-input>
      </div>
      <div class="From_item">
        <div>父节点</div>
        <el-select v-model="EditFrom.parentId" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <!-- <div class="From_item">
        <div>类型</div>
        <el-select v-model="from.name" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div> -->
      <div class="From_item">
        <div>是否需要操作权限</div>
        <p>
          <el-checkbox v-model="EditFrom.hasOperationAuth">是</el-checkbox>
        </p>
      </div>
      <div class="From_item">
        <div>组件路径</div>
        <el-input
          v-model="EditFrom.component"
          placeholder="请输入内容"
        ></el-input>
      </div>
      <div class="From_item">
        <div>组件跳转路径</div>
        <el-input
          v-model="EditFrom.path"
          placeholder="请输入内容"
        ></el-input>
      </div>
      <div class="From_item">
        <div>icon名称</div>
        <el-input
          v-model="EditFrom.icon"
          placeholder="请输入icon名称"
        ></el-input>
      </div>
      <div class="From_item">
        <div>菜单序号</div>
        <el-input v-model="EditFrom.sort" placeholder="请输入内容" type="number"></el-input>
      </div> 
      <div class="From_item">
        <div>备注</div>
        <el-input v-model="EditFrom.remark" placeholder="请输入内容"></el-input>
      </div>
      <!-- </from> -->
      <div class="dialog-footer">
        <el-button @click="EditDialog = false">取 消</el-button>
        <el-button type="primary" @click="OKEdit()">确 定</el-button>
      </div>
    </el-drawer>  

  </el-card>  
</template>

<script>
import {
  OAMDesignList,
  OAMDesign,
  functionPage,
  functionDelete,
  functionEdit,
} from "@/api/EmployeeManagement";
export default {
  data() {
    return {
      tableData: [],
      options: [],
      addDialog: false,
      EditDialog: false,
      from: { 
        component: "",
        name: "",
        hasOperationAuth: false,
        parentId: "",
        remark: "",
        tag: "",
        type: 1,
        sort:1
      },
      postData: {
        current: 0,
        size: 10,
      },
      EditFrom: {},
      checked: false,
      total: 0,
      tableLoading: false, //表格loadding
      typeList:[
        {label:'页面',value:1},
        {label:'按钮',value:2}
      ]
    };
  },
  created() {
    this.getTypeList();
    this.getfunctionPage();
  },
  methods: {
    handleSizeChange(newsize) {
      this.postData.size = newsize;
      // 重新获取数据
      this.getfunctionPage();
      // this.getTableList(0);
    },
    handleCurrentChange(newpage) {
      this.postData.current = newpage;
      // 重新获取数据
      this.getfunctionPage();
      // this.getTableList(0);
    },
    
    // 获取节点列表
    async getTypeList() {
      let res = await OAMDesignList();
      // console.log(res);
      if (!res.lenght) {
        this.options = res.map((o) => {
          return {
            value: o.functionId,
            label: o.name,
          };
        });
      }
    },

    // 获取表格数据
    async getfunctionPage() {
      this.tableLoading = true;
      let res = await functionPage(this.postData);
      console.log(res);
      this.tableData = res.records;
      this.total = parseInt(res.total);
      this.tableLoading = false;
    },

    // 添加
    async OKAdd(from) {
      console.log(this.from);
      this.from.sort = parseInt(this.from.sort)
      // return
      let res = await OAMDesign(this.from);
      console.log(res);
      if (res.status === "success") {
        this.$message.success(res.msg);
      } else {
        this.$message.error(res.msg);
      }
      this.getfunctionPage();
      this.addDialog = false;
    },

    // 删除
    async OKDelGroup(from) {
      let res = await functionDelete(from);
      console.log(res);
      if (res.status === "success") {
        this.$message.success(res.msg);
      } else {
        this.$message.error(res.msg);
      }
    },

    // 编辑弹框
    async Editdata(from) {
      this.EditFrom = JSON.parse(JSON.stringify(from)) ;
      this.EditDialog = true;
    },

    // 确认编辑
    async OKEdit() {
      let res = await functionEdit(this.EditFrom);
      console.log(res);
      this.EditDialog = false;
      if (res.status == "success") {
        this.$message.success(res.msg);
        this.getfunctionPage();
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style scoped>
.el-row {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 15px;
}
/* 添加表单样式 */
.From_item {
  width: 90%;
  height: 70rpx;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  /* background-color: aqua; */
}
.From_item div {
  width: 160px;
}
.From_item .el-input,
.From_item .el-select,
.From_item p {
  width: 70%;
}
.dialog-footer {
  margin-top: 10px;
  text-align: center;
}

</style>