<template>
  <!-- 权限组 -->
  <el-card>
    <!-- 权限组 -->
    <el-row>
      <el-button
        style="background-color: #5e6cf4; color: #fff"
        @click="dialogTableVisible = true"
        >添加分组</el-button
      >
    </el-row>

    <el-table
      :data="tableData"
      v-loading="tableLoading"
      style="width: 100%; margin-bottom: .5rem"
      border=""
      :row-style="{ height: '2.5rem' }"
      :cell-style="{ padding: '0', textAlign: 'center' }"
      :header-cell-style="{
        textAlign: 'center',
        backgroundColor: '#f5f7fa',
      }" >
      <el-table-column prop="groupName" label="分组名称"> </el-table-column>
      <el-table-column prop="groupTag" label="分组标记"> </el-table-column>
      <el-table-column prop="author" label="权限功能"> </el-table-column>
      <el-table-column prop="groupName" label="创建人"> </el-table-column>
      <el-table-column prop="groupName" label="创建时间"> </el-table-column>
      <el-table-column label="操作" width="110">
        <template v-slot="scope">
          <div class="ActionColumn"> 
            <el-link @click="paymentByTrasferClick(scope.row)" class="ActionColumn_h"  type="primary">编辑</el-link>
            <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                icon-color="red"
                title="确定删除吗？"
                @confirm="OKDelGroup(scope.row)" >
            <el-link  class="ActionColumn_h" slot="reference" type="primary">删除</el-link>
          </el-popconfirm>
          </div>
          <!-- <el-row :gutter="0">
            <el-col :span="14">
              <el-button
                type="success"
                plain
                icon="el-icon-edit-outline"
                size="small"
                @click="paymentByTrasferClick(scope.row)"
                >编辑</el-button>
            </el-col>
            <el-col :span="10">
              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                icon-color="red"
                title="确定删除此分组吗？"
                @confirm="OKDelGroup(scope.row)"
              >
                <el-button
                  slot="reference"
                  type="danger"
                  plain
                  icon="el-icon-close"
                  size="small"
                  >删除</el-button
                >
              </el-popconfirm>
            </el-col>
          </el-row> -->
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div style="text-align: center">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="dataFrom.current"
        :page-size="dataFrom.size"
        layout="prev, pager, next, jumper"
        :total="dataFrom.total"
      >
      </el-pagination>
    </div>
    <!-- 添加分组、 -->
    <el-dialog :width="this.$equipment() ? '100%' : '50%'" :title="EditFrom.httpType ? '编辑权限' : '添加权限'" :visible.sync="dialogTableVisible" @close="initData()">
      <div class="logTop">
        <div class="From_item">
          <div>分组名称</div>
          <el-input v-model="EditFrom.group" class="input-martop" placeholder="请输入内容"></el-input>
        </div>
        <div class="From_item" style="margin-bottom: 10rpx">
          <div>分组标记</div>
          <el-input
            class="input-martop"
            type="textarea"
            v-model="EditFrom.tag"
            :rows="2"
            placeholder="请输入内容"
            style="width: 70%">
          </el-input>
        </div>
      </div>
      <!-- 权限数据列表 -->
      <div class="content_max" v-if="navigationList.length">
        <div class="content_max_left">
          <div v-for="(itm, index) in navigationList" :key="index" :class="['navi-item', index == navigationIndex ? 'naviItem-check' : '' ]" @click="navigationIndex = index">{{ itm.name }}</div>
        </div>
        <div class="content_max_right">
          <template >
            <template v-if="navigationList[navigationIndex].children && navigationList[navigationIndex].children.length">
              <div class="navi-perItem" v-for="item in navigationList[navigationIndex].children" :key="item.functionId">
                <div class="per-title">{{ item.name }}</div>
                <div class="perItem-list" >
                  <el-checkbox v-for="perItem in item.buttons" :key="perItem.functionId"  :checked="perItem.isCheck" @change="setCheck($event, perItem)">{{perItem.name}}</el-checkbox>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="navi-perItem">
                <div class="per-title">{{ navigationList[navigationIndex].name }}</div>
                <div class="perItem-list">
                  <el-checkbox v-for="perItem in navigationList[navigationIndex].buttons" :key="perItem.functionId" :checked="perItem.isCheck" @change="setCheck($event, perItem)">{{perItem.name}}</el-checkbox>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>
      <div class="btn-flex">
        <el-button type="primary" @click="onSubmit">确定</el-button>
        <el-button type="danger" @click="dialogTableVisible = false">取消</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import { apis } from '../../api/EmployeeManagement'

export default {
  data() {
    return {
      tableData: [],
      dataFrom: {
        current: 1,
        size: 14,
        total: 0,
      },
      dialogTableVisible: false,
      EditFrom: {
        group: "",
        tag: "",
        groupId: '',
        httpType: 'POST'
      },
      initNavigationList: [], //初始化权限列表
      navigationList: [], //编辑权限列表数据 isCheck 按钮选中
      navigationIndex: 0, //权限列表选中下标
      tableLoading: false, //表格loading
    };
  },
  created(){
    // 获取表格数据
    this.getTableData();
    // 获取权限列表数据
    apis.perTreeList().then(res => {
      // console.log('res', res)
      this.initNavigationList= res;
      this.navigationList = res;
    })
  },
  
  methods: {
    setCheck(e,data){
      data.isCheck = e;
      console.log('切换数据', e,data)
    },
    // 编辑权限组
    paymentByTrasferClick(row){
      console.log('row', row)
      this.EditFrom = {
        group: row.groupName,
        tag: row.groupTag,
        httpType: 'PUT',    
        groupId: row.groupId
      }
      // this.EditFrom.httpType = 'PUT';
      // this.EditFrom.groupId = row.groupId;
      console.log('this.navigationIndex', this.navigationIndex)
      apis.getFunctionids(row.groupId).then(res => {
        console.log('res', res)
        this.navigationList.forEach(el => {
          if(Array.isArray(el.children)){
            // console.log('二级页面')
            el.children.forEach(item => {
              item.buttons = (item.buttons || []).map(item => { 
                console.log('res.indexOf(item.functionId)', res.indexOf(item.functionId), res,item.functionId )
                item.isCheck = res.indexOf(item.functionId) >= 0;
                return item
              })
            })
          }else{
            // console.log('一级页面')
            el.buttons = el.buttons.map(item => { 
              console.log('res.indexOf(item.functionId)', res.indexOf(item.functionId), res,item.functionId )
              item.isCheck = res.indexOf(item.functionId) >= 0;
              return item
            })
          }
          
        })
        this.dialogTableVisible = true; 
      })
    },
    // 获取表格数据
    getTableData(){
      this.tableLoading = true;
      apis.getPerData().then(res => {
        console.log('getData', res)
        this.tableData = res;
        this.tableLoading = false;
        this.getCurrentPage(); //请求分页数据
      })
    },
    // 确定添加分组
    onSubmit(){
      const {  EditFrom } = this;
      let groupData = {
        groupId: EditFrom.groupId,
        groupName: EditFrom.group,
        groupTag: EditFrom.tag,
        functionIds: this.getFuncId()
      }
      apis.addGroup(groupData, EditFrom.httpType).then(res => {
        console.log('addGroup', res)
        const { status } = res;
        if(status !== 'success') return this.$message.error('添加失败')
        this.$message.success( EditFrom.httpType ? '编辑权限组成功' : '添加权限组成功')
        this.dialogTableVisible = false;
        this.getTableData();
      })
    },
    // 获取functionid数组
    getFuncId(){
      let ids = [];
      this.navigationList.forEach(itm => {
        if(Array.isArray(itm.children)){
          const funArr = itm.children.reduce((per,crr) => {
            let butIds = []; // 勾选按钮id数据
            crr.buttons.forEach(item => item.isCheck && butIds.push(item.functionId))
            butIds.length && butIds.push(crr.functionId)
            // console.log(per, crr, butIds)
            return butIds.length ? [...per, ...butIds] : per
          }, [])
          ids = ids.concat(funArr.length ? [itm.functionId, ...funArr] : funArr);
        }else{
          // 一级列表
          let butIds = []; // 勾选按钮id数据
          itm.buttons.forEach(item => item.isCheck && butIds.push(item.functionId))
          ids = ids.concat(butIds.length ? [itm.functionId, ...butIds] : butIds);
        }
      })
      console.log('idsssss', ids)
      return ids
    },
    // 监听pageSize事件
    handleSizeChange(newsize) {
      this.dataFrom.size = newsize;
      // 重新获取数据
      this.getTableData(0);
    },
    handleCurrentChange(newpage) {
      this.dataFrom.current = newpage;
      // 重新获取数据
      this.getTableData(0);
    },
    // 初始化弹框数据
    initData(){
      console.log('触发')
      this.navigationList = this.initNavigationList;
      this.EditFrom = {};
    },
    // 确定删除
    OKDelGroup(from) {
      apis.deleteGroupData(from).then(res => {
        if(res.status != 'success') return this.$message.error('删除失败')
        this.$message.success('删除成功')
        this.getTableData();
      })
    },
    // 获取当前分页
    getCurrentPage(){
      apis.getCurrentPage(this.dataFrom).then(res => {
        console.log('请求数据', res)
        this.dataFrom = {
          current: parseInt(res.current),
          size: res.size,
          total: res.total,
        }
      })
    }
  },
};
</script>

<style scoped>
.el-row {
  display: flex;
  justify-content: flex-end;
  margin-top: .625rem;
  margin-bottom: .9375rem;
}
.logTop {
  width: 100%;
  /* background-color: aquamarine; */
  display: flex;
}
.From_item {
  width: 50%;
  height: 5rem;
  margin: auto;
  display: flex;
  /* background-color: aqua; */
}
.From_item div {
  width: 20%;
}
.From_item .el-input,
.From_item .el-select {
  width: 70%;
}
.dialog-footer {
  text-align: center;
  margin-top: 3.125rem;
}

/* 弹窗内容 */
.content_max {
  width: 100%;
  height: 25rem;
  display: flex;
  border-top:.0625rem solid rgb(218, 218, 218);
}
.content_max_left {
  height: 90%;
  overflow-y: scroll;
  width: 25%;
  border-right: .0625rem solid rgb(218, 218, 218);
  /* background-color: aqua; */
}
.content_max_right {
  width: 75%;
  height: 90%;
  overflow-y: scroll;
}
.input-martop{
  margin-top: -0.625rem;
}
.navi-item{
  font-size: 1rem;
  height: 3.75rem;
  /* line-height: 3.75rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all .3s;
  padding: 0 1.25rem;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: .375rem;
  color: rgb(81, 81, 81);
  /* background: #fff; */
}
.navi-item:hover{
  color: rgb(85, 169, 233);
  background: rgba(242, 242, 242, 0.262);
  box-shadow: 0 .1875rem .5rem .125rem rgb(235, 235, 235);
}
.naviItem-check{
  color: rgb(85, 169, 233);
  background: rgba(242, 242, 242, 0.262);
  box-shadow: 0 .1875rem .5rem .125rem rgb(235, 235, 235);
}
.navi-perItem{
  padding: 1.875rem 1.25rem 0;
  /* height: 3.125rem; */
}
.perItem-list{
  padding: .625rem;
  border-bottom: .0625rem solid rgb(218, 218, 218);
}
.btn-flex{
  margin: 0 auto;
  width: 170px;
  display: flex;
  justify-content: space-between;
}
</style>