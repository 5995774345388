<template>
  <!-- 表单 -->
  <div class='sideForm'>
    <el-form label-width="80px" label-position="left" style="padding: 0 30px">
        <el-form-item :key="index"  v-for="(item,index) in formDataHead"  :label="item.label + '：'">
          <el-image v-if="item.formItemEl" :src="item.imgUrl" fit="cover" class="form-img"></el-image>
          <el-input v-else-if="item.isCustom !== true" v-model="value[item.keyName]" :type="item.type || 'text'" :placeholder="item.placeholder || '请输入内容'"></el-input>
          <template v-else>
            <slot :name="item.slotName"></slot>
          </template>
        </el-form-item>
      <!-- <el-form-item label="上传文件">
        <el-upload
          class="upload-demo"
          drag
          action=""
          :on-change="handleChange"
          :auto-upload="false"
          :file-list="fileList"
          :limit="1"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            <em>点击上传</em>
          </div>
          <div class="el-upload__tip" slot="tip">
            只能上传1个文件, 且不超过500kb
          </div>
        </el-upload>
      </el-form-item> -->
      <el-form-item>
        <el-button type="primary" @click="formSubmit" v-throttle>确定{{random}}</el-button>
        <el-button @click="clearForm">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
 
<script >
// TODO 组件只关注视图层，最终数据提交在展示页面处理 页面可获取当前组件的formData数据
export default ({
  name: '',
  props: {
    // 表单数据key值
    formDataHead: {
      type: Array,
      default: []
    },
    // formData数据
    value: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      random: 0,
    }
  },
  watch:{
    value: {
      handler(newVal, oldVal){
        console.log(newVal, oldVal)
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  methods: {
    // 输入表单内容
    setInputVal(e, value){
      console.log(e.value)
    },
    // 提交表单
    formSubmit(){
      console.log('点击了')
      this.random = Math.random()
    },
    // 取消 重置表单
    clearForm(){

    },
  },
})
 
</script>
<style scoped>
.sideForm{
  width: 500px;
  margin: 0 auto;
}
.form-img{
  width: 100rpx;
  height: 70rpx;
}
</style>