<template>
  <el-card>
    <div v-loading="loadingTab">
      <!-- 待导入 -->
      <div style="width: 95%; margin: 0 auto">
        <el-row style="margin-bottom: 15px; display: flex; lign-items: center">
          <span style="flex: 1"
            >待导入
            <el-input
              placeholder="校区名称模糊搜索"
              v-model="getFrom0.remarks"
              style="width: 180px; margin:0 20px"
            >
            </el-input>
            <el-button
              style="background-color: #5e6cf4; color: #fff"
              @click="getreviewActivities(0)"
            >
              <i class="el-icon-search"></i>
            </el-button>
          </span>

          <el-button
            @click="formShowClick"
            type="primary"
            style="background-color: #5e6cf4"
            >添加</el-button
          >
        </el-row>
        <!-- 表格 -->
        <el-table
          :data="tableData0"
          style="width: 100%"
          border
          :cell-style="{ textAlign: 'center' }"
          :header-cell-style="{
            textAlign: 'center',
            backgroundColor: '#f5f7fa',
          }"
          @cell-dblclick="copyText"
        >
          <el-table-column
            prop="brandName"
            label="品牌"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="mechanism"
            label="校区名称"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="originalFileUrl"
            label="原文件地址"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="fileUrl"
            label="文件地址"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="emptyTag"
            label="是否清空"
            show-overflow-tooltip
            width="100"
          ></el-table-column>
          <el-table-column
            prop="creationTime"
            label="上传时间"
            width="180"
          ></el-table-column>
          <el-table-column label="操作" width="150" fixed="right">
            <template v-slot="scope">
              <div class="ActionColumn">
                <el-popconfirm
                  confirm-button-text="确定"
                  cancel-button-text="取消"
                  icon-color="red"
                  title="确定立即导入吗？"
                  @confirm="EdtDownapply(scope.row)"
                >
                  <el-link
                    slot="reference"
                    type="primary"
                    class="ActionColumn_h"
                    >立即导入</el-link
                  >
                </el-popconfirm>
                <el-popconfirm
                  confirm-button-text="确定"
                  cancel-button-text="取消"
                  icon-color="red"
                  title="确定删除该条记录吗？"
                  @confirm="trueDownapply(scope.row)"
                >
                  <el-link
                    slot="reference"
                    type="primary"
                    class="ActionColumn_h"
                    >删除</el-link
                  >
                </el-popconfirm>
              </div>
              <!-- <el-row>
                <el-col :span="14">
                  <el-popconfirm
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    icon-color="red"
                    title="确定立即导入吗？"
                    @confirm="EdtDownapply(scope.row)"
                  >
                    <el-button
                      slot="reference"
                      type="success"
                      plain
                      icon="el-icon-edit"
                      size="small"
                      >立即导入</el-button
                    >
                  </el-popconfirm>
                </el-col>
                <el-col :span="8">
                  <el-popconfirm
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    icon-color="red"
                    title="确定删除该条记录吗？"
                    @confirm="trueDownapply(scope.row)"
                  >
                    <el-button
                      slot="reference"
                      type="danger"
                      plain
                      icon="el-icon-close"
                      size="small"
                      >删除</el-button
                    >
                  </el-popconfirm>
                </el-col>
              </el-row> -->
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          style="margin-top: 8px"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="getFrom0.pageNum"
          :page-size="getFrom0.pageSize"
          layout="prev, pager, next, jumper"
          :total="total0"
        >
        </el-pagination>
      </div>

      <div style="width: 95%; margin: 0 auto;margin-top: 20px;">
        <el-row style="margin-bottom: 15px">
          <el-col style="height: 40px; display: flex; align-items: flex-end">
            <span>已导入
              <el-input
              placeholder="校区名称模糊搜索"
              v-model="getFrom1.remarks"
              style="width: 180px; margin:0 20px"
            >
            </el-input>
            <el-button
              style="background-color: #5e6cf4; color: #fff"
              @click="getreviewActivities(1)"
            >
              <i class="el-icon-search"></i>
            </el-button>
            </span>
          </el-col>
        </el-row>
        <!-- 表格 -->
        <el-table
          :data="tableData1"
          style="width: 100%"
          border
          :cell-style="{ textAlign: 'center' }"
          :header-cell-style="{
            textAlign: 'center',
            backgroundColor: '#f5f7fa',
          }"
          @cell-dblclick="copyText"
        >
          <el-table-column
            prop="brandName"
            label="品牌"
            width="230"
          ></el-table-column>
          <el-table-column
            prop="mechanism"
            label="校区名称"
            width="230"
          ></el-table-column>
          <el-table-column
            prop="fileUrl"
            label="文件地址"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="emptyTag"
            label="是否清空"
            show-overflow-tooltip
            width="100"
          ></el-table-column>
          <el-table-column
            prop="creationTime"
            label="上传时间"
            show-overflow-tooltip
          ></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          style="margin-top: 8px"
          @size-change="handleSizeChange1"
          @current-change="handleCurrentChange1"
          :current-page="getFrom1.pageNum"
          :page-size="getFrom1.pageSize"
          layout="prev, pager, next, jumper"
          :total="total1"
        >
        </el-pagination>
      </div>
    </div>

    <el-drawer
      title="添加导入数据"
      :visible.sync="drawer"
      direction="rtl"
      :size="this.$equipment() ? '100%' : '30%'"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="80px"
        label-position="left"
        style="padding: 0 30px"
      >
        <el-form-item label="选择品牌">
          <el-autocomplete
            v-model="state"
            :fetch-suggestions="querySearchAsync"
            placeholder="请选择品牌"
            @select="handleSelect"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="选择校区">
          <el-select v-model="form.mechanismId" placeholder="请选择校区">
            <el-option
              v-for="item in mechanismDataList"
              :key="item.mechanismId"
              :label="item.mechanism"
              :value="item.mechanismId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否清空">
          <el-radio-group v-model="form.emptyTag">
            <el-radio label="是"></el-radio>
            <el-radio label="否"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="上传文件">
          <el-upload
            class="upload-demo"
            drag
            action=""
            :on-change="handleChange"
            :auto-upload="false"
            :file-list="fileList"
            :limit="1"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
            <div class="el-upload__tip" slot="tip">
              只能上传1个文件, 且不超过500kb
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="formAddClick">确定</el-button>
          <el-button @click="formHiedClick">取消</el-button>
        </el-form-item>
      </el-form>
      <!-- <div style="margin-top:60px;padding: 0 30px;">需求须知</div>
      <div style="padding: 5px 30px;">需求须知</div>
      <div style="padding: 5px 30px;">需求须知</div> -->
    </el-drawer>
  </el-card>
</template>

<script>
import { uploadPageList, uploadStatusChange, uploadDel } from "@/api/http";
import { brandList, mechanismPageList, uploadAdd } from "@/api/api";
import axios from "axios";

import { REQUEST_URL } from "@/api/project.config";

export default {
  data() {
    return {
      drawer: false,
      restaurants: [],
      state: "",
      timeout: null,
      mechanismDataList: [],
      typeList: [],
      fileList: [],
      form: {
        mechanismId: "",
        emptyTag: "",
      },

      tableData: [],
      total: 0, // 表格总数据
      search: "", //搜素内容绑定
      activeName: "first",
      loadingTab: false, //表格加载属性
      serachValue: "", //搜索框内容绑定
      // 获取数据表单
      getFrom0: {
        pageNum: 1,
        pageSize: 5,
        remarks: "",
        status: "0",
      },
      getFrom1: {
        pageNum: 1,
        pageSize: 5,
        remarks: "",
        status: "1",
      },
      tableData0: [],
      tableData1: [],
      total0: 0,
      total1: 1,

      restaurants: [],
      state: "",
      timeout: null,
      mechanismDataList: [],
      originalFileUrl: "",
    };
  },

  methods: {
    // 文件上传
    handleChange(file, fileList) {
      // console.log(fileList);
      // console.log(file);
      this.fileList = fileList.slice(-3);
    },
    // beforeUpload(file) {
    //   console.log(file);
    //   var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
    //   const extension = testmsg === "xlx" ||testmsg === "xlsx";
    //   if (!extension ) {
    //     this.$message({
    //       message: "上传文件只能是.xlx格式或者.xlsx!",
    //       type: "warning",
    //     });
    //   }
    //   return extension;
    // },

    // 添加
    formShowClick() {
      this.restaurants = [];
      this.state = "";
      this.timeout = null;
      this.fileList = [];
      this.mechanismDataList = [];
      this.form = {
        mechanismId: "",
        emptyTag: "",
      };
      this.drawer = true;
    },
    formHiedClick() {
      this.drawer = false;
    },
    async formAddClick() {
      console.log(this.fileList);
      var testmsg = this.fileList[0].name.substring(
        this.fileList[0].name.lastIndexOf(".") + 1
      );
      const extension = testmsg === "xls" || testmsg === "xlsx";
      if (!extension) {
        this.$message({
          message: "上传文件只能是.xlx格式或者.xlsx!",
          type: "warning",
        });
        return;
      }
      // return
      if (this.form.mechanismId === "") {
        this.$message.warning("选择品牌和校区");
        return;
      }
      if (this.form.emptyTag === "") {
        this.$message.warning("选择是否清空");
        return;
      }
      if (!this.fileList.length) {
        this.$message.warning("上传要导入的文件");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let fileUrl = "";
      if (this.fileList && this.fileList.length) {
        let param = new FormData();
        param.append("file", this.fileList[0].raw);
        const fileUrlData = await axios.post(
          REQUEST_URL + "/oam/OAMUpload/uploadCheck",
          // "https://api.jamboxsys.com/jambox/ass/file/upLoad",
          param,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              token: this.form.mechanismId,
            },
          }
        );
        if (fileUrlData.data.status == "fail") {
          this.$message.error(fileUrlData.data.msg);
          this.fileList = [];
          loading.close();
          return;
        }
        console.log("fileUrlData", fileUrlData);
        // console.log(fileUrlData);
        // return
        fileUrl = fileUrlData.data.data.fileUrl;
        this.originalFileUrl = fileUrlData.data.data.originalFileUrl;
      }
      const data = {
        mechanismId: this.form.mechanismId,
        emptyTag: this.form.emptyTag === "是" ? 1 : 0,
        fileUrl: fileUrl || "",
        originalFileUrl: this.originalFileUrl,
      };
      const dataAdd = await uploadAdd(data);
      loading.close();
      if (dataAdd.status == "success") {
        this.$message.success("添加成功");
        this.drawer = false;
        this.getreviewActivities(-1);
      } else {
        this.$message.error("添加失败");
      }
    },

    // 0
    // 监听pageSize事件
    handleSizeChange(newsize) {
      this.getFrom0.pageSize = newsize;
      // 重新获取数据
      this.getreviewActivities(0);
    },
    handleCurrentChange(newpage) {
      this.getFrom0.pageNum = newpage;
      // 重新获取数据
      this.getreviewActivities(0);
    },
    // 1
    // 监听pageSize事件
    handleSizeChange1(newsize) {
      console.log(newsize);
      this.getFrom1.pageSize = newsize;
      // 重新获取数据
      this.getreviewActivities(1);
    },

    handleCurrentChange1(newpage) {
      console.log(newpage);
      this.getFrom1.pageNum = newpage;
      // 重新获取数据
      this.getreviewActivities(1);
    },

    // 获取数据方法
    async getreviewActivities(ind) {
      this.loadingTab = true;
      if (ind === -1) {
        const res1 = await uploadPageList(this.getFrom0);
        this.tableData0 = res1.data.list;
        this.total0 = parseInt(res1.data.total);
        console.log(this.tableData0);
        const res2 = await uploadPageList(this.getFrom1);
        this.tableData1 = res2.data.list;
        this.total1 = parseInt(res2.data.total);
        console.log(this.tableData1);
      } else {
        if (ind === 0) {
          const res1 = await uploadPageList(this.getFrom0);
          this.tableData0 = res1.data.list;
          this.total = parseInt(res1.data.total);
        }
        if (ind === 1) {
          const res2 = await uploadPageList(this.getFrom1);
          this.tableData1 = res2.data.list;
          this.total1 = parseInt(res2.data.total);
        }
      }
      // const dataLsit = await getreviewActivitiesList(this.viewActiveFrom);
      // this.tableData = dataLsit.data.list;
      // this.total = dataLsit.data.total; //获取一共多少条数据
      this.loadingTab = false;
    },

    // 修改状态
    async EdtDownapply(item) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const res = await uploadStatusChange({ uploadId: item.uploadId });
      console.log(res);
      if (res.status !== "success") return this.$message.error(res.msg);
      loading.close();
      this.$message.success(res.msg);
      this.getreviewActivities(-1);
    },

    // 删除
    async trueDownapply(item) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const res = await uploadDel({ uploadId: item.uploadId });
      if (res.status !== "success") return this.$message.error("删除失败");
      loading.close();
      this.$message.success("删除成功");
      this.getreviewActivities(-1);
    },

    // 品牌校区搜索
    async querySearchAsync(queryString, cb) {
      const brandDataList = await brandList({ remarks: queryString });
      let restaurants = [];
      for (let a = 0; a < brandDataList.data.length; a++) {
        restaurants.push({
          id: brandDataList.data[a].id,
          value: brandDataList.data[a].name,
        });
      }

      // let results = queryString
      //   ? restaurants.filter(this.createStateFilter(queryString))
      //   : restaurants;

      let results = restaurants;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(results);
      }, 3000 * Math.random());
    },

    // 序列化操作
    createStateFilter(queryString) {
      return (state) => {
        return (
          state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },

    async handleSelect(item) {
      const data = {
        pageNumber: 1,
        pageSize: 100,
        brandId: item.id,
      };
      const mechanismDataList = await mechanismPageList(data);
      this.mechanismDataList = mechanismDataList.data.list;
    },

    // 复制内容
    copyText(row, column, cell, event) {
      // 双击复制
      let save = function (e) {
        e.clipboardData.setData("text/plain", event.target.innerText);
        e.preventDefault(); //阻止默认行为
      };
      console.log(save);
      document.addEventListener("copy", save); //添加一个copy事件
      document.execCommand("copy"); //执行copy方法
      this.$message({ message: "复制成功", type: "success" }); //提示
    },
  },
  created() {
    // 获取列表数据
    this.getreviewActivities(-1);
  },
};
</script>

<style scoped>
.el-table >>> .warning-row {
  background: oldlace;
}

.el-table >>> .success-row {
  background: #f0f9eb;
}

.active_imgs {
  width: 50px;
  height: 50px;
  margin-right: 8px;
}
</style>

