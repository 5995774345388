import requests from '@/api/request'

// 查看合同
export function contract(data) {
    return requests({
        url: '/ass/qyTencent/eContractList',
        method: 'post',
        data: data
    })
}

// export function Appcontract(data) {
//     return requests({
//         url: '/ass/qyTencent/eContractList',
//         method: 'post',
//         data: data
//     })
// }