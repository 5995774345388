<template>
  <!-- 待确认 -->
  <div>
    <div class="Financial-max">
      <div class="Financial-max-item">
        <div>搜索</div>
        <el-input placeholder="请输入内容" v-model="searchData.remarks" clearable></el-input>
      </div>
      <div class="Financial-max-item">
        <div>负责人</div>
        <el-select v-model="searchData.staffId" placeholder="请选择" clearable>
          <el-option
            v-for="item in personCharges"
            :key="item.name"
            :label="item.name"
            :value="item.staffId">
          </el-option>
        </el-select>
      </div>
      <div class="Financial-max-item">
        <el-button
          style="background-color: #5e6cf4; color: #fff;margin-left: 20px;"
          @click="getTableList(searchData)">
          <i class="el-icon-search"></i>
        </el-button>
      </div>
    </div>
     <!-- 表格 -->
    <el-table
      :data="tableData"
      style="width: 100%;margin-top: 10px;"
      border
      :cell-style="{ textAlign: 'center' }"
      :header-cell-style="{ textAlign: 'center', backgroundColor: '#f5f7fa' }"
      @cell-dblclick="copyText"
      >
      <el-table-column  prop="brandName" label="机构名称"  min-width="250" ></el-table-column>
      <el-table-column prop="stage" label="支付套餐"  show-overflow-tooltip min-width="250"></el-table-column>
      <el-table-column prop="chargeName" label="负责人"   min-width="250"></el-table-column>
      <el-table-column  prop="region" label="所在地区"  min-width="120"></el-table-column>
      <el-table-column prop="name" label="联系人" show-overflow-tooltip min-width="80"></el-table-column>
      <el-table-column prop="phone" label="联系电话" min-width="110"></el-table-column>
      <el-table-column prop="reason" label="购买理由" min-width="110"></el-table-column>
      <el-table-column prop="phone" label="营业执照" min-width="115">
        <template slot-scope="scope">
          <el-image
            style="width: 90px; height: 60px"
            :src="scope.row.busUrl"
            fit="cover" :previewSrcList="[scope.row.busUrl]">
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="picUrl" label="支付凭证" min-width="115" fixed="right">
        <template slot-scope="scope">
          <el-image
            style="width: 90px; height: 60px"
            :src="scope.row.picUrl"
            fit="cover" :previewSrcList="[scope.row.picUrl]">
          </el-image>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="invoiceStatus" label="是否需要开票" ></el-table-column> -->
      <el-table-column prop="dealRemarks" label="成交备注" show-overflow-tooltip min-width="100" fixed="right"></el-table-column>
      <el-table-column prop="mechanismList" label="绑定校区" show-overflow-tooltip min-width="100" fixed="right"></el-table-column>
      <el-table-column prop="remarks" label="财审备注" show-overflow-tooltip min-width="100" fixed="right"></el-table-column>
      <el-table-column label="操作" width="125" fixed="right">
        <template slot-scope="scope">
          <div class="ActionColumn">
            <el-link @click="rejectClick(scope.row)" class="ActionColumn_h"  type="primary">驳回</el-link>
            <el-link @click="remarksClick(scope.row)" class="ActionColumn_h"  type="primary">修改备注</el-link> 
            <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                icon-color="red"
                title="确定修改状态吗？"
                @confirm="paymentByTrasferClick(scope.row)"
              >
            <el-link slot="reference" class="ActionColumn_h"  type="primary">确认</el-link> 
           </el-popconfirm>
          </div>
          <!-- <el-row :gutter="0">
            <el-col :span="7">
              <el-button type="danger" size="small" plain  icon="el-icon-close" @click="rejectClick(scope.row)">驳回</el-button>
            </el-col>
            <el-col :span="9">
              <el-button type="warning" size="small" plain  icon="el-icon-edit-outline" @click="remarksClick(scope.row)">修改备注</el-button>
            </el-col>
            <el-col :span="8">
              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                icon-color="red"
                title="确定修改状态吗？"
                @confirm="paymentByTrasferClick(scope.row)"
              >
                <el-button
                  slot="reference"
                  type="success"
                  plain
                  icon="el-icon-check"
                  size="small"
                  >确认</el-button >
              </el-popconfirm>
            </el-col>

          </el-row> -->
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      style="margin-top: 18px"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="paging.pageNum"
      :page-sizes="[8, 10, 15, 50]"
      :page-size="paging.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="paging.total"
    >
    </el-pagination>

    <!-- 驳回狂框 -->
    <el-drawer title="驳回" :visible.sync="rejectdrawer" v-loading="loadingTab">
      <div class="From_item">
        <div>驳回原因</div>
        <el-input
          type="textarea"
          :rows="4"
          v-model="reason"
          placeholder="请输入内容"
          style="width: 70%"
        >
        </el-input>
      </div>
      <!-- </from> -->
      <div class="dialog-footer">
        <el-button @click="rejectdrawer = false">取 消</el-button>
        <el-button type="primary" @click="subimtreject()" >确 定</el-button>
      </div>
    </el-drawer>

    <!-- 备注框 -->
    <el-drawer title="修改备注" :visible.sync="remarksdrawer" v-loading="loadingTab">
      <div class="From_item">
        <div>修改备注</div>
        <el-input
          type="textarea"
          :rows="4"
          v-model="remarks"
          placeholder="请输入内容"
          style="width: 70%"
        >
        </el-input>
      </div>
      <!-- </from> -->
      <div class="dialog-footer">
        <el-button @click="remarksdrawer = false">取 消</el-button>
        <el-button type="primary" @click="Submiremark()" >确 定</el-button>
      </div>
    </el-drawer>
    
  </div>
</template>

<script>
import { apis } from '@/api/channelManagement'
export default {
  name: "beConfirmed",
  data() {
    return {
      options: [],
      // 驳回
      reason:'',
      rejectdrawer:false,
      total:0,

      // 修改备注
      remarks:'',
      remarksdrawer:false,
      // TODO
      tableData: [], // 表格数据
      searchData: {}, // 搜索数据
      tableLoading: false, // 表格加载状态
      paging: {
        pageSize: 6, // 单页数量
        pageNum: 1, // 页码
        total: 0, // 总数
        status: 0,
      }, // 表格数据分页
      personCharges: [], // 负责人
      loadingTab:true,
    };
  },
  created(){
    this.getTableList();
    this.getStaffSaleSearch()
  },
  watch: {
    remarksdrawer(newVla, oldVal){
      if(!newVla)this.remarks = "";
    },
    rejectdrawer(newVla, oldVal){
      if(!newVla)this.reason = "";
    }
  },
  methods:{
    // 获取表格数据
    getTableList(remak = {}){
      this.tableLoading = true;
      this.loadingTab = true
      apis.financePageList({...this.paging, ...remak}).then(res => {
        console.log('分页列表', res);
        const { data, msg , status} = res;
        this.tableLoading = false;
        this.loadingTab = false
        if(status != 'success') return this.$message.error(msg)
        this.tableData = data.list;
        this.paging.total = parseInt(data.total);
      })
    },
    verification(data){
      // 使用示例 if(this.verification(this.channelFormData)) return;
      for(let key in data){
        if(!data[key]){
          this.$message.error('请完善信息！')
          return true;
        }
      }
    },
    copyText(row, column, cell, event) {
      // 双击复制
      let save = function (e) {
        e.clipboardData.setData("text/plain", event.target.innerText);
        e.preventDefault(); //阻止默认行为
      };
      console.log(save);
      document.addEventListener("copy", save); //添加一个copy事件
      document.execCommand("copy"); //执行copy方法
      this.$message({ message: "复制成功", type: "success" }); //提示
    },
    // 获取负责人列表
    getStaffSaleSearch(){
      apis.staffSaleSearch().then(res => {
        console.log('渠道人列表', res)
        this.personCharges = res.data;
      })
    },
    // TODO
    // 监听pageSize事件
    handleSizeChange(newsize) {
      this.paging.pageSize = newsize;
      // 重新获取数据
      this.getTableList(this.searchData)
    },

    handleCurrentChange(newpage) {
      this.paging.pageNum = newpage;
      // 重新获取数据
      this.getTableList(this.searchData)
    },
    
    // 确认
    paymentByTrasferClick(item){
      apis.financeUpdConfirm({financeId: item.financeId,clueStatus:1}).then(res => {
        if(res.status != 'success') return this.$message.error(res.msg)
        this.$message.success('确认成功')
        this.getTableList();
      })
    },

    // 驳回弹框
    rejectClick(item){
      this.rejectdrawer = true;
      this.operationData = item; // 操作数据
      console.log("弹出框");
    },
    // 确认驳回
    subimtreject(){
      let data = {
        financeId: this.operationData.financeId,
        reason: this.reason
      }
      if(this.verification(data)) return;

      this.loadingTab = true;
      apis.financeReturn(data).then(res => {
        console.log('res', res)
        if(res.status != 'success') return this.$message.error(res.msg)
        this.$message.success('驳回成功')
        this.getTableList();
        this.rejectdrawer = false;
        this.loadingTab = false;
      })
    },

    // 修改备注弹窗
    remarksClick(item){
      this.operationData = item; // 操作数据
      this.remarks = item.remarks;
      this.remarksdrawer = true;
    },

    // 确认修改备注
    Submiremark(){
      let data = {
        financeId: this.operationData.financeId,
        remarks: this.remarks
      }
      if(this.verification(data)) return;
      this.loadingTab = true
      apis.financeUpdRemarks(data).then(res => {
        console.log('res', res)
        if(res.status != 'success') return this.$message.error(res.msg)
        this.$message.success('修改备注成功')
        this.getTableList();
        this.remarksdrawer = false;
        this.loadingTab = false
      })
    },

  }
};
</script>

<style scoped>
.Financial-max {
  width: 100%;
  display: flex;
}
.Financial-max-item {
  display: flex;
  align-items: center;
}
.Financial-max-item div {
  width: 60px;
  margin-right: 5px;
  text-align: center;
}
.Financial-max-item .el-input,
.Financial-max-item .el-select{
  width: 200px;
}
/* 添加表单样式 */
.From_item {
  width: 90%;
  height: 70rpx;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.From_item div {
  width: 30%;
}
.From_item .el-input,
.From_item .el-select {
  width: 70%;
}
.dialog-footer {
  text-align: center;
  margin-top: 50px;
}
.ActionColumn{
  /* width: 70%; */
  /* padding-left: ; */
  /* background-color: #5e6cf4; */
  margin: auto;
  text-align: left;
}
.ActionColumn .ActionColumn_h{
  margin-right: 10px;
  font-size: 14px;
  /* color: #5e6cf4; */
}
.ActionColumn .ActionColumn_h:hover{
  text-decoration: underline;
  font-weight: bold;
}
</style>