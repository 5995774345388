<template>
  <el-card>
    <!-- 开发管理 -->
    <el-row>
      <el-col :span="22">
        <el-tabs v-model="status" @tab-click="handleClick">
          <el-tab-pane
            v-for="item in tabsList"
            :label="item.value"
            :name="item.id"
            :key="item.id"
          >
          </el-tab-pane>
        </el-tabs>
      </el-col>
      <el-col :span="2">
        <el-button
          style="background-color: #5e6cf4; color: #fff"
          @click="addDialogM = true"
          >添加任务</el-button
        >
      </el-col>
    </el-row>

    <WorkItemVue :tabTypeIndex="status" v-if="status == '0'" />
    <BeDevelopedVue :tabTypeIndex="status" v-if="status == '1'" />
    <BeDtestedVue :tabTypeIndex="status" v-if="status == '2'" />
    <ToBeDeployedVue :tabTypeIndex="status" v-if="status == '3'" />
    <Archived :tabTypeIndex="status" v-if="status == '4'" />

    <!-- 添加/编辑弹窗 -->
    <el-drawer title="添加任务" :visible.sync="addDialogM">
      <div class="From_item">
        <div>产品终端</div>
        <el-select v-model="UserFromM.systemName" placeholder="请选择">
          <el-option
            v-for="(item,index) in systemNameList"
            :key="index"
            :label="item"
            :value="item" >
          </el-option>
        </el-select>
      </div>
      <div class="From_item">
        <div>类型</div>
        <el-select v-model="UserFromM.type" placeholder="请选择">
          <el-option
            v-for="(item,index) in TypeList"
            :key="index"
            :label="item"
            :value="item" >
          </el-option>
        </el-select>
      </div>
      <div class="From_item">
        <div>耗费权重</div>
        <el-input
          v-model="UserFromM.costWei"
          style="width: 70%;" 
          type="Number"
        ></el-input>
        <!-- <el-input
          type="textarea"
          :rows="2"
          v-model="UserFromM.costWei"
          placeholder="请输入内容"
          style="width: 70%" >
        </el-input> -->
      </div>
      <div class="From_item">
        <div>内容</div>
        <el-input
          type="textarea"
          :rows="2"
          v-model="UserFromM.content"
          placeholder="请输入内容"
          style="width: 70%" >
        </el-input>
      </div>
      <div class="From_item">
        <div>上传附件</div>
        <el-upload
          class="upload-demo"
          action=""
          :on-change="handleChange"
          :file-list="fileList"
          style="width: 70%"
          :auto-upload="false"
          :limit="1" >
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip" style="width: 100%">
            支持文件：.ara .zip .doc .docx .pdf .jpg
          </div>
        </el-upload>
      </div>
      <div class="From_item">
        <div>备注</div>
        <el-input
          v-model="UserFromM.remarks"
          placeholder="请输入备注" ></el-input>
      </div>

      <div class="From_item">
        <div>请选择负责人</div>
        <el-select v-model="UserFromM.staffId" placeholder="请选择">
          <el-option
            v-for="(item,index) in staffIdList"
            :key="index"
            :label="item.name"
            :value="item.staffId" >
          </el-option>
        </el-select>
      </div>

      <div class="From_item">
        <div>预计完成时间</div>
        <el-date-picker
          v-model="UserFromM.estimateTime"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期">
        </el-date-picker>
      </div>
      <!-- </from> -->
      <div class="dialog-footer">
        <el-button @click="addDialogM = false">取 消</el-button>
        <el-button type="primary" @click="OKAdd()">确 定</el-button>
      </div>
    </el-drawer>
  </el-card>
</template>

<script>
import Archived from "./Develo_table/Archived.vue";
import BeDevelopedVue from "./Develo_table/BeDeveloped.vue";
import BeDtestedVue from "./Develo_table/BeDtested.vue";
import ToBeDeployedVue from "./Develo_table/ToBeDeployed.vue";
import WorkItemVue from "./Develo_table/WorkItem.vue";

import axios from "axios";


import {
  iterationAdd,
  iterationTypeList,
  dutyIterationList,
  iterationSystemList,
} from "@/api/DevelopmentManagement";

export default {
  data() {
    return {
      tabsList: [
        {
          id: "0",
          value: "工作项",
        },
        {
          id: "1",
          value: "待开发",
        },
        {
          id: "2",
          value: "待测试",
        },
        {
          id: "3",
          value: "待部署",
        },
        {
          id: "4",
          value: "已归档",
        },
      ],
      status: "0",
      // 文件
      fileList: [],
      // 添加表单
      UserFromM: {
        systemName: "",
        type: "",
        content: "",
        remarks: "",
        staffId: "",
        estimateTime: "",
        enclosure: "",
        costWei:""
      },
      // 弹窗
      addDialogM: false,
      options: [
      ],

      TypeList: [],
      staffIdList: [],
      systemNameList: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      let res1 = await iterationTypeList({});
      // console.log(res1);
      this.TypeList = res1.data;
      let res2 = await dutyIterationList({});
      // console.log(res2);
      this.staffIdList = res2.data;
      let res3 = await iterationSystemList({});
      // console.log(res3);
      this.systemNameList = res3.data;
    },

    handleClick(tab, event) {
      // 获取token
      const tokenstr = window.sessionStorage.getItem("token");
      // 如果不存在这个令牌的话就强制跳转到登录页面
      // if (!tokenstr) return this.$router.push("/Login");
      // this.status = tab.index;
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },

    // 添加
    async OKAdd() {
      let fileUrl = "";
      if (this.fileList && this.fileList.length) {
        let param = new FormData();
        param.append("file", this.fileList[0].raw);
        const fileUrlData = await axios.post(
          "https://api.jamboxsys.com/jambox/ass/file/upLoad",
          param,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        fileUrl = fileUrlData.data.data.url;
        console.log(fileUrl);
      }
      this.UserFromM.enclosure = fileUrl
      this.addDialogM = false
      let res = await iterationAdd(this.UserFromM)
      console.log(res);
      if(res.status === 'success'){
        this.$message.success("添加成功")
      }else{
        this.$message.error("添加失败")
      }
      return
      location.reload();
    },
  },
  components: {
    Archived,
    BeDevelopedVue,
    BeDtestedVue,
    ToBeDeployedVue,
    ToBeDeployedVue,
    WorkItemVue,
  },
};
</script>


<style scoped>
.From_item {
  width: 90%;
  height: 70rpx;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.From_item div {
  width: 30%;
}
.From_item .el-input,
.From_item .el-select,
.From_item .el-upload {
  width: 70%;
}
.dialog-footer {
  text-align: center;
  margin-top: 40px;
}
</style>