<template>
  <!-- 线索跟进 -->
  <el-card>
    <el-row style="margin-bottom: 15px; display: flex; align-items: center">
      <!-- 操作 -->
      <div class="Sittring_top">
        <div style="display: flex; width: 85%">
          <div class="Sittring_top-item">
            <label>搜 索</label>
            <el-input
              placeholder="请输入内容"
              v-model="viewActiveFrom.remarks"
              style="width: 70%"
            >
            </el-input>
          </div>

          <div class="Sittring_top-item">
            <label>意向情况</label>
            <el-select
              v-model="viewActiveFrom.intention"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in intentionList"
                :key="item.typeId"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </div>

          <div class="Sittring_top-item">
            <label>接入渠道</label>
            <el-select v-model="channelId" clearable placeholder="请选择">
              <el-option
                v-for="item in channelList"
                :key="item.value"
                :label="item.channel"
                :value="item.channelId"
              >
              </el-option>
            </el-select>
          </div>

          <!-- <div class="Sittring_top-item">
          <label>负责人</label>
          <el-select v-model="staffId" clearable placeholder="请选择">
            <el-option
              v-for="item in staffSaleList"
              :key="item.value"
              :label="item.name"
              :value="item.staffId"
            >
            </el-option>
          </el-select>
        </div> -->

          <div class="Sittring_top-item" style="width: 100px">
            <el-button
              style="background-color: #5e6cf4; color: #fff"
              @click="getTableDada()"
            >
              <i class="el-icon-search"></i> 搜索
            </el-button>
          </div>
        </div>

        <div class="Sittring_top-item" style="">
          <el-button
            style="background-color: #5e6cf4; color: #fff"
            @click="addfollowUp(1)"
          >
            新增线索
          </el-button>
        </div>
      </div>
    </el-row>
    <el-row style="margin-bottom: 15px; display: flex; align-items: center">
      <!-- 操作 -->
      <div class="Sittring_top">
        <div style="display: flex; width: 85%">
          <div class="Sittring_top-item" style="width:270px;">
            <label>跟进时长</label>
            <el-input
              placeholder="请输入内容"
              v-model="viewActiveFrom.continuationDay"
              style="width: 55%"

              type="Number"
              :min="0"
            >
            </el-input>
          </div>
          <div class="Sittring_top-item" >
            <label>未跟进天数</label>
            <el-input
              placeholder="请输入内容"
              v-model="viewActiveFrom.dayBefore"
              style="width: 50%"
              type="Number"
              :min="0"
            >
            </el-input>
          </div>
          <div class="Sittring_top-item">
            <label>负责人</label>
            <el-select v-model="staffId" clearable placeholder="请选择">
              <el-option
                v-for="item in staffSaleList"
                :key="item.value"
                :label="item.name"
                :value="item.staffId"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="Sittring_top-item" >
          <el-button type="warning" @click="BatchFollowVisible = true">
            批量跟进
          </el-button>
        </div>
      </div>
    </el-row>

    <el-row style="margin-bottom: 15px; display: flex; align-items: center">
      <!-- 操作 -->
      <div class="Sittring_top">
        <div style="display: flex; width: 85%">
          <div class="Sittring_top-item">
            <label style="margin-right: 10px">创建日期</label>
            <el-date-picker
              v-model="time"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
    </el-row>

    <!-- 表格 -->
    <el-table
      v-loading="loadingTab"
      :data="tableData"
      style="width: 100%"
      border
      :cell-style="{ textAlign: 'center' }"
      :header-cell-style="{ textAlign: 'center', backgroundColor: '#f5f7fa' }"
      :row-style="{ height: '50px' }"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" fixed> </el-table-column>
      <el-table-column
        prop="clue"
        label="机构名称"
        width="200"
      ></el-table-column>
      <el-table-column
        prop="scale"
        label="机构规模"
        width="200"
      ></el-table-column>
      <el-table-column
        prop="region"
        label="所在地区"
        width="200"
      ></el-table-column>
      <el-table-column prop="name" label="联系人" width="80"></el-table-column>
      <el-table-column
        prop="phone"
        label="联系方式"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="identity"
        label="联系人身份"
        width="100"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="channel"
        label="接入渠道"
        width="130"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="phone" label="聊天截图" min-width="110">
        <template slot-scope="scope">
          <el-image
            style="width: 90px; height: 60px"
            :src="scope.row.picUrl"
            fit="cover"
            :previewSrcList="[scope.row.picUrl]"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="use"
        label="是否使用系统"
        width="100"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="satisfied"
        label="系统满意度"
        width="100"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="recommend"
        label="推荐机构"
        width="100"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="intention"
        width="80"
        label="意向情况"
        show-overflow-tooltip
        fixed="right"
      ></el-table-column>
      <el-table-column
        prop="chargeName"
        label="负责人"
        width="70"
        show-overflow-tooltip
        fixed="right"
      ></el-table-column>
      <el-table-column
        prop="finRecord"
        label="成交校区"
        show-overflow-tooltip
        fixed="right"
      ></el-table-column>
      <el-table-column
        prop="remarks"
        label="备注"
        width="70"
        show-overflow-tooltip
        fixed="right"
      ></el-table-column>
      <el-table-column
        prop="creTime"
        label="创建时间"
        width="100"
        show-overflow-tooltip
        fixed="right"
      ></el-table-column>
      <!-- <el-table-column  prop="content" label="最新记录" width="100" show-overflow-tooltip fixed="right" ></el-table-column> -->
      <el-table-column
        prop="recordCreationTime"
        label="跟进时间"
        width="100"
        show-overflow-tooltip
        fixed="right"
      ></el-table-column>
      <el-table-column
        prop="recordTime"
        label="下次跟进时间"
        width="110"
        show-overflow-tooltip
        fixed="right"
      ></el-table-column>
      <el-table-column label="操作" v-slot="scope" fixed="right" width="140">
        <div class="ActionColumn">
          <el-link
            @click="addcostoUp(scope.row)"
            class="ActionColumn_h"
            type="primary"
            >跟进</el-link
          >
          <el-link
            @click="optiobChange('record', scope)"
            class="ActionColumn_h"
            type="primary"
            >记录</el-link
          >
          <el-link
            @click="optiobChange('married', scope)"
            class="ActionColumn_h"
            type="primary"
            >成交</el-link
          >
        </div>
        <div class="ActionColumn">
          <el-link
            @click="optiobChange('edit', scope)"
            class="ActionColumn_h"
            type="primary"
            >编辑</el-link
          >
          <el-popconfirm
            confirm-button-text="确定"
            cancel-button-text="取消"
            icon-color="red"
            title="确定删除客户吗？"
            @confirm="optiobChange('del', scope)"
          >
            <el-link slot="reference" class="ActionColumn_h" type="primary"
              >删除</el-link
            >
          </el-popconfirm>
        </div>
        <!-- <div class="capzuo">
          <el-button
            type="primary"  plain  size="small"  @click="addcostoUp(scope.row)"  >
            线索跟进
          </el-button>
          <el-dropdown :hide-on-click="hideClick"  @command="handleCommand($event, scope)" :data-scope="scope"
            trigger="click" >
            <span class="el-dropdown-link">
              更多操作<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="record">记录</el-dropdown-item>
              <el-dropdown-item command="married">成交</el-dropdown-item>
              <el-dropdown-item command="edit">编辑</el-dropdown-item>
              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                icon-color="red"
                title="确定删除客户吗？"
                @confirm="optiobChange()" >
                <el-dropdown-item slot="reference" command="del">删除</el-dropdown-item >
              </el-popconfirm>
            </el-dropdown-menu>
          </el-dropdown>
        </div> -->
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      style="margin-top: 18px"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="viewActiveFrom.pageNum"
      :page-sizes="[5, 8, 10, 12]"
      :page-size="viewActiveFrom.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>

    <!-- 新增框 -->
    <el-drawer
      title="新增线索"
      :visible.sync="followUpdrawer"
      v-loading="loadingTab"
    >
      <div class="From_item">
        <div>机构名称</div>
        <el-input v-model="EditFrom.clue" placeholder="请输入"></el-input>
      </div>
      <div class="From_item">
        <div>机构规模</div>
        <el-input v-model="EditFrom.scale" placeholder="请输入"></el-input>
      </div>
      <div class="From_item">
        <div>所在地区</div>
        <el-cascader
          v-model="EditFrom.region"
          :options="cityBriefData"
          :props="{ checkStrictly: true }"
          placeholder="请选择地区"
          clearable
        ></el-cascader>
      </div>
      <div class="From_item">
        <div>是否使用系统</div>
        <el-input v-model="EditFrom.use" placeholder="请输入"></el-input>
        <!-- <el-select v-model="EditFrom.useSys" placeholder="请选择">
          <el-option
            v-for="(item, index) in useSysList"
            :key="index"
            :label="item"
            :value="item">
          </el-option>
        </el-select> -->
      </div>
      <div class="From_item">
        <div>系统满意度</div>
        <el-input v-model="EditFrom.satisfied" placeholder="请输入"></el-input>
        <!-- <el-select v-model="EditFrom.useSys" placeholder="请选择">
          <el-option
            v-for="(item, index) in useSysList"
            :key="index"
            :label="item"
            :value="item">
          </el-option>
        </el-select> -->
      </div>
      <div class="From_item">
        <div>推荐机构</div>
        <!-- <el-input v-model="EditFrom.useSys" placeholder="请输入"></el-input> -->
        <el-autocomplete
          v-model="state"
          :fetch-suggestions="querySearchAsync"
          placeholder="请选择品牌"
          @select="handleSelect"
          style="width: 70%"
          clearable
          @clear="brandClear"
        ></el-autocomplete>
      </div>
      <div class="From_item">
        <div>联系人</div>
        <el-input v-model="EditFrom.name" placeholder="请输入"></el-input>
      </div>
      <div class="From_item">
        <div>联系方式</div>
        <el-input
          v-model="EditFrom.phone"
          placeholder="请输入手机号/微信/QQ"
        ></el-input>
      </div>
      <div class="From_item">
        <div>联系人身份</div>
        <el-select v-model="EditFrom.identity" placeholder="请选择">
          <el-option
            v-for="(item, index) in clueIdentityList"
            :key="index"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </div>

      <div class="From_item">
        <div>接入渠道</div>
        <el-select v-model="EditFrom.channelId" placeholder="请选择">
          <el-option
            v-for="(item, index) in channelList"
            :key="index"
            :label="item.channel"
            :value="item.channelId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="From_item">
        <div>意向情况</div>
        <el-select v-model="EditFrom.intention" placeholder="请选择">
          <el-option
            v-for="(item, index) in intentionList"
            :key="index"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </div>
      <div class="From_item">
        <div>负责人</div>
        <el-select v-model="EditFrom.staffId" placeholder="请选择">
          <el-option
            v-for="(item, index) in staffSaleList"
            :key="index"
            :label="item.name"
            :value="item.staffId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="From_item">
        <div>聊天截图</div>
        <el-upload
          class="upload-demo"
          action=""
          :on-change="handleChangecreenshot"
          :on-remove="removeChanges"
          :auto-upload="false"
          :file-list="fileListscreenshot"
          :limit="1"
          style="width: 70%"
        >
          <el-button size="small" type="primary" style="float: left"
            >点击上传</el-button
          >
          <div class="el-upload__tip" slot="tip" style="width: 100%">
            只能上传1张图片
          </div>
        </el-upload>
      </div>
      <div class="dialog-footer">
        <el-button @click="followUpdrawer = false">取 消</el-button>
        <el-button type="primary" @click="OKEdit()">确 定</el-button>
      </div>
    </el-drawer>

    <!-- 编辑框 -->
    <el-drawer
      title="编辑线索"
      :visible.sync="EditCluesfollowr"
      v-loading="loadingTab"
      @close="EditCluesfollowrClose"
    >
      <div class="From_item">
        <div>机构名称</div>
        <el-input v-model="EditClues.clue" placeholder="请输入"></el-input>
      </div>
      <div class="From_item">
        <div>机构规模</div>
        <el-input v-model="EditClues.scale" placeholder="请输入"></el-input>
      </div>
      <div class="From_item">
        <div>所在地区</div>
        <el-cascader
          v-model="EditClues.region"
          :options="cityBriefData"
          :props="{ checkStrictly: true }"
          placeholder="请选择地区"
          clearable
        ></el-cascader>
      </div>
      <div class="From_item">
        <div>是否使用系统</div>
        <el-input v-model="EditClues.use" placeholder="请输入"></el-input>
        <!-- <el-select v-model="EditFrom.useSys" placeholder="请选择">
          <el-option
            v-for="(item, index) in useSysList"
            :key="index"
            :label="item"
            :value="item">
          </el-option>
        </el-select> -->
      </div>
      <div class="From_item">
        <div>系统满意度</div>
        <el-input v-model="EditClues.satisfied" placeholder="请输入"></el-input>
        <!-- <el-select v-model="EditFrom.useSys" placeholder="请选择">
          <el-option
            v-for="(item, index) in useSysList"
            :key="index"
            :label="item"
            :value="item">
          </el-option>
        </el-select> -->
      </div>
      <div class="From_item">
        <div>推荐机构</div>
        <!-- <el-input v-model="EditFrom.useSys" placeholder="请输入"></el-input> -->
        <el-autocomplete
          v-model="state"
          :fetch-suggestions="querySearchAsync"
          placeholder="请选择品牌"
          @select="handleSelect"
          style="width: 70%"
          clearable
          @clear="brandClear"
        ></el-autocomplete>
      </div>
      <div class="From_item">
        <div>联系人</div>
        <el-input v-model="EditClues.name" placeholder="请输入"></el-input>
      </div>
      <div class="From_item">
        <div>联系方式</div>
        <el-input
          v-model="EditClues.phone"
          placeholder="请输入手机号/微信/QQ"
        ></el-input>
      </div>
      <div class="From_item">
        <div>联系人身份</div>
        <el-select v-model="EditClues.identity" placeholder="请选择">
          <el-option
            v-for="(item, index) in clueIdentityList"
            :key="index"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </div>

      <div class="From_item">
        <div>接入渠道</div>
        <el-select v-model="EditClues.channelId" placeholder="请选择">
          <el-option
            v-for="(item, index) in channelList"
            :key="index"
            :label="item.channel"
            :value="item.channelId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="From_item">
        <div>意向情况</div>
        <el-select v-model="EditClues.intention" placeholder="请选择">
          <el-option
            v-for="(item, index) in intentionList"
            :key="index"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </div>
      <div class="From_item">
        <div>负责人</div>
        <el-select v-model="EditClues.staffId" placeholder="请选择">
          <el-option
            v-for="(item, index) in staffSaleList"
            :key="index"
            :label="item.name"
            :value="item.staffId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="From_item">
        <div>聊天截图</div>
        <el-upload
          class="upload-demo"
          action=""
          :on-change="handleChangecreenshot"
          :on-remove="removeChanges"
          :auto-upload="false"
          :file-list="fileListscreenshot"
          :limit="1"
          style="width: 70%"
        >
          <el-button size="small" type="primary" style="float: left"
            >点击上传</el-button
          >
          <div class="el-upload__tip" slot="tip" style="width: 100%">
            只能上传1张图片
          </div>
        </el-upload>
      </div>
      <div class="dialog-footer">
        <el-button @click="EditCluesfollowr = false">取 消</el-button>
        <el-button type="primary" @click="EditCluesClick()">确 定</el-button>
      </div>
    </el-drawer>

    <!-- 添加跟进 -->
    <el-dialog
      title="新增记录"
      :visible.sync="dialogVisible"
      width="30%"
      v-loading="loadingTab"
    >
      <div class="From_item">
        <div>下次跟进时间</div>
        <el-date-picker
          v-model="recordTime"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </div>

      <div class="From_item">
        <div>新增记录</div>
        <el-input
          type="textarea"
          :rows="4"
          v-model="recordContent"
          placeholder="请输入内容"
          style="width: 70%"
        >
        </el-input>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addFollowcol()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 记录弹窗 -->
    <el-dialog
      title="记录"
      :visible.sync="dialogrecord"
      width="40%"
      v-loading="loadingTab"
    >
      <el-table :data="recordData" stripe style="width: 100%" max-height="500">
        <el-table-column prop="creationTime" label="时间" width="100">
        </el-table-column>
        <el-table-column prop="operation" label="操作"> </el-table-column>
        <el-table-column prop="chargeName" label="操作人"> </el-table-column>
        <el-table-column
          prop="content"
          label="内容"
          width="250"
        ></el-table-column>
        <!-- <el-table-column label="操作"  width="250"> 
          <template slot-scope="scoped">
            <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                icon-color="red"
                title="确定删除客户吗?"
                @confirm="deleteRecord(scoped.row)" >
                <el-button  slot="reference" type="danger"  plain  icon="el-icon-close"
                      size="small" >删除</el-button >
              </el-popconfirm>
          </template>
        </el-table-column> -->
      </el-table>
      <el-pagination
        style="margin-top: 18px"
        @size-change="handleSizeChange1"
        @current-change="handleCurrentChange1"
        :current-page.sync="Recordfrom.pageNum"
        :page-size="Recordfrom.pageSize"
        layout="prev, pager, next"
        :total="recordtotal"
      >
      </el-pagination>
    </el-dialog>

    <!-- 成交弹窗 -->
    <el-drawer
      title="成交"
      :visible.sync="dialogmarried"
      width="30%"
      @close="closeDialog"
      v-loading="loadingTab"
    >
      <div class="From_item">
        <div>支付套餐</div>
        <el-select v-model="marriedFrom.stageId" placeholder="请选择">
          <el-option
            v-for="(item, index) in signContractStageList"
            :key="index"
            :label="item.stage"
            :value="item.stageId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="From_item">
        <div>上传凭证</div>
        <el-upload
          class="upload-demo"
          action=""
          :on-change="handleChange"
          :on-remove="removeChange"
          :auto-upload="false"
          :file-list="fileList"
          :limit="1"
          style="width: 70%"
        >
          <el-button size="small" type="primary" style="float: left"
            >点击上传</el-button
          >
          <div class="el-upload__tip" slot="tip" style="width: 100%">
            只能上传1张图片
          </div>
        </el-upload>
      </div>
      <div class="From_item">
        <div>营业执照</div>
        <el-upload
          class="upload-demo"
          action=""
          :on-change="handleChangeBus"
          :on-remove="removeChange"
          :auto-upload="false"
          :file-list="busList"
          :limit="1"
          style="width: 70%"
        >
          <el-button size="small" type="primary" style="float: left"
            >点击上传</el-button
          >
          <div class="el-upload__tip" slot="tip" style="width: 100%">
            只能上传1张图片
          </div>
        </el-upload>
      </div>
      <div class="From_item">
        <div>购买理由</div>
        <el-input v-model="marriedFrom.reason" placeholder="请输入"></el-input>
      </div>
      <!-- <div class="From_item">
        <div>是否需要开支票</div>
        <div style="width: 70%; text-align: left; padding-left: 8px">
          <el-radio v-model="marriedFrom.invoiceStatus" label="1">是</el-radio>
          <el-radio v-model="marriedFrom.invoiceStatus" label="0">否</el-radio>
        </div>
      </div> -->
      <div class="From_item">
        <div>选择品牌</div>
        <el-autocomplete
          v-model="state"
          :fetch-suggestions="querySearchAsync"
          placeholder="请选择品牌"
          @select="handleSelect"
          style="width: 70%"
        ></el-autocomplete>
      </div>
      <div class="From_item">
        <div>绑定的校区</div>
        <el-select
          v-model="marriedFrom.mechanismIdList"
          filterable
          multiple
          placeholder="请选择"
        >
          <el-option
            v-for="item in mechanismDataList"
            :key="item.mechanismId"
            :label="item.mechanism"
            :value="item.mechanismId"
          >
          </el-option>
        </el-select>
      </div>

      <div class="From_item">
        <div>备注</div>
        <el-input
          v-model="marriedFrom.dealRemarks"
          placeholder="请输入"
        ></el-input>
      </div>
      <div class="From_item">
        <div>定制小程序</div>
        <el-select v-model="marriedFrom.param1" placeholder="请选择">
          <el-option
            v-for="(item, index) in Completed"
            :key="index"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <div class="From_item">
        <div>公众号关联</div>
        <el-select v-model="marriedFrom.param2" placeholder="请选择">
          <el-option
            v-for="(item, index) in Completed"
            :key="index"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <div class="From_item">
        <div>腾讯电子签</div>
        <el-select v-model="marriedFrom.param3" placeholder="请选择">
          <el-option
            v-for="(item, index) in Completed"
            :key="index"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <div class="From_item">
        <div>使用培训</div>
        <el-select v-model="marriedFrom.param4" placeholder="请选择">
          <el-option
            v-for="(item, index) in Completed"
            :key="index"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <div class="From_item">
        <div>数据导入</div>
        <el-select v-model="marriedFrom.param5" placeholder="请选择">
          <el-option
            v-for="(item, index) in Completed"
            :key="index"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <div class="From_item">
        <div>品宣设计</div>
        <el-select v-model="marriedFrom.param6" placeholder="请选择">
          <el-option
            v-for="(item, index) in Completed"
            :key="index"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <div class="dialog-footer">
        <el-button @click="dialogmarried = false">取 消</el-button>
        <el-button type="primary" @click="marriedClick()">确 定</el-button>
      </div>
    </el-drawer>

    <!-- 批量跟进提示 -->
    <el-dialog title="提示" :visible.sync="BatchFollowVisible" width="35%">
      <p>
        批量跟进的下次跟进时间是<span class="text-red">‘第二天’</span
        >，跟进内容固定是 <span class="text-red">‘待客户回复’</span>
      </p>
      <br />
      <p class="text-red">*具体跟进内容请移步具体线索的跟进</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="BatchFollowVisible = false">取 消</el-button>
        <el-button type="primary" @click="OKBatchFollow"
          >确定批量跟进</el-button
        >
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import {
  clueIntentionSearch,
  channelListSearch,
  signContractStageList,
  staffSaleSearch,
  clueIdentitySearch,
  cluePageList,
  clueAdd,
  clueRecordAdd,
  clueRecordPageList,
  clueDel,
  clueUpd,
  financeAdd,
  clueBatchRecordAdd,
} from "@/api/distributionChannel";
import { brandList, mechanismPageList, uploadAdd } from "@/api/api";

import { putObject } from "@/util/cos-helper";
import { cityBriefData } from "@/util/city-brief-data";

export default {
  data() {
    return {
      viewActiveFrom: {
        remarks: "",
        pageSize: 8,
        pageNum: 1,
        intention: "",
        dayBefore: "",
        continuationDay: 0,
      },
      time: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      channelId: "",
      staffId: "",
      state: "",
      recordData: [],
      tableData: [],
      total: 0,
      recordtotal: 0,
      // 下拉存储数据
      hideClick: false,
      operationType: "",
      scopeRowFrom: {},

      // 新增编辑框
      index: 0,
      followUpdrawer: false,
      EditFrom: {
        clue: "",
        scale: "",
        region: "",
        name: "",
        phone: "",
        identity: "",
        channelId: "",
        staffId: "",
        intention: "",
        staffName: "",
        use: "",
      },
      // 编辑框
      EditClues: {
        clue: "",
        scale: "",
        region: "",
        name: "",
        phone: "",
        identity: "",
        channelId: "",
        staffId: "",
        intention: "",
        staffName: "",
        use: "",
        satisfied:''
      },
      EditCluesfollowr: false,

      ContactIdentity: ["主理人", "合伙人", "店长", "其他"],
      useSysList: ["是", "否", "不确定"],
      // 跟进记录内容
      dialogVisible: false,
      recordContent: "",
      recordTime: "",

      // 记录
      dialogrecord: false,

      // 成交
      dialogmarried: false,
      marriedFrom: {
        stageId: "",
        picUrl: "",
        reason: "",
        mechanismList: "",
        mechanismIdList: "1",
        stageName: "",
        dealRemarks: "",
        invoiceStatus: "1",
        brandId: "",
        staffId: "",
        param1: "不需要",
        param2: "不需要",
        param3: "不需要",
        param4: "不需要",
        param5: "不需要",
        param6: "不需要",
      },

      // 文件导入
      fileList: [], //         一键导入文件数据
      // 需要支票
      label: "1",
      // 列表
      intentionList: [], //意向
      channelList: [], //渠道
      staffSaleList: [], //负责人
      clueIdentityList: [], //联系人
      signContractStageList: [], //套餐

      // 校区列表
      mechanismDataList: [],
      // 线索
      Recordfrom: {
        pageNum: 1,
        pageSize: 5,
      },

      EditStatus: 0,
      loadingTab: true,
      submitState: true,
      Completed: ["不需要", "待操作", "已完成"],
      fileListscreenshot: [],
      busList: [],
      deldFalw: false,
      cityBriefData: [],
      brandId: "",
      brandName: "",
      // 批量跟进数组
      multipleSelection: [],
      BatchFollowVisible: false,
    };
  },

  mounted() {
    this.getData();
    this.getTableDada();
    this.cityBriefData = cityBriefData;
    // console.log(this.cityBriefData);
  },

  methods: {
    // 获取列表
    async getData() {
      let res1 = await clueIntentionSearch({});
      this.intentionList = res1.data;
      console.log("this.intentionList", this.intentionList);
      let res2 = await channelListSearch({});
      this.channelList = res2.data;
      let res3 = await staffSaleSearch({});
      this.staffSaleList = res3.data;
      let res4 = await clueIdentitySearch({});
      this.clueIdentityList = res4.data;
      let res5 = await signContractStageList({});
      this.signContractStageList = res5.data;
    },

    // 获取表格数据
    async getTableDada() {
      console.log(this.viewActiveFrom);
      // this.viewActiveFrom.staffId = this.viewActiveFrom.staffId === '' ? delete this.viewActiveFrom.staffId : parseInt(this.viewActiveFrom.staffId)
      if (this.channelId !== "") {
        this.viewActiveFrom.channelId = parseInt(this.channelId);
      } else {
        delete this.viewActiveFrom.channelId;
      }
      if (this.staffId !== "") {
        this.viewActiveFrom.staffId = parseInt(this.staffId);
      } else {
        delete this.viewActiveFrom.staffId;
      }
      this.viewActiveFrom.dayBefore =
        parseInt(this.viewActiveFrom.dayBefore) || 0;
      this.viewActiveFrom.start = this.time.length ? this.time[0] : undefined;
      this.viewActiveFrom.end = this.time.length ? this.time[1] : undefined;
      let res = await cluePageList(this.viewActiveFrom);
      console.log(res);
      this.loadingTab = false;
      this.total = parseInt(res.data.total);
      this.tableData = res.data.list;
    },

    // 监听pageSize事件
    handleSizeChange(newsize) {
      this.viewActiveFrom.pageSize = newsize;
      // 重新获取数据
      this.getTableDada();
    },

    handleCurrentChange(newpage) {
      this.viewActiveFrom.pageNum = newpage;
      // 重新获取数据
      this.getTableDada();
    },
    // 监听pageSize事件
    handleSizeChange1(newsize) {
      this.Recordfrom.pageSize = newsize;
      // 重新获取数据
      this.getRecordTable();
    },

    handleCurrentChange1(newpage) {
      this.Recordfrom.pageNum = newpage;
      // 重新获取数据
      this.getRecordTable();
    },
    // 选择操作
    handleCommand(option, scope) {
      console.log(scope.row);
      this.operationType = option; // 设置操作类型
      this.scopeRowFrom = scope.row;
      if (option != "del" && option != "loss") {
        this.hideClick = false; // 非删除、流失操作 自动关闭下拉列表
        this.optiobChange();
      }
    },

    // 操作执行
    async optiobChange(type, scope) {
      // const scope = this.operationType;
      this.scopeRowFrom = scope.row;
      switch (type) {
        case "record":
          console.log("记录");
          this.getRecordTable();
          break;
        case "married":
          console.log("成交");
          this.dialogmarried = true;
          break;
        case "edit":
          console.log("编辑");
          console.log(this.scopeRowFrom);
          this.EditCluesfollowr = true;
          this.EditClues.clueId = this.scopeRowFrom.clueId;
          this.EditClues.channelId = this.scopeRowFrom.channelId;
          this.EditClues.clue = this.scopeRowFrom.clue;
          this.EditClues.scale = this.scopeRowFrom.scale;
          this.EditClues.region = this.scopeRowFrom.regionCode;

          this.EditClues.use = this.scopeRowFrom.use;
          this.EditClues.satisfied = this.scopeRowFrom.satisfied;
          this.EditClues.name = this.scopeRowFrom.name;
          this.EditClues.phone = this.scopeRowFrom.phone;
          this.EditClues.identity = this.scopeRowFrom.identity;
          this.EditClues.intention = this.scopeRowFrom.intention;
          this.EditClues.staffId = this.scopeRowFrom.staffId;
          this.state = this.scopeRowFrom.recommend;
          
          // this.brandId = this.scopeRowFrom.recommend_clue_id
          // this.brandName = this.scopeRowFrom.recommend
          // this.EditFrom = JSON.parse(JSON.stringify(this.scopeRowFrom));
          // this.followUpdrawer = true;
          // this.EditStatus = 1;
          // this.index = 0
          // this.fileListscreenshot =  this.EditFrom.picUrl

          this.fileListscreenshot.push({
            name: this.scopeRowFrom.picUrl,
            url: this.scopeRowFrom.picUrl,
          });
          break;
        case "del":
          // 删除事件
          // console.log("删除");
          let res = await clueDel({ clueId: this.scopeRowFrom.clueId });
          if (res.status === "success") {
            this.$message.success("操作成功");
          } else {
            this.$message.error("操作失败");
          }
          this.getTableDada();
          break;
        default:
          console.error("无法获取操作类型");
          break;
      }
    },
    // 获取记录表格数据
    async getRecordTable() {
      this.dialogrecord = true;
      this.Recordfrom.clueId = this.scopeRowFrom.clueId;
      let res = await clueRecordPageList(this.Recordfrom);
      console.log(res.data);
      this.recordData = res.data.list;
      this.recordtotal = parseInt(res.data.total);
    },

    // 添加跟进记录
    addfollowUp(index) {
      this.followUpdrawer = true;
      this.index = index;
    },
    // 打开跟进记录弹窗
    async addcostoUp(item) {
      console.log(item);
      this.dialogVisible = true;
      this.scopeRowFrom = item;
    },

    // 新增线索
    async OKEdit() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      // console.log(this.EditFrom);
      this.staffSaleList.forEach((item) => {
        if (item.staffId === this.EditFrom.staffId) {
          // this.EditFrom.staffId = item.staffId
          this.EditFrom.staffName = item.name;
        }
      });
      console.log("biaodan", this.EditFrom);
      // return
      let res;
      // 添加
      // if (this.EditStatus === 0) {
      console.log("新增");

      let fileUrl = "";
      if (this.fileListscreenshot && this.fileListscreenshot.length) {
        let res = await putObject(this.fileListscreenshot[0]);
        console.log(res);
        fileUrl = res.data.url;
      }
      this.EditFrom.picUrl = fileUrl;
      for (const k in this.EditFrom) {
        if (
          !this.EditFrom[k] &&
          k !== "phone" &&
          k !== "use" &&
          k !== "chargeName" &&
          k !== "recommendClueId" &&
          k !== "recommend"
        ) {
          this.$message.error("参数不能为空");
          loading.close();
          return;
        }
      }
      if (this.fileListscreenshot.length === 0) {
        this.$message.error("请上传聊天截图");
        loading.close();
        return;
      }
      console.log("执行");
      this.loadingTab = true;
      this.EditFrom.recommendClueId = this.brandId;
      this.EditFrom.recommend = this.brandName;

      console.log("数据", this.EditFrom);
      this.EditFrom.region =
        this.EditFrom.region[this.EditFrom.region.length - 1];
      // this.channelFormData.region = this.channelFormData.region.slice(-1).toString()
      // return
      console.log(this.EditFrom);
      res = await clueAdd(this.EditFrom);
      loading.close();

      this.loadingTab = false;
      this.followUpdrawer = false;
      console.log(res);
      if (res.status === "success") {
        console.log("成功");
        this.getTableDada();

        this.$message.success("操作成功");
      } else {
        this.$message.error("操作失败");
      }
      // console.log("添加");
      // } else {
      //   // 编辑
      //   let postData = {
      //     clueId: this.EditFrom.clueId,
      //     clue: this.EditFrom.clue,
      //     identity: this.EditFrom.identity,
      //     scale: this.EditFrom.scale,
      //     name: this.EditFrom.name,
      //     region: this.EditFrom.region,
      //     channelId: this.EditFrom.channelId,
      //     staffName: this.EditFrom.staffName,
      //     staffId: this.EditFrom.staffId,
      //     intention: this.EditFrom.intention,
      //     phone: this.EditFrom.phone,
      //   };
      //   console.log('this.postData',this.postDatas)
      //   for(const k in this.postData) {
      //     if(!this.postData[k] && k !== 'phone') {
      //       this.$message.error("参数不能为空");
      //       return;
      //     }
      //   }
      //   if(this.fileListscreenshot.length ===0){
      //     this.$message.error("请上传截图");
      //     return;
      //   }
      //   postData.region = this.EditFrom.region.slice(-1).toString()
      //   this.loadingTab = true
      //   if(this.deldFalw){
      //     console.log("换图片");
      //     let fileUrl = "";
      //     if(this.fileListscreenshot && this.fileListscreenshot.length){
      //     let res = await putObject(this.fileListscreenshot[0])
      //       console.log(res);
      //     fileUrl = res.data.url
      //   }
      //     postData.picUrl = fileUrl
      //   }
      //   postData.recommendClueId = this.brandId
      //   postData.recommend = this.brandName
      //   postData.use =  this.EditFrom.use
      //   postData.satisfied = this.EditFrom.satisfied

      //   res = await clueUpd(postData);
      //   console.log("编辑");
      // }
    },

    async EditCluesClick() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let postData = this.EditClues;
      if (this.fileListscreenshot.length === 0) {
        this.$message.error("请上传聊天截图");
        loading.close();

        return;
      }
      this.staffSaleList.forEach((item) => {
        if (item.staffId === postData.staffId) {
          // this.EditFrom.staffId = item.staffId
          postData.staffName = item.name;
        }
      });
      for (const k in postData) {
        if (!postData[k] && k !== "region") {
          this.$message.error("参数不能为空");
          loading.close();
          return;
        }
      }
      console.log(this.EditClues);
      console.log(this.EditClues.region);
      postData.region = this.EditClues.region ?  this.EditClues.region.slice(-1).toString() : '';
      console.log(postData);

      if (this.deldFalw) {
        console.log("换图片");
        let fileUrl = "";
        if (this.fileListscreenshot && this.fileListscreenshot.length) {
          let res = await putObject(this.fileListscreenshot[0]);
          console.log(res);
          fileUrl = res.data.url;
        }
        postData.picUrl = fileUrl;
      }
      loading.close();

      // return
      let res = await clueUpd(postData);
      loading.close();
      this.loadingTab = false;
      this.EditCluesfollowr = false;
      console.log(res);
      if (res.status === "success") {
        console.log("成功");
        this.getTableDada();

        this.$message.success("操作成功");
      } else {
        this.$message.error("操作失败");
      }
    },

    // 确认添加添加跟进记录
    async addFollowcol() {
      console.log("添加跟进记录");
      if (this.recordContent == "" || this.recordContent == null) {
        console.log(1);
        this.$message.error("请填写跟进内容");
        return;
      }
      if (this.recordTime == "" || this.recordTime == null) {
        console.log(1);
        this.$message.error("请填写跟进时间");
        return;
      }
      let postData = {
        content: this.recordContent,
        clueId: this.scopeRowFrom.clueId,
        recordTime: this.recordTime,
      };
      this.loadingTab = true;
      console.log(postData);
      let res = await clueRecordAdd(postData);
      if (res.status === "success") {
        this.$message.success("操作成功");
      } else {
        this.$message.error("操作失败");
      }
      // console.log(2);
      this.dialogVisible = false;
      (this.recordContent = ""), this.getTableDada();
      // let res = await
      this.loadingTab = true;
      this.recordTime = "";
    },

    // 成交转财务
    async marriedClick() {
      // console.log(this.marriedFrom.stageId);
      console.log(this.marriedFrom.mechanismIdList.length);
      console.log(this.marriedFrom.mechanismIdList);
      // console.log(this.fileList);
      if (
        this.marriedFrom.stageId === "" ||
        this.marriedFrom.stageId === null
      ) {
        this.$message.error("请选择套餐");
        return;
      }
      // if(this.marriedFrom.reason === ''||this.marriedFrom.reason===null ){
      //   this.$message.error("请输入购买理由")
      //   return
      // }
      if (this.state === "") {
        this.$message.error("请选择品牌");
        return;
      }
      if (
        this.marriedFrom.mechanismIdList.length === 0 ||
        !this.marriedFrom.mechanismIdList
      ) {
        this.$message.error("请选择校区");
        return;
      }
      if (this.fileList.length === 0) {
        this.$message.error("请选择上传凭证");
        return;
      }
      if (this.busList.length === 0) {
        this.$message.error("请选择上传营业执照");
        return;
      }
      this.loadingTab = true;

      this.marriedFrom.mechanismList = this.mechanismDataList
        .filter((list) =>
          this.marriedFrom.mechanismIdList.includes(list.mechanismId)
        )
        .map((o) => o.mechanism);
      console.log(this.signContractStageList);
      this.signContractStageList.forEach((item) => {
        if (item.stageId === this.marriedFrom.stageId) {
          console.log(1);
          this.marriedFrom.stageName = item.stage;
        }
      });
      this.marriedFrom.staffId = this.scopeRowFrom.staffId;
      this.marriedFrom.clueId = this.scopeRowFrom.clueId;

      console.log(this.marriedFrom);
      // return
      let fileUrl = "";
      if (this.fileList && this.fileList.length) {
        let res = await putObject(this.fileList[0]);
        console.log(res);
        fileUrl = res.data.url;
      }
      this.marriedFrom.picUrl = fileUrl;
      let busList = "";
      if (this.busList && this.busList.length) {
        let res = await putObject(this.busList[0]);
        console.log(res);
        busList = res.data.url;
      }
      this.marriedFrom.busUrl = busList;

      let data = await financeAdd(this.marriedFrom);
      console.log(data);
      if (data.status === "success") {
        this.$message.success("操作成功");
        this.getTableDada();
        this.dialogmarried = false;
      } else {
        this.$message.error("操作失败");
      }
      this.loadingTab = false;
      this.fileList = [];
      this.busList = "";
      this.marriedFrom.stageId = "";
      this.mechanismDataList = [];
      this.marriedFrom.mechanismIdList = [];
    },

    // 文件上传
    handleChange(file, fileList) {
      console.log(fileList);
      this.fileList = fileList;
    },

    handleChangeBus(file, fileList) {
      this.busList = fileList;
    },

    handleChangecreenshot(file, fileList) {
      console.log(fileList);

      this.fileListscreenshot = fileList;
    },

    // 删除文件
    removeChange(file, fileList) {
      console.log(file, fileList);
    },

    removeChanges(file, fileList) {
      console.log(1);
      this.deldFalw = true;
      this.fileListscreenshot = [];
    },

    // 删除记录
    deleteRecord(from) {
      console.log(from);
    },
    // 编辑关闭
    EditCluesfollowrClose() {
      this.EditClues.clueId = "";
      this.EditClues.channelId = "";
      this.EditClues.clue = "";
      this.EditClues.scale = "";
      this.EditClues.region = "";
      this.EditClues.use = "";
      this.EditClues.satisfied = "";
      this.EditClues.name = "";
      this.EditClues.phone = "";
      this.EditClues.identity = "";
      this.EditClues.intention = "";
      this.EditClues.staffId = "";
      this.state = "";
      this.fileListscreenshot = [];
    },
    // 关闭添加
    // closefollowUp(){
    //   // this.closefollowUp
    //   this.EditStatus = 0

    //   // window.location.reload()
    //   this.fileListscreenshot = []
    //   delete this.EditFrom.channel
    //   delete this.EditFrom.clueId
    //   delete this.EditFrom.content
    //   delete this.EditFrom.creationTime
    //   delete this.EditFrom.recordTime
    //   delete this.EditFrom.recordCreationTime
    //   delete this.EditFrom.channelId
    //   delete this.EditFrom.name

    //   console.log(this.EditFrom);
    //   if (this.index !== 1) {
    //     console.log("清理");
    //     this.state = ''
    //     this.brandId = ''
    //     this.brandName = ''
    //     // this.EditFrom = this.scopeRowFrom;
    //     // console.log(1);
    //     for(const k in this.EditFrom) {
    //       this.EditFrom[k] =''
    //     }
    //   }else{
    //     console.log("不清理");
    //   }
    // },
    // 关闭成交
    closeDialog() {
      // console.log(1);
      this.marriedFrom.dealRemarks = "";
      this.fileList = [];
      this.busList = [];
      this.state = "";
    },

    // 表格多选操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // console.log(val);
    },
    // 确定批量操作跟进
    async OKBatchFollow() {
      if (!this.multipleSelection.length) {
        return this.$message.error("请勾选跟进线索");
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let arr = [];
      this.multipleSelection.forEach((itme) => {
        arr.push(itme.clueId);
      });
      console.log(arr);
      // this.
      let res = await clueBatchRecordAdd(arr);
      console.log(res);
      loading.close();
      if (res.status === "success") {
        this.$message.success("操作成功");
        this.getTableDada();
        this.BatchFollowVisible = false;
      } else {
        this.$message.error("操作失败");
      }
    },

    // 品牌校区搜索
    async querySearchAsync(queryString, cb) {
      const brandDataList = await brandList({ remarks: queryString });
      console.log(brandDataList);
      let restaurants = [];
      for (let a = 0; a < brandDataList.data.length; a++) {
        restaurants.push({
          id: brandDataList.data[a].id,
          value: brandDataList.data[a].name,
        });
      }
      // console.log(brandDataList);
      // console.log(restaurants);
      // console.log(queryString);
      // let results = queryString
      //   ? restaurants.filter(this.createStateFilter(queryString))
      //   : restaurants;
      let results = restaurants;
      // console.log(results);
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(results);
      }, 3000 * Math.random());
    },

    // 序列化操作
    createStateFilter(queryString) {
      return (state) => {
        return (
          state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    // 校区搜索
    async handleSelect(item) {
      const data = {
        pageNumber: 1,
        pageSize: 100,
        brandId: item.id,
      };
      // console.log(item);
      this.marriedFrom.brandId = item.id;
      this.brandId = item.id;
      this.brandName = item.value;
      // console.log(this.brandId );
      // console.log(this.brandName );

      const mechanismDataList = await mechanismPageList(data);
      // console.log(mechanismDataList);

      this.mechanismDataList = mechanismDataList.data.list;
      // console.log(this.mechanismDataList);
    },
    // 校区清空
    brandClear() {
      // console.log("清空");
      this.brandId = "";
      this.brandName = "";
    },
  },
};
</script>

<style scoped>
.Sittring_top {
  width: 100%;
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  /* background-color: #5e6cf4; */
}
.Sittring_top-item {
  display: flex;
  align-items: center;
  margin-left: 5px;
  font-size: 14px;
}
.Sittring_top-item .el-select,
.Sittring_top-item .el-input {
  width: 60%;
  margin-left: 8px;
}
.button {
  width: 80px;
  height: 35px;
  color: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #5e6cf4;
}
.capzuo {
  display: flex;
  justify-content: space-between;
}
.el-collapse {
  width: 100px;
  border: none;
}
.el-collapse-item {
  /* max-height: 100px;
  overflow: auto; */
  background-color: #f8fafc;
}
.sele_but {
  margin-bottom: 5px;
}

/* 添加表单样式 */
.From_item {
  width: 90%;
  height: 70rpx;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.From_item div {
  width: 30%;
}
.From_item .el-input,
.From_item .el-select,
.From_item .el-cascader {
  width: 70%;
}
.dialog-footer {
  text-align: center;
  margin-top: 50px;
}
.ActionColumn {
  margin: auto;
  text-align: left;
}
.ActionColumn .ActionColumn_h {
  margin-right: 10px;
  font-size: 14px;
}
.ActionColumn .ActionColumn_h:hover {
  text-decoration: underline;
  font-weight: bold;
}

/* 红色字体 */
.text-red {
  color: red;
}
</style>