<template>
  <el-container>
    <el-aside :width="menuElasticity ? '160px' : '0px'">
      <!-- LOGO -->
      <div class="heard_img" @click="gsdfga()">
        <img
          src="https://6a61-jambox-dance-student-1b5e4294ddf-1308699755.tcb.qcloud.la/oam.png?sign=2e489bbe2648c264dac8ccd43a1aae25&t=1657265698"
          alt=""
        />
      </div>
      <!-- 菜单列表 -->
      <div>
        <el-menu
          class="el-menu-vertical-demo"
          background-color="#5e6cf4"
          active-text-color="#FFDAB9"
          :unique-opened="true"
          text-color="#fff"
          router
          style="border-right: 2px solid #5e6cf4"
          :default-active="activepath">
          <!-- 首页 -->
          <el-menu-item index="/Welcomes" @click="saveNavState('/Welcomes')">
            <i class="el-icon-monitor"></i>
            <span slot="title">首页</span>
          </el-menu-item>
          <template v-for="item in functionList">
            <template v-if="item.hide && item.buttons && !item.buttons.length"></template>
            <template v-else-if="item.hide">
              <el-menu-item
                :index="item.children[0].path"
                :key="item.children[0].name"
                @click="saveNavState(item.children[0].path)"
              >
                <i :class="item.children[0].meta.icon"></i>
                <span slot="title">{{ item.children[0].meta.title }}</span>
              </el-menu-item>
            </template>
            <template v-else>
              <el-submenu :key="item.name" :index="item.path">
                <template slot="title">
                  <i :class="item.meta.icon"></i>
                  <span>{{ item.meta.title }}</span>
                </template>
                <el-menu-item
                  v-for="subItem in item.children"
                  :key="subItem.name"
                  :index="subItem.path"
                  @click="saveNavState(subItem.path)"
                  style="min-width: 0px"
                  >{{ subItem.meta.title }}
                </el-menu-item>
              </el-submenu>
            </template>
          </template>
          
          <!-- 
          <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-open"></i>
              <span>员工管理</span>
            </template>
            <el-menu-item index="/employee/PermissionGroup" @click="saveNavState('/employee/PermissionGroup')" style="min-width: 0px;">权限组</el-menu-item>
            <el-menu-item index="/EmployeeSettings"  @click="saveNavState('/EmployeeSettings')" style="min-width: 0px;">员工设置</el-menu-item>
            <el-menu-item index="/FunctionList"  @click="saveNavState('/FunctionList')" style="min-width: 0px;">功能列表</el-menu-item>
          </el-submenu>

          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-files"></i>
              <span>开发管理</span>
            </template>
            <el-menu-item index="/DevelopmentManagement"  @click="saveNavState('/DevelopmentManagement')" style="min-width: 0px;">开发管理</el-menu-item>
            <el-menu-item index="/ProductComplaints"  @click="saveNavState('/ProductComplaints')" style="min-width: 0rpx;">产品投诉</el-menu-item>
            <el-menu-item index="1-2" style="min-width: 0rpx;">统计汇总</el-menu-item>
          </el-submenu>

          <el-menu-item
            index="/LterationRecord"
            @click="saveNavState('/LterationRecord')"
          >
            <i class="el-icon-files"></i>
            <span slot="title">迭代记录</span>
          </el-menu-item>

          <el-menu-item
            index="/InstitutionSetting"
            @click="saveNavState('/InstitutionSetting')"
          >
            <i class="el-icon-cpu"></i>
            <span slot="title">机构设置</span>
          </el-menu-item>

          <el-menu-item
            index="/DataImporting"
            @click="saveNavState('/DataImporting')"
          >
            <i class="el-icon-connection"></i>
            <span slot="title">数据导入</span>
          </el-menu-item>

          <el-menu-item
            index="/DemandRecBrandDesignrds"
            @click="saveNavState('/DemandRecBrandDesignrds')"
          >
            <i class="el-icon-set-up"></i>
            <span slot="title">品牌设计</span>
          </el-menu-item>

          <el-menu-item
            index="/DemandRecords"
            @click="saveNavState('/DemandRecords')"
          >
            <i class="el-icon-collection"></i>
            <span slot="title">需求记录</span>
          </el-menu-item>
          <el-menu-item
            index="/OperationalRecords"
            @click="saveNavState('/OperationalRecords')"
          >
            <i class="el-icon-receiving"></i>
            <span slot="title">运维记录</span>
          </el-menu-item>
          <el-menu-item
            index="/MonthlyEvaluation"
            @click="saveNavState('/MonthlyEvaluation')"
          >
            <i class="el-icon-receiving"></i>
            <span slot="title">月度评价</span>
          </el-menu-item>
          <el-menu-item
            index="/DutyRoster"
            @click="saveNavState('/DutyRoster')"
          >
            <i class="el-icon-date"></i>
            <span slot="title">值班表</span>
          </el-menu-item> -->
        </el-menu>
      </div>
    </el-aside>

    <el-container>
      <!-- 顶部栏 -->
      <el-header style="height: 50px">
        <el-row style="display: flex; align-items: center; height: 50px">
          <el-col :span="5">
            <div
              style="
                width: 40px;
                height: 40px;
                line-height: 40px;
                border-radius: 3px;
              "
              class="menue_elasticity"
              @click="menuElasticity = !menuElasticity"
              
            >
              |||
            </div>
          </el-col>
          <el-col :span="19" style="display:flex;align-items: center;justify-content: flex-end;">
            <!-- 欧阳专业 -->
            <el-button type="warning"  size="small" class="quit" plain @click="goyanqi" v-if="$store.state.user.user.type == 'other'" style="margin-right:25px"> 机构延期</el-button >
            <!-- <el-button type="warning"  size="small" class="quit" plain @click="goyanqi"  style="margin-right:25px"> 机构延期</el-button > -->

            <!-- $router.push('/routerTest')/ -->
            欢迎 <label style="color:#fd7eaa;margin: 0  10px 0 10px;"  > {{$store.state.user.user.name}}  </label>
            <el-button type="info" size="small" class="quit" @click="quitClick">
              退出</el-button
            >
          </el-col>
        </el-row>
      </el-header>
      <!-- 主体 -->
      <el-main>
        <div style="overflow: auto; height: 90vh">
          <router-view></router-view>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>


<script>
import { mapGetters } from "vuex";
import {financeuserList} from "@/api/api"

export default {
  name: "Home",
  data() {
    return {
      menuList: [], //菜单栏数组
      // 当前被激活的地址
      activepath: "",
      menuElasticity: true,
    };
  },
  computed: {
    ...mapGetters([
      "functionList"
    ]),
  },

  created() {
    console.log( this.$store);
    // 初始化菜单数据  获取到本地存储的数据
    this.menuList = JSON.parse(sessionStorage.getItem("menuList"));
    // 刷新保存当前打开的页面
    this.activepath = sessionStorage.getItem("activepath")
      ? sessionStorage.getItem("activepath")
      : "/Welcomes";
      // console.log('functions', this.functionList)
  },

  methods: {
    // 退出按钮监听事件
    quitClick() {
      // 清除本地缓存
      sessionStorage.clear();
      // 路由跳转
      this.$router.push("/Login");
      this.$message.error("您已退出系统！");
    },
    // 去到机构延期页面
    goyanqi(){
      this.$router.push("/FinancialAudit");
    },
    saveNavState(activepath) {
      // console.log(activepath);
      sessionStorage.setItem("activepath", activepath);
      this.activepath = activepath;
    },
    // menuElasticityClick(){
    //   console.log(this.$equipment());
    //   if(this.$equipment()){
    //     this
    //   }
    // }
    gsdfga(){
      console.log(132);
      financeuserList().then(res=>{
        console.log(res);
      })
    }
  },
};
</script>

<style scoped>
/* 左侧样式设置 */
.el-aside {
  background-color: #5e6cf4;
  height: 100vh;
}
/* 头部样式设置 */
.el-header {
  background-color: #fff;
}
/* 主体样式设置 */
.el-main {
  /* background-color: darkcyan; */
  background-color: rgb(249, 249, 249);
}

/* 用户头像设置 */
.heard_img {
  width: 100%;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heard_img img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

/* 菜单栏样式 */
.el-submenu .el-menu-item {
  height: 50px;
  line-height: 50px;
  padding: 0 45px;
  min-width: 0px;
}
.el-submenu .el-menu-item {
  height: 50px px;
  line-height: 50px;
  padding: 0 45px;
  /* min-width: 200px; */
}

/* .el-menu-item {
  height: 40px;
  line-height: 40px;
  color: #fff;
  font-size: 13px;
} */
/* .first-menu {
  background-color: #5e6cf4;
} */
/* 菜单栏图标样式 */
/* .el-menu-item i, */
.el-menu i {
  font-size: 17px;
  color: #fff;
}
/* 退出按钮设置 */
.quit {
  float: right;
  margin-top: 8px;
}
/* 当前时间样式 */
.nowTime {
  width: 250px;
  height: 30px;
  line-height: 30px;
  float: right;
  margin-top: 8px;
  font-size: 15px;
  font-family: 宋体;
}

/* 小图标样式设置 */
.icon {
  width: 20px;
  margin-right: 10px;
}
/* 菜单伸缩样式 */
.menue_elasticity {
  width: 100%;
  text-align: center;
  height: 20px;
  line-height: 20px;
  font-size: 18px;
  color: #fff;
  background-color: #adb5ff;
  /* z-index: 99; */
}
/deep/.el-submenu.is-opened>.el-submenu__title .el-submenu__icon-arrow {
    transform: rotateZ(180deg);
    color: #fff;
}
/deep/.el-submenu__title i {
    color: #fff;
}
</style>