<template>
  <el-card>
    <!-- 线索/KPI -->
    <div class="record_top">
      <el-button
        type=""
        icon="el-icon-arrow-left"
        circle
        @click="backHistory"
      ></el-button>
      <div class="record_top-centent">
        年度KPI:
        <div class="record_top-centent-text">新增线索:<label>{{ KPIData.newAddYear }}</label></div>
        <div class="record_top-centent-text">签约校区:<label>{{ KPIData.dealYear }}</label></div>
      </div>
      <div class="record_top-left">
        <!-- <el-card class="record_top-left-card">

        </el-card> -->
        <div class="record_top-left-but">
          <!-- <el-button  plain @click="lookKPIDailog = true">查看目标</el-button> -->
          <el-button type="primary" plain @click="selectKPIData">参数设置</el-button>
          <el-button  type="success" plain @click="inheritDailog = true">继承</el-button>
        </div>
      </div>
    </div>
    <el-divider></el-divider>
    <div class="KPI-MAX-Big">
      <div class="KPI-MAX-Big-centent">
        第一季度KPI:
        <div class="KPI-MAX-Big-centent-text">新增线索:<label>{{ KPIData.newAddOne }}</label></div>
        <div class="KPI-MAX-Big-centent-text">签约校区:<label>{{ KPIData.dealOne }}</label></div>
      </div>
      <div class="KPI-max"> 
        <el-card class="KPI-max-item">
          <div class="KPI-max-item-centent">
            <div class="KPI-max-item-centent-l">
              (一月)
              <div class="KPI-max-item-centent-text">新增:<label>{{ KPIData.month && KPIData.month[Year + '-01'].newAdd }}</label></div>
              <div class="KPI-max-item-centent-text">签约:<label>{{ KPIData.month && KPIData.month[Year + '-01'].deal }}</label></div>
            </div>
            <el-button type="success" icon="el-icon-plus" circle size="mini" @click="openStaffDialo('01')"></el-button>
          </div>
          <el-table
            :data="KPIData.month && KPIData.month[Year + '-01'].list"
            style="width: 100%"
            :row-style="{height:'10px'}"
            :cell-style="{padding: '8px',textAlign: 'center'}"
            :header-cell-style="{ textAlign: 'center', }"
            >
            <el-table-column
              prop="name"
              label="姓名" >
            </el-table-column>
            <el-table-column
              prop="newAdd"
              label="新增线索">
            </el-table-column>
            <el-table-column
              prop="deal"
              label="签约线索">
            </el-table-column>
            <el-table-column width="105" fixed="right">
              <template slot-scope="scope">
                <el-button type="primary" style="margin-right:8px" icon="el-icon-edit" circle size="mini" @click="editClick(scope.row)"></el-button>
                <el-popconfirm
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    icon-color="red"
                    title="确定删除吗？"
                    @confirm="employeeDel(scope.row.kpiId)">
                <el-button slot="reference" type="danger" icon="el-icon-delete" circle size="mini"></el-button>
              </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
        <el-card class="KPI-max-item">
          <div class="KPI-max-item-centent">
            <div class="KPI-max-item-centent-l">
              (二月)
              <div class="KPI-max-item-centent-text">新增:<label>{{ KPIData.month && KPIData.month[Year + '-02'].newAdd }}</label></div>
              <div class="KPI-max-item-centent-text">签约:<label>{{ KPIData.month && KPIData.month[Year + '-02'].deal }}</label></div>
            </div>
            <el-button type="success" icon="el-icon-plus" circle size="mini" @click="openStaffDialo('02')"></el-button>
          </div>
          <el-table
            :data="KPIData.month && KPIData.month[Year + '-02'].list"
            style="width: 100%"
            :row-style="{height:'10px'}"
            :cell-style="{padding: '8px',textAlign: 'center'}"
            :header-cell-style="{ textAlign: 'center', }"
            >
            <el-table-column
              prop="name"
              label="姓名" >
            </el-table-column>
            <el-table-column
              prop="newAdd"
              label="新增线索">
            </el-table-column>
            <el-table-column
              prop="deal"
              label="签约线索">
            </el-table-column>
            <el-table-column width="105" fixed="right">
              <template slot-scope="scope">
                <el-button type="primary" style="margin-right:8px" icon="el-icon-edit" circle size="mini" @click="editClick(scope.row)"></el-button>
                <el-popconfirm
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    icon-color="red"
                    title="确定删除吗？"
                    @confirm="employeeDel(scope.row.kpiId)">
                <el-button slot="reference" type="danger" icon="el-icon-delete" circle size="mini"></el-button>
              </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
        <el-card class="KPI-max-item">
          <div class="KPI-max-item-centent">
            <div class="KPI-max-item-centent-l">
              (三月)
              <div class="KPI-max-item-centent-text">新增:<label>{{ KPIData.month && KPIData.month[Year + '-03'].newAdd }}</label></div>
              <div class="KPI-max-item-centent-text">签约:<label>{{ KPIData.month && KPIData.month[Year + '-03'].deal }}</label></div>
            </div>
            <el-button type="success" icon="el-icon-plus" circle size="mini" @click="openStaffDialo('03')"></el-button>
          </div>
          <el-table
            :data="KPIData.month && KPIData.month[Year + '-03'].list"
            style="width: 100%"
            :row-style="{height:'10px'}"
            :cell-style="{padding: '8px',textAlign: 'center'}"
            :header-cell-style="{ textAlign: 'center', }"
            >
            <el-table-column
              prop="name"
              label="姓名" >
            </el-table-column>
            <el-table-column
              prop="newAdd"
              label="新增线索">
            </el-table-column>
            <el-table-column
              prop="deal"
              label="签约线索">
            </el-table-column>
            <el-table-column width="105" fixed="right">
              <template slot-scope="scope">
                <el-button type="primary" style="margin-right:8px" icon="el-icon-edit" circle size="mini" @click="editClick(scope.row)"></el-button>
                <el-popconfirm
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    icon-color="red"
                    title="确定删除吗？"
                    @confirm="employeeDel(scope.row.kpiId)">
                <el-button slot="reference" type="danger" icon="el-icon-delete" circle size="mini"></el-button>
              </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>
    </div>

    <el-divider></el-divider>
    <div class="KPI-MAX-Big">
      <div class="KPI-MAX-Big-centent">
        第二季度KPI:
        <div class="KPI-MAX-Big-centent-text">新增线索:<label>{{ KPIData.newAddTwo }}</label></div>
        <div class="KPI-MAX-Big-centent-text">签约校区:<label>{{ KPIData.dealTwo }}</label></div>
      </div>
      <div class="KPI-max"> 
        <el-card class="KPI-max-item">
          <div class="KPI-max-item-centent">
            <div class="KPI-max-item-centent-l">
              (四月)
              <div class="KPI-max-item-centent-text">新增:<label>{{ KPIData.month && KPIData.month[Year + '-04'].newAdd }}</label></div>
              <div class="KPI-max-item-centent-text">签约:<label>{{ KPIData.month && KPIData.month[Year + '-01'].deal }}</label></div>
            </div>
            <el-button type="success" icon="el-icon-plus" circle size="mini" @click="openStaffDialo('04')"></el-button>
          </div>
          <el-table
            :data="KPIData.month && KPIData.month[Year + '-04'].list"
            style="width: 100%"
            :row-style="{height:'10px'}"
            :cell-style="{padding: '8px',textAlign: 'center'}"
            :header-cell-style="{ textAlign: 'center', }"
            >
            <el-table-column
              prop="name"
              label="姓名" >
            </el-table-column>
            <el-table-column
              prop="newAdd"
              label="新增线索">
            </el-table-column>
            <el-table-column
              prop="deal"
              label="签约线索">
            </el-table-column>
            <el-table-column width="105" fixed="right">
              <template slot-scope="scope">
                <el-button type="primary" style="margin-right:8px" icon="el-icon-edit" circle size="mini" @click="editClick(scope.row)"></el-button>
                <el-popconfirm
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    icon-color="red"
                    title="确定删除吗？"
                    @confirm="employeeDel(scope.row.kpiId)">
                <el-button slot="reference" type="danger" icon="el-icon-delete" circle size="mini"></el-button>
              </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
        <el-card class="KPI-max-item">
          <div class="KPI-max-item-centent">
            <div class="KPI-max-item-centent-l">
              (五月)
              <div class="KPI-max-item-centent-text">新增:<label>{{ KPIData.month && KPIData.month[Year + '-05'].newAdd }}</label></div>
              <div class="KPI-max-item-centent-text">签约:<label>{{ KPIData.month && KPIData.month[Year + '-05'].deal }}</label></div>
            </div>
            <el-button type="success" icon="el-icon-plus" circle size="mini" @click="openStaffDialo('05')"></el-button>
          </div>
          <el-table
            :data="KPIData.month && KPIData.month[Year + '-05'].list"
            style="width: 100%"
            :row-style="{height:'10px'}"
            :cell-style="{padding: '8px',textAlign: 'center'}"
            :header-cell-style="{ textAlign: 'center', }"
            >
            <el-table-column
              prop="name"
              label="姓名" >
            </el-table-column>
            <el-table-column
              prop="newAdd"
              label="新增线索">
            </el-table-column>
            <el-table-column
              prop="deal"
              label="签约线索">
            </el-table-column>
            <el-table-column width="105" fixed="right">
              <template slot-scope="scope">
                <el-button type="primary" style="margin-right:8px" icon="el-icon-edit" circle size="mini" @click="editClick(scope.row)"></el-button>
                <el-popconfirm
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    icon-color="red"
                    title="确定删除吗？"
                    @confirm="employeeDel(scope.row.kpiId)">
                <el-button slot="reference" type="danger" icon="el-icon-delete" circle size="mini"></el-button>
              </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
        <el-card class="KPI-max-item">
          <div class="KPI-max-item-centent">
            <div class="KPI-max-item-centent-l">
              (六月)
              <div class="KPI-max-item-centent-text">新增:<label>{{ KPIData.month && KPIData.month[Year + '-06'].newAdd }}</label></div>
              <div class="KPI-max-item-centent-text">签约:<label>{{ KPIData.month && KPIData.month[Year + '-06'].deal }}</label></div>
            </div>
            <el-button type="success" icon="el-icon-plus" circle size="mini" @click="openStaffDialo('06')"></el-button>
          </div>
          <el-table
            :data="KPIData.month && KPIData.month[Year + '-06'].list"
            style="width: 100%"
            :row-style="{height:'10px'}"
            :cell-style="{padding: '8px',textAlign: 'center'}"
            :header-cell-style="{ textAlign: 'center', }"
            >
            <el-table-column
              prop="name"
              label="姓名" >
            </el-table-column>
            <el-table-column
              prop="newAdd"
              label="新增线索">
            </el-table-column>
            <el-table-column
              prop="deal"
              label="签约线索">
            </el-table-column>
            <el-table-column width="105" fixed="right">
              <template slot-scope="scope">
                <el-button type="primary" style="margin-right:8px" icon="el-icon-edit" circle size="mini" @click="editClick(scope.row)"></el-button>
                <el-popconfirm
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    icon-color="red"
                    title="确定删除吗？"
                    @confirm="employeeDel(scope.row.kpiId)">
                <el-button slot="reference" type="danger" icon="el-icon-delete" circle size="mini"></el-button>
              </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>
    </div>

    <el-divider></el-divider>
    <div class="KPI-MAX-Big">
      <div class="KPI-MAX-Big-centent">
        第三季度KPI:
        <div class="KPI-MAX-Big-centent-text">新增线索:<label>{{ KPIData.newAddThree }}</label></div>
        <div class="KPI-MAX-Big-centent-text">签约校区:<label>{{ KPIData.dealThree }}</label></div>
      </div>
      <div class="KPI-max"> 
        <el-card class="KPI-max-item">
          <div class="KPI-max-item-centent">
            <div class="KPI-max-item-centent-l">
              (七月)
              <div class="KPI-max-item-centent-text">新增:<label>{{ KPIData.month && KPIData.month[Year + '-07'].newAdd }}</label></div>
              <div class="KPI-max-item-centent-text">签约:<label>{{ KPIData.month && KPIData.month[Year + '-07'].deal }}</label></div>
            </div>
            <el-button type="success" icon="el-icon-plus" circle size="mini" @click="openStaffDialo('07')"></el-button>
          </div>
          <el-table
            :data="KPIData.month && KPIData.month[Year + '-07'].list"
            style="width: 100%"
            :row-style="{height:'10px'}"
            :cell-style="{padding: '8px',textAlign: 'center'}"
            :header-cell-style="{ textAlign: 'center', }"
            >
            <el-table-column
              prop="name"
              label="姓名" >
            </el-table-column>
            <el-table-column
              prop="newAdd"
              label="新增线索">
            </el-table-column>
            <el-table-column
              prop="deal"
              label="签约线索">
            </el-table-column>
            <el-table-column width="105" fixed="right">
              <template slot-scope="scope">
                <el-button type="primary" style="margin-right:8px" icon="el-icon-edit" circle size="mini" @click="editClick(scope.row)"></el-button>
                <el-popconfirm
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    icon-color="red"
                    title="确定删除吗？"
                    @confirm="employeeDel(scope.row.kpiId)">
                <el-button slot="reference" type="danger" icon="el-icon-delete" circle size="mini"></el-button>
              </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
        <el-card class="KPI-max-item">
          <div class="KPI-max-item-centent">
            <div class="KPI-max-item-centent-l">
              (八月)
              <div class="KPI-max-item-centent-text">新增:<label>{{ KPIData.month && KPIData.month[Year + '-08'].newAdd }}</label></div>
              <div class="KPI-max-item-centent-text">签约:<label>{{ KPIData.month && KPIData.month[Year + '-08'].deal }}</label></div>
            </div>
            <el-button type="success" icon="el-icon-plus" circle size="mini" @click="openStaffDialo('08')"></el-button>
          </div>
          <el-table
            :data="KPIData.month && KPIData.month[Year + '-08'].list"
            style="width: 100%"
            :row-style="{height:'10px'}"
            :cell-style="{padding: '8px',textAlign: 'center'}"
            :header-cell-style="{ textAlign: 'center', }"
            >
            <el-table-column
              prop="name"
              label="姓名" >
            </el-table-column>
            <el-table-column
              prop="newAdd"
              label="新增线索">
            </el-table-column>
            <el-table-column
              prop="deal"
              label="签约线索">
            </el-table-column>
            <el-table-column width="105" fixed="right">
              <template slot-scope="scope">
                <el-button type="primary" style="margin-right:8px" icon="el-icon-edit" circle size="mini" @click="editClick(scope.row)"></el-button>
                <el-popconfirm
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    icon-color="red"
                    title="确定删除吗？"
                    @confirm="employeeDel(scope.row.kpiId)">
                <el-button slot="reference" type="danger" icon="el-icon-delete" circle size="mini"></el-button>
              </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
        <el-card class="KPI-max-item">
          <div class="KPI-max-item-centent">
            <div class="KPI-max-item-centent-l">
              (九月)
              <div class="KPI-max-item-centent-text">新增:<label>{{ KPIData.month && KPIData.month[Year + '-09'].newAdd }}</label></div>
              <div class="KPI-max-item-centent-text">签约:<label>{{ KPIData.month && KPIData.month[Year + '-09'].deal }}</label></div>
            </div>
            <el-button type="success" icon="el-icon-plus" circle size="mini" @click="openStaffDialo('09')"></el-button>
          </div>
          <el-table
            :data="KPIData.month && KPIData.month[Year + '-09'].list"
            style="width: 100%"
            :row-style="{height:'10px'}"
            :cell-style="{padding: '8px',textAlign: 'center'}"
            :header-cell-style="{ textAlign: 'center', }"
            >
            <el-table-column
              prop="name"
              label="姓名" >
            </el-table-column>
            <el-table-column
              prop="newAdd"
              label="新增线索">
            </el-table-column>
            <el-table-column
              prop="deal"
              label="签约线索">
            </el-table-column>
            <el-table-column width="105" fixed="right">
              <template slot-scope="scope">
                <el-button type="primary" style="margin-right:8px" icon="el-icon-edit" circle size="mini" @click="editClick(scope.row)"></el-button>
                <el-popconfirm
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    icon-color="red"
                    title="确定删除吗？"
                    @confirm="employeeDel(scope.row.kpiId)">
                <el-button slot="reference" type="danger" icon="el-icon-delete" circle size="mini"></el-button>
              </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>
    </div>

    <el-divider></el-divider>
    <div class="KPI-MAX-Big">
      <div class="KPI-MAX-Big-centent">
        第四季度KPI:
        <div class="KPI-MAX-Big-centent-text">新增线索:<label>{{ KPIData.newAddFour }}</label></div>
        <div class="KPI-MAX-Big-centent-text">签约校区:<label>{{ KPIData.dealFour }}</label></div>
      </div>
      <div class="KPI-max"> 
        <el-card class="KPI-max-item">
          <div class="KPI-max-item-centent">
            <div class="KPI-max-item-centent-l">
              (十月)
              <div class="KPI-max-item-centent-text">新增:<label>{{ KPIData.month && KPIData.month[Year + '-10'].newAdd }}</label></div>
              <div class="KPI-max-item-centent-text">签约:<label>{{ KPIData.month && KPIData.month[Year + '-10'].deal }}</label></div>
            </div>
            <el-button type="success" icon="el-icon-plus" circle size="mini" @click="openStaffDialo('10')"></el-button>
          </div>
          <el-table
            :data="KPIData.month && KPIData.month[Year + '-10'].list"
            style="width: 100%"
            :row-style="{height:'10px'}"
            :cell-style="{padding: '8px',textAlign: 'center'}"
            :header-cell-style="{ textAlign: 'center', }"
            >
            <el-table-column
              prop="name"
              label="姓名" >
            </el-table-column>
            <el-table-column
              prop="newAdd"
              label="新增线索">
            </el-table-column>
            <el-table-column
              prop="deal"
              label="签约线索">
            </el-table-column>
            <el-table-column width="105" fixed="right">
              <template slot-scope="scope">
                <el-button type="primary" style="margin-right:8px" icon="el-icon-edit" circle size="mini" @click="editClick(scope.row)"></el-button>
                <el-popconfirm
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    icon-color="red"
                    title="确定删除吗？"
                    @confirm="employeeDel(scope.row.kpiId)">
                <el-button slot="reference" type="danger" icon="el-icon-delete" circle size="mini"></el-button>
              </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
        <el-card class="KPI-max-item">
          <div class="KPI-max-item-centent">
            <div class="KPI-max-item-centent-l">
              (十一月)
              <div class="KPI-max-item-centent-text">新增:<label>{{ KPIData.month && KPIData.month[Year + '-11'].newAdd }}</label></div>
              <div class="KPI-max-item-centent-text">签约:<label>{{ KPIData.month && KPIData.month[Year + '-11'].deal }}</label></div>
            </div>
            <el-button type="success" icon="el-icon-plus" circle size="mini" @click="openStaffDialo('11')"></el-button>
          </div>
          <el-table
            :data="KPIData.month && KPIData.month[Year + '-11'].list"
            style="width: 100%"
            :row-style="{height:'10px'}"
            :cell-style="{padding: '8px',textAlign: 'center'}"
            :header-cell-style="{ textAlign: 'center', }"
            >
            <el-table-column
              prop="name"
              label="姓名" >
            </el-table-column>
            <el-table-column
              prop="newAdd"
              label="新增线索">
            </el-table-column>
            <el-table-column
              prop="deal"
              label="签约线索">
            </el-table-column>
            <el-table-column width="105" fixed="right">
              <template slot-scope="scope">
                <el-button type="primary" style="margin-right:8px" icon="el-icon-edit" circle size="mini" @click="editClick(scope.row)"></el-button>
                <el-popconfirm
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    icon-color="red"
                    title="确定删除吗？"
                    @confirm="employeeDel(scope.row.kpiId)">
                <el-button slot="reference" type="danger" icon="el-icon-delete" circle size="mini"></el-button>
              </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
        <el-card class="KPI-max-item">
          <div class="KPI-max-item-centent">
            <div class="KPI-max-item-centent-l">
              (十二月)
              <div class="KPI-max-item-centent-text">新增:<label>{{ KPIData.month && KPIData.month[Year + '-12'].newAdd }}</label></div>
              <div class="KPI-max-item-centent-text">签约:<label>{{ KPIData.month && KPIData.month[Year + '-12'].deal }}</label></div>
            </div>
            <el-button type="success" icon="el-icon-plus" circle size="mini" @click="openStaffDialo('12')"></el-button>
          </div>
          <el-table
            :data="KPIData.month && KPIData.month[Year + '-12'].list"
            style="width: 100%"
            :row-style="{height:'10px'}"
            :cell-style="{padding: '8px',textAlign: 'center'}"
            :header-cell-style="{ textAlign: 'center', }"
            >
            <el-table-column
              prop="name"
              label="姓名" >
            </el-table-column>
            <el-table-column
              prop="newAdd"
              label="新增线索">
            </el-table-column>
            <el-table-column
              prop="deal"
              label="签约线索">
            </el-table-column>
            <el-table-column width="105" fixed="right">
              <template slot-scope="scope">
                <el-button type="primary" style="margin-right:8px" icon="el-icon-edit" circle size="mini" @click="editClick(scope.row)"></el-button>
                <el-popconfirm
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    icon-color="red"
                    title="确定删除吗？"
                    @confirm="employeeDel(scope.row.kpiId)">
                <el-button slot="reference" type="danger" icon="el-icon-delete" circle size="mini"></el-button>
              </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </div>
    </div>

    <!-- 设置目标弹窗 -->
    <el-dialog
      title="设置目标"
      :visible.sync="setKPIDailog"
      width="700px">
      <div class="lookKPI">
        <div class="lookKPI-title">每日新增线索：</div>
        <el-input-number style="width:130px" v-model="setKPIFrom.dayAdd" :min="1" label="请输入数量"></el-input-number>
      </div>
      <!-- <div class="lookKPI">
        <div class="lookKPI-title">每日跟进线索：</div>
        <el-input-number style="width:130px" v-model="setKPIFrom.dayFollow" :min="1" label="请输入数量"></el-input-number>
      </div> -->
      <div class="lookKPI">
        <div class="lookKPI-title">跟进间隔时间：</div>
        <el-input-number style="width:130px" v-model="setKPIFrom.followInterval" :min="1" label="请输入数量"></el-input-number>
      </div>
      <div class="lookKPI">
        <div class="lookKPI-title">线索流失：</div>
        <el-input-number style="width:130px;margin-left: 28px;" :min="1" v-model="setKPIFrom.lossDeal"  label="请输入数量"></el-input-number>
        <div class="lookKPI-title-tip">天未成交 <label style="color:red;font-size:18px">或者</label></div>
        <el-input-number style="width:130px" :min="1" v-model="setKPIFrom.lossFollow"  label="请输入数量"></el-input-number>
        <div class="lookKPI-title-tip">天未跟进</div>
      </div>
      <div class="dialog-but">
        <el-button type="primary" style="margin:auto;" @click="setKPIData">保存</el-button>
      </div>
    </el-dialog>

    <!-- 继承弹窗 -->
    <el-drawer
      title="设置目标"
      :visible.sync="inheritDailog"
      width="30%">
      <div class="lookKPI">
        <div class="lookKPI-title">转出人</div>
        <el-select v-model="inheritFrom.staffId" placeholder="请选择">
          <el-option
            v-for="(item, index) in staffSaleList"
            :key="index"
            :label="item.name"
            :value="item.staffId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="lookKPI">
        <div class="lookKPI-title">转入人</div>
        <el-select v-model="inheritFrom.inheritId" placeholder="请选择">
          <el-option
            v-for="(item, index) in staffSaleList"
            :key="index"
            :label="item.name"
            :value="item.staffId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="dialog-but">
        <el-button type="primary" style="margin:auto;" @click="clueInheritClick">继承</el-button>
      </div>
    </el-drawer>

    <!-- 成员添加编辑弹窗 -->
    <el-dialog
      title="员工目标编辑"
      :visible.sync="staffDailog"
      width="30%">
      <div class="lookKPI">
        <div class="lookKPI-title">选择员工</div>
        <el-select v-model="addFrom.staffId" placeholder="请选择员工">
          <el-option
            v-for="(item, index) in staffSaleList"
            :key="index"
            :label="item.name"
            :value="item.staffId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="lookKPI">
        <div class="lookKPI-title">新增线索</div>
        <el-input-number style="width:220px" v-model="addFrom.newAdd" :min="0" label="请输入数量"></el-input-number>
      </div>
      <div class="lookKPI">
        <div class="lookKPI-title">签约成交</div>
        <el-input-number style="width:220px" v-model="addFrom.deal" :min="0" label="请输入数量"></el-input-number>
      </div>

      <div class="dialog-but">
        <el-button type="primary" style="margin:auto;" @click="staffOpear()">{{dayID ? '保存' :'修改'}}</el-button>
      </div>
    </el-dialog>

  </el-card>
</template>

<script>
import {
  clueIntentionSearch,
  channelListSearch,
  signContractStageList,
  staffSaleSearch,
  clueIdentitySearch,
  cluePageList,
  clueAdd,
  clueRecordAdd,
  clueRecordPageList,
  clueDel,
  clueUpd,
  financeAdd,
  clueBatchRecordAdd,
} from "@/api/distributionChannel";
import {apis} from "../api/clue"
export default {
  data() {
    return {
      tableData: [{
        date: '王小虎',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1517 弄'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1519 弄'
      }, {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1516 弄'
      }],
     
      // lookKPIDailog:false,  // 查看目标弹窗
      setKPIDailog:false, //设置目标弹窗
      inheritDailog:false,  //继承弹窗
      staffDailog:false,  //成员添加编辑

      // from
      setKPIFrom:{//设置参数
        dayAdd:"",
        dayFollow:"",
        followInterval:"",
        lossDeal:"",
        lossFollow:"",
      },
      inheritFrom:{//继承
        staffId:'',
        inheritId:""
      },
      addFrom:{
        cycle:'',
        staffId:'',
        newAdd:0,
        deal:0,
      },
      staffSaleList: [], //负责人

      KPIData:{}, //所有数据
      Year:'',
      dayID:'',

    };
  },
  created(){
    this.Year= new Date().getFullYear()
    this.getData()
    this.getKPIData()
  },
  methods: {
    getData(){
      staffSaleSearch({}).then(res=>{
        this.staffSaleList = res.data;;
      })
    },
    getKPIData(){
      apis.clueKPIList({}).then(res=>{
        console.log(res);
        this.KPIData = res.data
        // console.log(this.Year+'-01');
        // console.log(this.KPIData.month[this.Year+'-01'].list);
      })
    },
    // 打开编辑弹窗
    editClick(item){
      this.kpiID = item.kpiId
      this.addFrom.staffId = item.staffId
      this.addFrom.deal = item.deal
      this.addFrom.newAdd = item.newAdd

      this.staffDailog = true
    },
    // 打开参数设置弹窗
    async selectKPIData(){
      const loading = this.$loading({
        lock: true,
        text: "加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await apis.clueParamDetails({})
      loading.close()
      this.setKPIFrom = res.data
      this.setKPIDailog = true
      console.log(res);
    },
    // 设置参数
    async setKPIData(){
      const loading = this.$loading({
        lock: true,
        text: " 领取中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await apis.clueParamSet(this.setKPIFrom)
      if (res.status == "success") {
        loading.close();
        this.$message.success("操作成功");
        this.setKPIDailog = false
        // this.getPooldData()
      } else {
        loading.close();
        this.$message.error("操作失败");
      }
      console.log(res);
    },
    // 确定删除员工
    async employeeDel(id){
      const loading = this.$loading({
        lock: true,
        text: " 删除中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await apis.clueKPIDel({kpiId:id})
      if (res.status == "success") {
        loading.close();
        this.$message.success("操作成功");
        // this.inheritDailog = false
        this.getKPIData()
      } else {
        loading.close();
        this.$message.error(res.msg);
      }
    },
    // 打开员工弹窗
    openStaffDialo(index){
      console.log(index);
      this.addFrom.newAdd = 0
      this.addFrom.deal = 0
      this.addFrom.staffId = ''
      this.staffDailog = true
      this.dayID = '-'+index
    },

    // 员工保存操作
    async staffOpear(){
      if(this.addFrom.staffId == ''){
        this.$message.error("请选择员工");
        return
      }
      const loading = this.$loading({
        lock: true,
        text: " 操作中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res;
      console.log(this.dayID);
      if(this.dayID){
        // 添加
        this.addFrom.cycle = this.Year + this.dayID
        res = await apis.clueKPIAdd(this.addFrom)
        // console.log(res);
      }else{
        console.log('编辑');
        // 编辑
        this.addFrom.kpiId = this.kpiID
        res = await apis.clueKPIUpd(this.addFrom)
      }
      if (res.status == "success") {
        loading.close();
        this.$message.success("操作成功");
        // this.inheritDailog = false
        this.getKPIData()
      } else {
        loading.close();
        this.$message.error(res.msg);
      }
      this.dayID = ''

    },
    // 确定继承
    async clueInheritClick(){
      if(this.inheritFrom.staffId =='' || this.inheritFrom.inheritId == ''){
        this.$message.error("请选转入人或者转出人")
        return
      }
      const loading = this.$loading({
        lock: true,
        text: " 领取中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await apis.clueInherit({
        staffId:this.inheritFrom.staffId,
        inheritId:this.inheritFrom.inheritId
      })
      if (res.status == "success") {
        loading.close();
        this.$message.success("操作成功");
        this.inheritDailog = false
        // this.getPooldData()
      } else {
        loading.close();
        this.$message.error("操作失败");
      }
      this.inheritFrom.staffId ='' 
      this.inheritFrom.inheritId = ''
      console.log(res);
    },
    // 返回
    backHistory() {
      this.$router.go(-1); //返回上一层
    },
  },
};
</script>

<style scoped>
.record_top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 15px;
}
.record_top-centent{
  display: flex;
  align-items: center;
}
.record_top-centent .record_top-centent-text{
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.record_top-centent .record_top-centent-text label{
  font-size: 30px;
}
.record_top-left{
  display: flex;
}
.record_top-left-card{
  width: 200px;
  height: 220px;
  margin-right: 20px;
}
.record_top-left-but{
  /* width: 100px;
  height: 100px; */
  /* display: flex; */
  justify-content: space-between;
  flex-direction: column;
}

/* 项max */
.KPI-MAX-Big{
  width: 100%;
}
.KPI-MAX-Big-centent{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.KPI-MAX-Big-month{
  width: 100%;
  display: flex;
}
.KPI-MAX-Big-centent-text{
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.KPI-MAX-Big-centent-text label{
  font-size: 20px;
}
.KPI-max{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.KPI-max-item{
  width: 32.5%;
}
.KPI-max-item-centent{
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  align-items: center;
}
.KPI-max-item-centent-l{
  flex: 1;
  display: flex;
  font-size: 14px;
  align-items: center;
}
.KPI-max-item-centent
.KPI-max-item-centent-text{
  margin-left: 10px;
}
.KPI-max-item-centent-text label{
  font-weight: 600;
}

/* KPI查看 */
.lookKPI{
  width: 100%;
  display: flex;
  height: 50px;
  align-items: center;
}
.lookKPI .lookKPI-title{
  max-width: 150px;
  margin: 0 15px;
}
.lookKPI label{
  font-size: 30px;
}
.lookKPI  .lookKPI-title-tip{
  margin: 0 8px;
}
.dialog-but{
  width: 100%;
  margin-top: 30px;
  text-align: center;
}
</style>