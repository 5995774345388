// 首页这个模块：API进行统一的管理
import requests from "./request";

// 
/**
 * 首页
 */

const apis = {
    // 需求标记接口
    homePageFinanceStr(data, type = "POST") {
        return requests({
            url: `oam/homePage/financeStr`,
            method: type,
            data
        })
    },

    homePageNumCount(data, type = "POST") {
        return requests({
            url: 'oam/homePage/numCount',
            method: type,
            data
        })
    },
    // 校区注册
    homePageMechanismList(data, type = "POST") {
        return requests({
            url: 'oam/homePage/mechanismList',
            method: type,
            data
        })
    },
    // 值班
    homePageDutyListt(data, type = "POST") {
        return requests({
            url: 'oam/homePage/dutyList',
            method: type,
            data
        })
    },
    // 最新渠道
    homePagechannelList(data, type = "POST") {
        return requests({
            url: 'oam/homePage/channelList',
            method: type,
            data
        })
    },
    // 最新线索
    homePageclueList(data, type = "POST") {
        return requests({
            url: 'oam/homePage/clueList',
            method: type,
            data
        })
    },

    // 开发记录
    homePageiterationList(data, type = "POST") {
        return requests({
            url: 'oam/homePage/iterationList',
            method: type,
            data
        })
    },

    // 运维记录
    homePagerecordList(data, type = "POST") {
        return requests({
            url: 'oam/homePage/recordList',
            method: type,
            data
        })
    },

    // 售后服务
    homePageafterServiceList(data, type = "POST") {
        return requests({
            url: 'oam/homePage/afterServiceList',
            method: type,
            data
        })
    },

}
export { apis }
// export const homePageFinanceStr = (query1) => requests({
//     url: 'ass/oam/homePage/financeStr',
//     method: "POST",
//     data: query1,
//     headers: {
//         'Content-Type': 'application/json',
//         'token': window.sessionStorage.getItem("token")
//         // 'token': "asddd"
//     }
// });