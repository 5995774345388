<template>
  <el-card>
    <!-- 记录页面 -->
    <div class="record_top">
      <el-button
        type=""
        icon="el-icon-arrow-left"
        circle
        @click="backHistory"
      ></el-button>
      <el-button type="success" @click="workEnd">办结</el-button>
    </div>
    <div class="content-max">
      <div class="content-swiper">
        <template v-if="RecordList.length">
          <div
            class="content-item"
            v-for="(item, index) in RecordList"
            :key="index"
          >
            <template v-if="item.color == 'FFFFFF'">
              <div class="name">
                <h5>{{ item.name }}</h5>
                <p>{{ item.creationTime }}</p>
              </div>
              <el-card
                class="el-card-content"
                :style="{ background: '#' + item.color }"
              >
                {{ item.content }}
                <p v-if="item.fileUrl">
                  附件:{{ item.fileUrl }}
                  <el-button
                    class="el-card-content-text"
                    @click="coopl(item.fileUrl)"
                    >复制</el-button
                  >
                </p>
              </el-card>
            </template>
            <template v-else>
              <div class="mystyle">
                <el-card
                  class="el-card-content"
                  :style="{ background: '#' + item.color }"
                >
                  {{ item.content }}
                  <p v-if="item.fileUrl">
                    附件:{{ item.fileUrl }}
                    <el-button
                      class="el-card-content-text"
                      @click="coopl(item.fileUrl)"
                      >复制</el-button
                    >
                  </p>
                </el-card>
                <div class="name">
                  <h5>{{ item.name }}</h5>
                  <p>{{ item.creationTime }}</p>
                </div>
              </div>
            </template>
          </div>
        </template>
        <h3 v-else>暂无记录</h3>
      </div>
    </div>
    <div class="redcode-bottom">
      <div class="Replycontent">
        <div class="el-inputreplay">
          <el-input type="textarea" placeholder="请输入内容" v-model="textarea">
          </el-input>
        </div>
        <div class="From_items">
          <div>选择附件</div>
          <el-upload
            class="upload-demo"
            action=""
            :on-change="handleChange"
            :file-list="fileList"
            style="width: 60%"
            :auto-upload="false"
            :limit="1"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip" style="width: 100%">
              支持文件：.ara .zip .doc .docx .pdf .jpg
            </div>
          </el-upload>
        </div>
      </div>
      <div class="Replycontent_bottom">
        <el-button type="primary" @click="Submit()">提交</el-button>
      </div>
    </div>
  </el-card>
</template>

<script>
import { apis } from "./api/index";

import { putObject } from "@/util/cos-helper";
export default {

  data() {
    return {
      fileList: [],
      textarea: "",
      id: "",
      RecordList: [],
    };
  },
  created() {
    console.log(this.$route.query.id);
    this.getData();
    this.id = this.$route.query.id;
    console.log(this.status);
    window.localStorage.setItem("status",this.$route.query.status)
  },
  methods: {
    // 获取记录
    async getData() {
      let data = { workId: this.$route.query.id };
      let res = await apis.workRecordList(data);
      //   arr.push(...res.data);
      this.RecordList = res.data;
      console.log(res);
    },
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },
    // 提交
    async Submit() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (this.textarea === "" || this.textarea === null) {
        this.$message.error("请输入内容");
      loading.close();

        return;
      }
      let fileUrl = "";
      if (this.fileList && this.fileList.length) {
        let res = await putObject(this.fileList[0]);
        console.log(res);
        fileUrl = res.data.url;
      }
      let postdata = {
        workId: this.id,
        content: this.textarea,
        fileUrl: fileUrl,
      };
      console.log(postdata);
      //   return

      let res = await apis.workRecordAdd(postdata);
      loading.close();
      console.log(res);
      if (res.status === "success") {
        this.textarea = "";
        this.fileList = [];
        this.getData();
        this.$notify({
          title: "提示",
          message: "提交成功",
          type: "success",
        });
      } else {
        this.$notify({
          title: "提示",
          message: "提交失败",
          type: "error",
        });
      }
    },
    // 返回
    backHistory() {
      this.$router.go(-1); //返回上一层
    },

    // 办结
    async workEnd() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await apis.workEnd({ workId: this.id });
      loading.close();
      console.log(res);
      if (res.status === "success") {
        this.$notify({
          title: "提示",
          message: "办结成功",
          type: "success",
        });
      } else {
        this.$notify({
          title: "提示",
          message: res.msg,
          type: "error",
        });
      }
    },

    // 复制
    coopl(url) {
      console.log(url);
      //创建input标签
      var input = document.createElement("input");
      //将input的值设置为需要复制的内容
      input.value = url;
      //添加input标签
      document.body.appendChild(input);
      //选中input标签
      input.select();
      //执行复制
      document.execCommand("copy");
      //成功提示信息
      this.$notify({
        title: "提示",
        message: "复制成功",
        type: "success",
      });
      //移除input标签
      document.body.removeChild(input);
    },
  },
  beforeDestroy(){
    setTimeout(()=>{
      window.localStorage.setItem('workOrder','0')
      console.log('销毁');
    },1300)
  }
};
</script>

<style scoped>
.record_top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.content-max {
  width: 95%;
  margin: auto;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 8px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  max-height: 60vh;
}
.content-swiper {
  width: 100%;
  overflow-y: auto;
}

.content-item {
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  margin-bottom: 10px;
}
.name {
  width: 13%;
}
.name p {
  font-size: 12px;
  color: #999;
}
.el-card-content {
  max-width: 88%;
  height: auto;
  border-radius: 15px;
}
.el-card-content p {
  font-size: 12px;
  color: #999;
  margin-top: 8px;
}
.el-card-content-text {
  border: 1px solid rgb(117, 115, 115);
  padding: 2px;
}

.From_item {
  width: 90%;
  height: 70rpx;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.From_item div {
  width: 30%;
}

.redcode-bottom {
  width: 100%;
  margin-top: 25px;
  padding-top: 25px;
  border-top: 2px solid #ccc;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
.Replycontent {
  width: 100%;
  display: flex;
}
.el-inputreplay {
  width: 70%;
}
.From_items {
  width: 30%;
  display: flex;
}
.From_items div {
  margin: 0 20px;
}
.Replycontent_bottom {
  margin-top: 10px;
}
.mystyle {
  width: 100%;
  /* background-color: bisque; */
  display: flex;
  justify-content: flex-end;
  text-align: right;
  padding: 0 20px;
}
</style>