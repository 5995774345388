<template>
  <!-- 品牌设计 -->
  <el-card>
    <div v-loading="loadingTab">
      <div style="width: 95%; margin: 0 auto">
        <el-row style="margin-bottom: 15px; display: flex; align-items: center">
          <span>待设计</span>
          <el-button style="margin: 0 10px 0 auto" @click="dialogShow(1)"
            >一键导入</el-button
          >
          <el-button
            @click="formShowClick"
            type="primary"
            style="background-color: #5e6cf4"
            >添加</el-button
          >
        </el-row>
        <!-- 表格 -->
        <el-table
          :data="tableData0"
          style="width: 100%"
          border
          :row-style="{ height: '30px' }"
          :cell-style="{ padding: '8px', textAlign: 'center' }"
          :header-cell-style="{
            textAlign: 'center',
            backgroundColor: '#f5f7fa',
          }"
        >
          <el-table-column prop="name" label="品牌"></el-table-column>
          <el-table-column prop="top" label="优先级"></el-table-column>
          <el-table-column label="资料地址">
            <template v-slot="scope" width="150">
              <!-- <a :href="scope.row.design_url">下载</a> -->
              <el-button
                slot="reference"
                type="info"
                plain
                icon="el-icon-download"
                size="small"
                @click="upDataBrandDesign(scope.row.design_url)"
                >下载资料</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="type"
            label="资料类型"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="creationTime"
            label="接收日期"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column label="操作" width="150">
            <template v-slot="scope">
              <div class="ActionColumn"> 
                <el-popconfirm
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    icon-color="red"
                    title="确定将该需求状态更新为已设计？"
                    @confirm="solvedConfirm(scope.row)">
                    <el-link
                      slot="reference"
                      type="primary"
                      class="ActionColumn_h"
                      >已设计</el-link>
                  </el-popconfirm>
                  <el-popconfirm
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    icon-color="red"
                    title="确定删除该设计文件吗？"
                    @confirm="delConfirm(scope.row)">
                    <el-link
                      slot="reference"
                      type="primary"
                      class="ActionColumn_h"
                      >删除</el-link>
                  </el-popconfirm>
              </div>
              <!-- <el-row style="display: flex; justify-content: space-between">
                <el-col :span="10">
                  <el-popconfirm
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    icon-color="red"
                    title="确定将该需求状态更新为已设计？"
                    @confirm="solvedConfirm(scope.row)"
                  >
                    <el-button
                      slot="reference"
                      type="success"
                      plain
                      icon="el-icon-edit"
                      size="small"
                      >已设计</el-button
                    >
                  </el-popconfirm>
                </el-col>
                <el-col :span="10">
                  <el-popconfirm
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    icon-color="red"
                    title="确定删除该设计文件吗？"
                    @confirm="delConfirm(scope.row, 0)"
                  >
                    <el-button
                      slot="reference"
                      type="danger"
                      plain
                      icon="el-icon-close"
                      size="small"
                      >删除</el-button
                    >
                  </el-popconfirm>
                </el-col>
              </el-row> -->
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div style="text-align: center; margin-top: 8px">
          <el-pagination
            @size-change="handleSizeChange0"
            @current-change="handleCurrentChange0"
            :current-page="designPageFrom0.pageNum"
            :page-size="designPageFrom0.pageSize"
            layout="prev, pager, next, jumper"
            :total="total0"
          >
          </el-pagination>
        </div>
      </div>

      <div style="width: 95%; margin: 0 auto">
        <el-row
          style="
            margin-bottom: 15px;
            display: flex;
            justify-content: ;
            align-items: center;
            margin-top: 10px;
          "
        >
          <span style="flex: 1">已设计</span>
          <el-button style="margin: 0 10px 0 auto" @click="dialogShow(2)"
            >一键导入</el-button
          >
          <el-button
            @click="formShowClick1"
            type="primary"
            style="background-color: #5e6cf4"
            >添加</el-button
          >
        </el-row>
        <!-- 表格 -->
        <el-table
          :data="tableData1"
          style="width: 100%"
          border
          :row-style="{ height: '30px' }"
          :cell-style="{ padding: '8px', textAlign: 'center' }"
          :header-cell-style="{
            textAlign: 'center',
            backgroundColor: '#f5f7fa',
          }"
        >
          <el-table-column prop="name" label="品牌名称"></el-table-column>
          <el-table-column label="设计文件" width="150">
            <template v-slot="scope">
              <el-button
                slot="reference"
                type="info"
                plain
                icon="el-icon-download"
                size="small"
                @click="upDataBrandDesign(scope.row.design_url)"
                >下载资料</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="creationTime"
            label="上传时间"
          ></el-table-column>
          <el-table-column
            prop="top"
            label="优先级"
            show-overflow-tooltip
          ></el-table-column>

          <el-table-column label="操作" width="100">
            <template v-slot="scope">
              <div class="ActionColumn"> 
                  <el-popconfirm
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    icon-color="red"
                    title="确定删除该设计文件吗？"
                    @confirm="delConfirm(scope.row, 1)">
                    <el-link
                      slot="reference"
                      type="primary"
                      class="ActionColumn_h"
                      >删除</el-link>
                  </el-popconfirm>
              </div>
              <!-- <el-row>
                <el-col>
                  <el-popconfirm
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    icon-color="red"
                    title="确定删除该设计文件吗？"
                    @confirm="delConfirm(scope.row, 1)"
                  >
                    <el-button
                      slot="reference"
                      type="danger"
                      plain
                      icon="el-icon-close"
                      size="small"
                      >删除</el-button
                    >
                  </el-popconfirm>
                </el-col>
              </el-row> -->
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div style="text-align: center; margin-top: 8px">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="designPageFrom1.pageNum"
            :page-size="designPageFrom1.pageSize"
            layout="prev, pager, next, jumper"
            :total="total1"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <el-drawer
      title="添加导入数据"
      :visible.sync="drawer"
      direction="rtl"
      :size="this.$equipment() ? '100%' : '30%'"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="80px"
        label-position="left"
        style="padding: 0 30px"
      >
        <el-form-item label="选择品牌">
          <el-autocomplete
            v-model="state"
            :fetch-suggestions="querySearchAsync"
            placeholder="请输入内容"
            @select="handleSelect"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item v-if="form.status === 0" label="优先级">
          <el-input v-model="form.top" placeholder="请输入数字等级"></el-input>
        </el-form-item>
        <el-form-item v-if="form.status === 0" label="资料类型">
          <el-radio-group v-model="form.type">
            <el-radio label="首次"></el-radio>
            <el-radio label="补充"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="form.status === 0" label="需求备注">
          <el-input type="textarea" v-model="form.remark"></el-input>
        </el-form-item>
        <el-form-item label="上传文件">
          <el-upload
            class="upload-demo"
            drag
            action=""
            :on-change="handleChange"
            :auto-upload="false"
            :file-list="fileList"
            :limit="1"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              <em>点击上传</em>
            </div>
            <div class="el-upload__tip" slot="tip">
              只能上传1个文件, 且不超过500kb
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="formAddClick">确定</el-button>
          <el-button @click="formHiedClick">取消</el-button>
        </el-form-item>
      </el-form>
      <!-- <div style="margin-top:60px;padding: 0 30px;">需求须知</div>
      <div style="padding: 5px 30px;">需求须知</div>
      <div style="padding: 5px 30px;">需求须知</div> -->
    </el-drawer>

    <!-- 多文件导入 -->
    <el-dialog
      :visible.sync="dialogTableVisible"
      :center="true"
      title="设计文件一键导入"
    >
      <div class="uploadWrap">
        <el-upload
          v-show="!upLoadsTableData.length"
          class="upload-demo"
          action=""
          :auto-upload="false"
          :on-change="oneKeyHandleChange"
          :limit="1"
          drag
          :file-list="oneKeyFileList"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将压缩包文件拖到此处，或<em>点击上传</em>
          </div>
          <!-- <div class="el-upload__tip" slot="tip">上传单个文件解析</div> -->
          <!-- <el-button size="small" type="primary" style="margin: 0 auto;">点击上传</el-button> -->
          <!-- <div slot="tip" class="el-upload__tip">上传单个文件解析</div> -->
        </el-upload>
      </div>
      <!-- 上传数据状态 -->
      <el-table
        v-show="upLoadsTableData.length"
        :data="upLoadsTableData"
        style="width: 361px; margin: 0 auto"
        border
        :cell-style="{ textAlign: 'center' }"
        :header-cell-style="{
          textAlign: 'center',
          backgroundColor: '#f5f7fa',
        }"
      >
        <el-table-column
          prop="brandName"
          label="品牌"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="upLoadType"
          label="上传状态"
          width="180"
        ></el-table-column>
      </el-table>
    </el-dialog>
  </el-card>
</template>

<script>
import { designPageList } from "@/api/http";
import { brandList, designAdd, designDel, designDelete } from "@/api/api";
import axios from "axios";

export default {
  data() {
    return {
      drawer: false,
      restaurants: [],
      state: "",
      timeout: null,
      mechanismDataList: [],
      typeList: [],
      fileList: [], // 上传文件
      form: {
        brandId: "",
        top: "",
        type: "",
        status: 0,
        remark: "",
      },

      // total: 0, // 表格总数据
      search: "", //搜素内容绑定
      activeName: "first",
      loadingTab: false, //表格加载属性
      serachValue: "", //搜索框内容绑定
      // 获取数据表单
      designPageFrom0: {
        pageNum: 1,
        pageSize: 5,
        status: "0",
      },
      designPageFrom1: {
        pageNum: 1,
        pageSize: 5,
        status: "1",
      },
      tableData0: [],
      tableData1: [],
      total0: 0, // 表格总数据
      total1: 0, // 表格总数据
      uploadShow: true, //           多文件上传组件显隐
      dialogTableVisible: false, //  dialog弹框显隐
      oneKeyFileList: [], //         一键导入文件数据
      upLoadsTableData: [], //        文件上传解析数据
      oneKeyUpType: 0, //上传设计状态 1待设计 2已设计
    };
  },
  created() {
    // 获取列表数据
    this.getdesignPageList(-1);
  },
  methods: {
    // 初始化测试数据
    creactTest() {
      this.upLoadsTableData = [
        {
          brandName: "测试1name",
          upLoadType: "成功1",
        },
        {
          brandName: "测试2name",
          upLoadType: "失败1",
        },
      ];
    },
    dialogShow(type) {
      this.dialogTableVisible = !this.dialogTableVisible;
      this.upLoadsTableData = []; //初始化数据
      this.oneKeyFileList = [];
      this.uploadShow = !this.uploadShow;
      this.oneKeyUpType = type;
    },
    // 删除设计文件
    async delConfirm(e, ind) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { designId } = e;
      const reData = await designDelete({ designId });
      loading.close();
      console.log("删除品牌设计", reData);
      if (reData.status === "success") {
        this.$message.success("删除成功！");
        this.getdesignPageList(ind); //重新请求渲染数据
      } else {
        this.$message.error("删除失败！");
      }
    },
    // 一键上传
    async oneKeyHandleChange(file, fileList) {
      this.uploadShow = false;
      this.oneKeyFileList = fileList;
      // this.$message.success("解析成功");
      let param = new FormData();
      param.append("file", fileList[0].raw);
      param.append("type", this.oneKeyUpType); // 一键上传 待设计1  已设计2
      const fileUrlData = await axios.post(
        "http://192.168.3.85:8078/test/ass/OAMDesign/batch/upload",
        // "https://api.jamboxsys.com/test/ass/OAMDesign/batch/upload",
        param,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: window.sessionStorage.getItem("token"),
          },
        }
      );
      const { data, msg } = fileUrlData.data;
      if (msg == "success") {
        let fileData = [];
        for (let key in data) {
          fileData.push({
            brandName: key,
            upLoadType: data[key],
          });
        }
        this.upLoadsTableData = fileData;
        // this.getdesignPageList(-1);
        this.$message.success("解析成功");
      } else {
        this.$message.error("解析成功");
        this.uploadShow = true;
        this.oneKeyFileList = [];
      }
      this.oneKeyFileList = [];
      //TODO 初始化测试数据
      // this.creactTest();
    },
    // 文件上传
    handleChange(file, fileList) {
      // console.log('handleChange', file, fileList.slice(-3))
      // this.fileList = fileList.slice(-3);
      this.fileList = fileList;
    },
    // 添加
    formShowClick() {
      this.restaurants = [];
      this.state = "";
      this.timeout = null;
      this.fileList = [];
      this.mechanismDataList = [];
      this.form = {
        brandId: "",
        top: "",
        type: "",
        status: 0,
        remark: "",
      };
      this.drawer = true;
    },

    formShowClick1() {
      this.restaurants = [];
      this.state = "";
      this.timeout = null;
      this.fileList = [];
      this.mechanismDataList = [];
      this.form = {
        brandId: "",
        top: "",
        type: "",
        status: 1,
        remark: "",
      };
      this.drawer = true;
    },
    formHiedClick() {
      this.drawer = false;
    },
    async formAddClick() {
      if (this.form.brandId === "") {
        this.$message.warning("选择品牌");
        return;
      }
      if (this.form.status === 0 && this.form.top === "") {
        this.$message.warning("输入优先级");
        return;
      }
      if (isNaN(Number(this.form.top, 10))) {
        this.$message.warning("优先级应为数字");
        return;
      }
      if (this.form.status === 0 && this.form.type === "") {
        this.$message.warning("选择资料类型");
        return;
      }
      if (this.form.status === 0 && this.form.remark === "") {
        this.$message.warning("输入需求备注");
        return;
      }
      if (!this.fileList.length) {
        this.$message.warning("上传要导入的文件");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let fileUrl = "";
      if (this.fileList && this.fileList.length) {
        let param = new FormData();
        param.append("file", this.fileList[0].raw);
        const fileUrlData = await axios.post(
          "https://api.jamboxsys.com/jambox/ass/file/upLoad",
          param,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        fileUrl = fileUrlData.data.data.url;
      }
      if (this.form.status === 1) {
        const data = {
          brandId: this.form.brandId,
          status: 1,
          designUrl: fileUrl || "",
        };
        const dataAdd = await designAdd(data);
        if (dataAdd.status == "success") {
          this.$message.success("添加成功");
          this.drawer = false;
          this.getdesignPageList(-1);
        } else {
          this.$message.error("添加失败");
        }
      } else {
        const data = {
          brandId: this.form.brandId,
          top: this.form.top,
          type: this.form.type,
          status: 0,
          designUrl: fileUrl || "",
        };
        const dataAdd = await designAdd(data);
        if (dataAdd.status == "success") {
          this.$message.success("添加成功");
          this.drawer = false;
          this.getdesignPageList(-1);
        } else {
          this.$message.error("添加失败");
        }
      }
      loading.close();
    },

    // 品牌校区搜索
    async querySearchAsync(queryString, cb) {
      const brandDataList = await brandList({ remarks: queryString });
      let restaurants = [];
      for (let a = 0; a < brandDataList.data.length; a++) {
        restaurants.push({
          id: brandDataList.data[a].id,
          value: brandDataList.data[a].name,
        });
      }
      let results = queryString
        ? restaurants.filter(this.createStateFilter(queryString))
        : restaurants;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(results);
      }, 3000 * Math.random());
    },

    createStateFilter(queryString) {
      return (state) => {
        return (
          state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },

    async handleSelect(item) {
      this.form.brandId = item.id;
    },

    // 改变状态
    async solvedConfirm(item) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const dataDel = await designDel({ designId: item.designId });
      loading.close();
      if (dataDel.status == "success") {
        this.$message.success("更新成功");
        this.getdesignPageList(-1);
      } else {
        this.$message.error("更新失败");
      }
    },

    // 获取数据方法
    async getdesignPageList(ind) {
      if (ind === -1) {
        this.loadingTab = true;
        const res0 = await designPageList(this.designPageFrom0);
        const res1 = await designPageList(this.designPageFrom1);
        console.log(res0);
        console.log(res1);

        this.tableData0 = res0.data.list;
        this.total0 = parseInt(res0.data.total);

        this.tableData1 = res1.data.list;
        this.total1 = parseInt(res1.data.total);
      } else {
        if (ind == 0) {
          const res0 = await designPageList(this.designPageFrom0);
          this.tableData0 = res0.data.list;
          this.total0 = parseInt(res0.data.total);
        }
        if (ind == 1) {
          const res1 = await designPageList(this.designPageFrom1);
          this.tableData1 = res1.data.list;
          this.total1 = parseInt(res1.data.total);
        }
      }
      this.loadingTab = false;
    },

    handleSizeChange0(newsize) {
      this.designPageFrom0.pageSize = newsize;
      // 重新获取数据
      this.getdesignPageList(0);
    },

    handleCurrentChange0(newpage) {
      this.designPageFrom0.pageNum = newpage;
      // 重新获取数据
      this.getdesignPageList(0);
    },

    handleSizeChange(newsize) {
      this.designPageFrom1.pageSize = newsize;
      // 重新获取数据
      this.getdesignPageList(1);
    },

    handleCurrentChange(newpage) {
      this.designPageFrom1.pageNum = newpage;
      // 重新获取数据
      this.getdesignPageList(1);
    },

    // 下载数据
    upDataBrandDesign(url) {
      window.location.href = url;
    },
  },
};
</script>

<style scoped>
.mar-auto {
  margin: 0 auto;
}
.el-table >>> .warning-row {
  background: oldlace;
}
.el-table >>> .success-row {
  background: #f0f9eb;
}
.active_imgs {
  width: 50px;
  height: 50px;
  margin-right: 8px;
}
.upload-mar {
  margin-bottom: 20px;
}
.el-upload__tip {
  text-align: center;
}
.uploadWrap {
  width: 362px;
  margin: 0 auto;
  overflow: auto;
}
</style>