<template>
  <!-- 工作项 -->
  <div>
    <el-row :gutter="10" style="display: flex; margin-bottom: 15px">
      <el-select
        v-model="dataFrom.systemName"
        clearable
        placeholder="请选择终端"
        @change="getTabelList()"
      >
        <el-option
          v-for="(item, index) in systemNameList"
          :key="index"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="dataFrom.staffId"
        placeholder="请选择负责人"
        style="margin-left: 20px"
        clearable
        @change="getTabelList()"
      >
        <el-option
          v-for="(item, index) in staffIdList"
          :key="index"
          :label="item.name"
          :value="item.staffId"
        >
        </el-option>
      </el-select>
      <el-button
        style="background-color: #5e6cf4; color: #fff; margin-left: 20px"
        @click="getTabelList()"
      >
        <i class="el-icon-search"></i>
      </el-button>
    </el-row>
    <el-table
      v-loading="tableLoading"
      :data="tableData"
      style="width: 100%; margin-bottom: 8px"
      border=""
      :row-style="{ height: '60px' }"
      :cell-style="{ padding: '0', textAlign: 'center' }"
      :header-cell-style="{
        textAlign: 'center',
        backgroundColor: '#f5f7fa',
      }"
      @cell-dblclick="copyText"
    >
      <el-table-column prop="statusTitle" label="开发阶段"> </el-table-column>
      <el-table-column prop="systemName" label="产品终端"> </el-table-column>
      <el-table-column prop="type" label="类型"> </el-table-column>
      <el-table-column prop="content" label="内容"> </el-table-column>
      <el-table-column prop="grade" label="等级"> </el-table-column>

      <el-table-column prop="costWei" label="耗费权重"> </el-table-column>
      <el-table-column prop="enclosure" label="附件" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="remarks" label="备注"> </el-table-column>
      <el-table-column prop="chargeName" label="负责人"> </el-table-column>
      <el-table-column prop="estimateTime" label="预计完成时间">
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template v-slot="scope">
          <div class="ActionColumn">
            <!-- <el-link
              @click="Showstaff(scope.row)"
              class="ActionColumn_h"
              type="primary"
              >编辑</el-link
            > -->
            <el-link
              @click="Showrecord(scope.row)"
              class="ActionColumn_h"
              type="primary"
              >记录</el-link
            >
            <el-link
              @click="Membersclick(scope.row)"
              class="ActionColumn_h"
              type="primary"
              >小组</el-link
            >
            <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              icon-color="red"
              title="确定删除吗？"
              @confirm="OKdelstaff(scope.row)"
            >
              <el-link class="ActionColumn_h" slot="reference" type="primary"
                >删除</el-link
              >
            </el-popconfirm>
            <!-- <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                icon-color="red"
                title="确定删除吗？"
                @confirm="OKdelstaff(scope.row)" >
              <el-link  class="ActionColumn_h" slot="reference" type="primary">删除</el-link>
            </el-popconfirm> -->
          </div>
          <div class="ActionColumn">
            <!-- <el-link
              @click="bindWork(scope.row)"
              class="ActionColumn_h"
              type="primary"
              >任务绑定</el-link
            > -->
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <div style="text-align: center">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="dataFrom.pageNum"
        :page-size="dataFrom.pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 添加/编辑弹窗 -->
    <el-drawer title="添加任务" :visible.sync="addDialog" @close="closedrawer">
      <div class="From_item">
        <div>产品终端</div>
        <el-select v-model="EditFrom.systemName" placeholder="请选择">
          <el-option
            v-for="(item, index) in systemNameList"
            :key="index"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>

      <div class="From_item">
        <div>类型</div>
        <el-select v-model="EditFrom.type" placeholder="请选择">
          <el-option
            v-for="(item, index) in TypeList"
            :key="index"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>

      <div class="From_item">
        <div>耗费权重</div>
        <el-input
          v-model="EditFrom.costWei"
          style="width: 70%"
          type="Number"
        ></el-input>
      </div>

      <div class="From_item">
        <div>内容</div>
        <el-input
          type="textarea"
          :rows="2"
          v-model="EditFrom.content"
          placeholder="请输入内容"
          style="width: 70%"
        >
        </el-input>
      </div>

      <div class="From_item">
        <div>上传附件</div>
        <el-upload
          class="upload-demo"
          action=""
          :on-change="handleChange"
          :on-remove="removeChange"
          :file-list="fileList"
          style="width: 70%"
          :auto-upload="false"
          ref="upload"
          :limit="1"
        >
          <el-button size="small" type="primary" style="float: left"
            >点击上传</el-button
          >
          <div slot="tip" class="el-upload__tip" style="width: 100%">
            支持文件：.ara .zip .doc .docx .pdf .jpg
          </div>
        </el-upload>
      </div>

      <div class="From_item">
        <div>备注</div>
        <el-input
          v-model="EditFrom.remarks"
          placeholder="请输入备注"
        ></el-input>
      </div>

      <div class="From_item">
        <div>请选择负责人</div>
        <el-select v-model="EditFrom.staffId" placeholder="请选择">
          <el-option
            v-for="(item, index) in staffIdList"
            :key="index"
            :label="item.name"
            :value="item.staffId"
          >
          </el-option>
        </el-select>
      </div>

      <div class="From_item">
        <div>预计完成时间</div>
        <el-date-picker
          v-model="EditFrom.estimateTime"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
        >
        </el-date-picker>
      </div>

      <!-- </from> -->
      <div class="dialog-footer">
        <el-button @click="addDialog = false">取 消</el-button>
        <el-button type="primary" @click="OKEdit()">确 定</el-button>
      </div>
    </el-drawer>

    <!-- 转交弹窗 -->
    <el-drawer title="转交任务" :visible.sync="HandOverDialog">
      <div class="From_item">
        <div>负责人</div>
        <el-select v-model="HandOverFrom.staffId" placeholder="请选择">
          <el-option
            v-for="(item, index) in staffIdList"
            :key="index"
            :label="item.name"
            :value="item.staffId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="From_item">
        <div>截止时间</div>
        <el-date-picker
          v-model="HandOverFrom.estimateTime"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
        >
        </el-date-picker>
      </div>

      <div class="dialog-footer">
        <el-button @click="HandOverDialog = false">取 消</el-button>
        <el-button type="primary" @click="OKHandOver()">确 定</el-button>
      </div>
    </el-drawer>

    <!-- 记录弹窗 -->
    <el-dialog title="操作记录" :visible.sync="dialogrecord">
      <el-table
        :data="recordTable"
        max-height="400px"
        style="width: 100%; margin-bottom: 8px"
        border=""
        :row-style="{ height: '60px' }"
        :cell-style="{ padding: '0', textAlign: 'center' }"
        :header-cell-style="{ textAlign: 'center', backgroundColor: '#f5f7fa' }"
      >
        <el-table-column property="time" label="时间"></el-table-column>
        <el-table-column property="statusTitle" label="操作"></el-table-column>
        <el-table-column property="chargeName" label="操作人"></el-table-column>
        <el-table-column property="staffId" label="负责人"></el-table-column>
        <el-table-column
          property="estimateTime"
          label="预计完成时间"
        ></el-table-column>
      </el-table>
    </el-dialog>

    <!-- 任务绑定框 -->
    <el-drawer title="已绑定需求" :visible.sync="bindDialog">
      <div class="From_item">
        <el-button type="warning" @click="BindRequirement()"
          >绑定需求</el-button
        >
      </div>
      <!-- <div class="From_item">
        <h3>已绑定需求</h3> 
      </div> -->
      <div style="width: 90%; margin: auto">
        <template v-if="taskList.length">
          <div
            v-for="(item, index) in taskList"
            :key="index"
            style="border-bottom: 1px solid #f5f5f5; padding: 8px 0"
          >
            {{ index + 1 }}. {{ item.content }}
          </div>
        </template>
        <h2 v-else>暂无绑定需求</h2>
      </div>
      <div class="dialog-footer">
        <!-- <el-button @click="HandOverDialog = false">取 消</el-button> -->
        <el-button type="primary" @click="bindDialog = false">确 定</el-button>
      </div>
    </el-drawer>

    <!-- 需求弹框 -->
    <el-dialog title="未绑定任务需求" :visible.sync="demandDialog">
      <div
        :gutter="20"
        style="margin-bottom: 15px; display: flex; flex-wrap: wrap"
      >
        <div
          :span="5"
          style="display: flex; align-items: center; margin-right: 15px"
        >
          <span style="white-space: nowrap">模糊搜索：</span>
          <el-input placeholder="请输入内容" v-model="DemandFrom.remarks">
          </el-input>
        </div>
        <div :span="2">
          <el-button
            style="background-color: #5e6cf4; color: #fff"
            @click="getDemand()"
          >
            <i class="el-icon-search"></i> 搜索
          </el-button>
        </div>
      </div>
      <el-table
        :data="demandList"
        max-height="400px"
        style="width: 100%; margin-bottom: 8px"
        border=""
        :row-style="{ height: '60px' }"
        :cell-style="{ padding: '0', textAlign: 'center' }"
        :header-cell-style="{ textAlign: 'center', backgroundColor: '#f5f7fa' }"
      >
        <el-table-column
          property="mechanism"
          label="机构名称"
        ></el-table-column>
        <el-table-column
          property="content"
          label="需求内容"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          property="creationTime"
          label="提出时间"
        ></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="small" type="primary" @click="bindRecnd(scope.row)"
              >绑定需求</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: center">
        <el-pagination
          @size-change="handleSizedemand"
          @current-change="handleCurrentdemand"
          :current-page="DemandFrom.pageNum"
          :page-size="DemandFrom.pageSize"
          layout="prev, pager, next, jumper"
          :total="DemandTotal"
        >
        </el-pagination>
      </div>
    </el-dialog>

    <!-- 成员管理 -->
    <el-dialog :visible.sync="memberStatus" @close="noeditMember">
      <h2>{{ this.memberFrom.grade }} 级需求</h2>
      <div
        :gutter="20"
        style="margin-bottom: 15px; display: flex; flex-wrap: wrap"
      >
        <p class="pTip">
          *Tip:
          A级/200%/30天，B级/100%/21天，C级/80%/14天，D级/40%/7天，E级/10%/2天
        </p>
        <!-- <div :span="5" style="display: flex;align-items: center;margin-right: 15px;">
          <span style="white-space: nowrap;">模糊搜索：</span>
          <el-input placeholder="请输入内容" v-model="DemandFrom.remarks"> </el-input>
        </div> -->
        <div style="display: flex; justify-content: space-between; width: 100%">
          <el-select
            v-model="Memberadd.staffId"
            placeholder="请选择负责人"
            style="margin-left: 20px"
            clearable
            @change="getTabelList()"
            v-if="editMemberStatus"
          >
            <el-option
              v-for="(item, index) in staffIdList"
              :key="index"
              :label="item.name"
              :value="item.staffId"
            >
            </el-option>
          </el-select>
          <label v-if="!editMemberStatus">{{ Memberadd.staffId }}</label>
          <el-input
            placeholder="请输入绩点"
            v-model="Memberadd.scale"
            style="width: 40%"
          >
          </el-input>
          <el-button
            style="background-color: #5e6cf4; color: #fff; width: "
            @click="addMember()"
            v-if="editMemberStatus"
          >
            添加
          </el-button>
          <el-button
            type="success"
            @click="editMemberOK()"
            v-if="!editMemberStatus"
          >
            修改
          </el-button>
          <el-button
            type="info"
            @click="noeditMember()"
            v-if="!editMemberStatus"
          >
            取消
          </el-button>
        </div>
      </div>
      <el-table
        :data="Member"
        max-height="400px"
        style="width: 100%; margin-bottom: 8px"
        border=""
        :row-style="{ height: '60px' }"
        :cell-style="{ padding: '0', textAlign: 'center' }"
        :header-cell-style="{ textAlign: 'center', backgroundColor: '#f5f7fa' }"
        v-if="editMemberStatus"
      >
        <el-table-column property="name" label="名称"></el-table-column>
        <el-table-column
          property="scale"
          label="绩点"
          show-overflow-tooltip
        ></el-table-column>
        <!-- <el-table-column property="creationTime" label="提出时间"></el-table-column> -->
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button
              size="small"
              @click="editMember(scope.row)"
              type="primary"
              style="margin-right: 8rpx"
              >编辑</el-button
            >
            <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              icon-color="red"
              title="确定删除成员吗？"
              @confirm="deleteMember(scope.row)"
            >
              <el-button size="small" slot="reference" type="danger"
                >删除</el-button
              >
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: center"></div>
    </el-dialog>
  </div>
</template>

<script>
import {
  iterationTypeList,
  dutyIterationList,
  iterationSystemList,
} from "@/api/DevelopmentManagement";
import {
  iterationPageList,
  iterationRecordList,
  iterationUpd,
  iterationStatusChange,
  iterationDel,
  OAMDemandDoundDemand,
  OAMDemandnotBindDemand,
  OAMDemanditerationBindDemand,
  scaleList,
  scaleAdd,
  scaleUpd,
  scaleDel,
} from "@/api/DevelopmentManagement";

import axios from "axios";

export default {
  data() {
    return {
      tableData: [],
      // 修改表单
      UserFrom: {},
      addDialog: false,
      HandOverDialog: false,
      dialogrecord: false,
      dataFrom: {
        pageNum: 1,
        pageSize: 10,
        status: "0",
        data: "",
        staffId: "",
        systemName: "",
      },
      // 文件
      fileList: [],
      tableLoading: false, //表格加载属性

      // 编辑
      EditFrom: {},
      TypeList: [],
      staffIdList: [],
      systemNameList: [],

      // 转交from
      HandOverFrom: {
        iterationId: "",
        estimateTime: "",
        staffId: "",
      },
      recordTable: [],
      deldFalw: false,
      total: 0,
      // 绑定任务
      bindDialog: false,
      // 任务数组
      taskList: [],
      // 任务表单
      remendFrom: {},
      // 需求弹框
      demandDialog: false,
      demandList: [],
      DemandFrom: {
        pageNum: 1,
        pageSize: 5,
        remarks: "",
      },
      DemandTotal: 0,

      // demandDialogLpa:false,
      memberFrom: {},
      memberStatus: false,
      Member: [],
      Memberadd: {
        staffId: "",
        scale: "",
      },
      // 编辑状态
      editMemberStatus: true,
    };
  },
  props: ["tabTypeIndex"],
  mounted() {
    this.dataFrom.status = this.tabTypeIndex;
    // //调用获取表格数据方法
    this.getTabelList();
    // this.areaData = area;
    this.getList();
  },
  methods: {
    async getList() {
      let res1 = await iterationTypeList({});
      // console.log(res1);
      this.TypeList = res1.data;
      let res2 = await dutyIterationList({});
      // console.log(res2);
      this.staffIdList = res2.data;
      let res3 = await iterationSystemList({});
      // console.log(res3);
      this.systemNameList = res3.data;
    },
    // 获取表格
    async getTabelList() {
      this.tableLoading = true;
      let res = await iterationPageList(this.dataFrom);
      this.tableData = res.data.list;
      this.total = parseInt(res.data.total);
      console.log(res);
      this.tableLoading = false;
    },
    handleSizeChange(newsize) {
      this.dataFrom.pageSize = newsize;
      // 重新获取数据
      this.getTabelList();
    },
    handleCurrentChange(newpage) {
      this.dataFrom.pageNum = newpage;
      // 重新获取数据
      this.getTabelList();
    },
    // 文件上传
    handleChange(file, fileList) {
      console.log(fileList);
      this.fileList = fileList.slice(-3);
    },
    // 删除文件
    removeChange(file, fileList) {
      console.log(fileList);
      this.EditFrom.enclosure = "";

      this.deldFalw = true;
    },
    // 编辑弹窗
    Showstaff(from) {
      console.log(from);
      this.EditFrom = JSON.parse(JSON.stringify(from));
      console.log(from.enclosure);
      if (from.enclosure) {
        this.fileList.push({
          name: from.enclosure.substr(-7),
          url: from.enclosure,
        });
      }

      // console.log(this.fileList);
      this.addDialog = true;
    },

    // 任务绑定
    async bindWork(from) {
      console.log(from);
      this.remendFrom = from;
      this.bindDialog = true;
      let res = await OAMDemandDoundDemand({ iterationId: from.iterationId });
      console.log(res);
      this.taskList = res.data;
    },

    // 绑定需求按钮 打开弹窗
    BindRequirement() {
      this.demandDialog = true;
      this.getDemand();
    },

    // 获取未绑定任务的需求
    async getDemand() {
      // this.demandDialogLpa = false
      console.log(this.DemandFrom.pageSize);
      let res = await OAMDemandnotBindDemand(this.DemandFrom);
      console.log(res);
      // this.demandDialogLpa = true
      this.demandList = res.data.list;
      this.DemandTotal = parseInt(res.data.total);
    },

    // 绑定任务
    async bindRecnd(from) {
      let postData = {
        demandId: from.demandId,
        iterationId: this.remendFrom.iterationId,
      };
      console.log(postData);
      // return
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await OAMDemanditerationBindDemand(postData);
      loading.close();
      console.log(res);
      if (res.status !== "success") this.$message.error(res.msg);
      this.$message.success("绑定成功");
      this.bindWork(this.remendFrom);
      this.getDemand(from);
    },

    handleSizedemand(newsize) {
      this.DemandFrom.pageSize = newsize;
      // 重新获取数据
      this.getDemand();
    },
    handleCurrentdemand(newpage) {
      this.DemandFrom.pageNum = newpage;
      // 重新获取数据
      this.getDemand();
    },

    // 成员管理
    async Membersclick(item) {
      console.log(item);
      this.memberFrom = item;
      this.getMenber();
    },
    async getMenber() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await scaleList({ iterationId: this.memberFrom.iterationId });
      loading.close();
      this.Member = res.data;
      this.memberStatus = true;
      // console.log(res);
    },
    // 添加成员
    async addMember() {
      let data = {
        iterationId: this.memberFrom.iterationId,
        staffId: this.Memberadd.staffId,
        scale: parseInt(this.Memberadd.scale),
      };
      if (data.staffId == "" || data.staffId == undefined) {
        this.$message.error("请选择成员");
        return;
      }
      if (isNaN(data.scale)) {
        this.$message.error("请输入正确绩点");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      console.log(data);
      let res = await scaleAdd(data);
      console.log(res);
      if (res.status == "success") {
        this.$message.success("添加成功");
        this.Memberadd.staffId = "";
        this.Memberadd.scale = "";
        this.getMenber();
      } else {
        this.$message.error(res.msg);
        loading.close();
      }
      this.memberStatus = true;
      console.log(res);
    },
    // 编辑成员
    editMember(row) {
      console.log(row);
      this.scaleIdMember = row.scaleId;
      this.Memberadd.staffId = row.name;
      this.Memberadd.scale = row.scale;
      this.editMemberStatus = false;
    },
    // 确定编辑
    async editMemberOK() {
      let data = {
        scaleId: this.scaleIdMember,
        iterationId: this.memberFrom.iterationId,
        scale: parseInt(this.Memberadd.scale),
      };
      console.log(data);
      if (isNaN(data.scale)) {
        this.$message.error("请输入正确绩点");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      console.log(data);
      let res = await scaleUpd(data);
      console.log(res);
      if (res.status == "success") {
        this.$message.success("编辑成功");
        this.Memberadd.staffId = "";
        this.Memberadd.scale = "";
        this.editMemberStatus = true;
        this.getMenber();
      } else {
        this.$message.error(res.msg);
        loading.close();
      }
    },
    // 取消编辑
    noeditMember() {
      this.Memberadd.staffId = "";
      this.Memberadd.scale = "";
      this.editMemberStatus = true;
    },
    // 删除成员
    async deleteMember(item) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      // console.log(data);
      let res = await scaleDel({
        scaleId: item.scaleId,
        iterationId: this.memberFrom.iterationId,
      });
      if (res.status == "success") {
        this.$message.success("删除成功");
        this.getMenber();
      } else {
        this.$message.error(res.msg);
        loading.close();
      }
      console.log(res);
    },

    // 编辑弹窗关闭事件
    closedrawer() {
      console.log(123);
      this.fileList = [];
    },
    // 转交弹窗
    ShowHandOverDialog(from) {
      this.HandOverDialog = true;
      this.HandOverFrom = from;
    },

    // 记录弹窗
    async Showrecord(from) {
      this.dialogrecord = true;
      console.log(from);
      let res = await iterationRecordList({ iterationId: from.iterationId });
      console.log(res);
      this.recordTable = res.data;
    },

    // 提交编辑
    async OKEdit() {
      if (this.deldFalw) {
        let fileUrl = "";
        if (this.fileList && this.fileList.length) {
          let param = new FormData();
          param.append("file", this.fileList[0].raw);
          const fileUrlData = await axios.post(
            "https://api.jamboxsys.com/jambox/ass/file/upLoad",
            param,
            { headers: { "Content-Type": "multipart/form-data" } }
          );
          fileUrl = fileUrlData.data.data.url;
          console.log(fileUrl);
        }
        this.EditFrom.enclosure = fileUrl;
      }
      console.log(this.EditFrom);
      let res = await iterationUpd(this.EditFrom);
      console.log(res);
      if (res.status === "success") {
        this.$message.success("编辑成功");
      } else {
        this.$message.error("编辑失败");
      }
      this.getTabelList();
      this.addDialog = false;
      this.$refs.upload.clearFiles();
    },

    // // 提交转交
    // async OKHandOver() {
    //   // console.log("提交转交");
    //   console.log(this.HandOverFrom);
    //   let res = await iterationStatusChange(this.HandOverFrom);
    //   if (res.status === "success") {
    //     this.$message.success("转交成功");
    //   } else {
    //     this.$message.error("转交失败");
    //   }
    //   this.HandOverDialog = false;
    //   this.getTabelList();
    // },

    // 确定删除
    async OKdelstaff(from) {
      let res = await iterationDel({ iterationId: from.iterationId });
      if (res.status === "success") {
        this.$message.success("删除成功");
      } else {
        this.$message.error("删除失败");
      }
      this.getTabelList();
    },

    // 复制内容
    copyText(row, column, cell, event) {
      // 双击复制
      let save = function (e) {
        e.clipboardData.setData("text/plain", event.target.innerText);
        e.preventDefault(); //阻止默认行为
      };
      console.log(save);
      document.addEventListener("copy", save); //添加一个copy事件
      document.execCommand("copy"); //执行copy方法
      this.$message({ message: "复制成功", type: "success" }); //提示
    },
  },
};
</script>

<style scoped>
/* 添加表单样式 */
.From_item {
  width: 90%;
  height: 70rpx;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.From_item div {
  width: 30%;
}
.From_item .el-input,
.From_item .el-select {
  width: 70%;
}
.dialog-footer {
  text-align: center;
  margin-top: 50px;
}

.pTip {
  position: relative;
  z-index: 99;
  margin-bottom: 20px;
  color: red;
  margin-top: 15px;
}
</style>