<template>
  <!-- <div>财务审核</div> -->
  <el-card>
    <!-- <el-row :gutter="13">
      <el-col :span="22">
        <el-tabs v-model="statusp" @tab-click="handleClick">
          <el-tab-pane
            v-for="item in tabsList"
            :label="item.value"
            :name="item.id"
            :key="item.id" >
          </el-tab-pane>
        </el-tabs>
      </el-col>
      <el-col :span="2"> -->
        <el-button
          style="background-color: #5e6cf4; color: #fff"
          @click="InstitutionalDag = true" >机构延期</el-button >
      <!-- </el-col>
    </el-row> -->

    <!-- <beConfirmed v-if="statusp === '0'" />
    <ContractInvoicing v-if="statusp === '1'" />
    <CompletedVue v-if="statusp === '2'" /> -->

    <!-- 延期操作框 -->
    <el-drawer
      title="修改到期时间"
      :visible.sync="InstitutionalDag"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      :size="this.$equipment() ? '100%' : '30%'" >
      <div class="From_item">
        <div>选择品牌</div>
        <el-autocomplete
          v-model="state"
          :fetch-suggestions="querySearchAsync"
          placeholder="请选择品牌"
          @select="handleSelect"
          style="width: 70%" ></el-autocomplete>
      </div>
      <div class="From_item">
        <div>绑定的校区</div>
        <el-select
          v-model="editFrom.mechanismId"
          filterable
          placeholder="请选择"
          @change="changemechan" >
          <el-option
            v-for="item in mechanismDataList"
            :key="item.mechanismId"
            :label="item.mechanism"
            :value="item.mechanismId" >
          </el-option>
        </el-select>
      </div>
      <div class="From_item" v-if="editFrom.storeId">
        <div>到期时间</div>
        <el-date-picker v-model="date" disabled type="date" >
        </el-date-picker>
      </div>
      <div class="From_item" v-if="editFrom.storeId">
        <div>延期时间</div>
        <el-date-picker :start-placeholder="date" v-model="editFrom.date" value-format="yyyy-MM-dd" type="date" placeholder="请选择选择日期">
        </el-date-picker>
      </div>
      <div class="From_item" v-if="editFrom.storeId">
        <div>延期原因</div>
        <el-input
          type="textarea"
          :rows="4"
          v-model="editFrom.reason"
          placeholder="请输入内容"
          style="width: 70%"
        >
        </el-input>
      </div>
      <div class="From_item" v-if="editFrom.storeId">
        <div>截图</div>
        <div style="width:70%">
          <Upload v-model="editFrom.picUrl"></Upload>
        </div>
      </div>
      <div style="text-align: center; margin-top: 25px" v-if="this.editFrom.storeId">
        <el-button
          style="background-color: #5e6cf4; color: #fff"
          @click="submilt()" > 延期</el-button >
      </div>
    </el-drawer>
  </el-card>
</template>

<script>
import beConfirmed from "./Financial_table/beConfirmed.vue";
import ContractInvoicing from "./Financial_table/ContractInvoicing.vue";
import CompletedVue from "./Financial_table/Completed.vue";

import { brandList, mechanismPageList, uploadAdd } from "@/api/api";
import {
  setEndTime,
} from "@/api/InstitutionSetting";

import Upload from "@/components/Upload-copy/index.vue";


export default {
  data() {
    return {
      tabsList: [
        {
          id: "0",
          value: "待确认",
        },
        {
          id: "1",
          value: "合同/开票",
        },
        {
          id: "2",
          value: "已完成",
        },
      ],
      statusp: "",
      state: "",
      InstitutionalDag: false,
      // 校区列表
      mechanismDataList: [],
      editFrom: {
        endTime: "",
        mechanismId: "",
        brandId: "",
        date:'',
        storeId:'',
        reason:"",
        picUrl:"",
      },
      date:''
    };
  },
  mounted() {
    this.statusp = window.localStorage.getItem("Transaction")
  },
  methods: {
    handleClick(tab, event) {
      // 获取token
      const tokenstr = window.sessionStorage.getItem("token");
      // 如果不存在这个令牌的话就强制跳转到登录页面
      if (!tokenstr) return this.$router.push("/Login");
      console.log(tab.index);
      window.localStorage.setItem("Transaction",tab.index)
      this.statusp = tab.index;
    },

    // 选择校区
    changemechan(e){
      console.log(e);
      this.mechanismDataList.forEach(item =>{
        if(item.mechanismId === e){
          this.editFrom.storeId = item.storeId
          this.date = item.endTime
          this.editFrom.date = JSON.parse(JSON.stringify(item.endTime))
        }
      })
      console.log(this.editFrom);
    },

    // 延期
    async submilt(){
      if (this.editFrom.date === "") {
        return this.$message.error("请填写到期时间");
      }
      if (this.editFrom.date === this.date) {
        return this.$message.error("延期时间与原来时间一致");
      }
      if (this.editFrom.reason == '') {
        return this.$message.error("请输入延期原因");
      }
      if (this.editFrom.picUrl == '') {
        return this.$message.error("请上传截图");
      }

      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let postData={
        storeId:this.editFrom.storeId,
        endTime:this.editFrom.date,
        reason:this.editFrom.reason,
        picUrl:this.editFrom.picUrl
      }
      console.log(postData);
      // return
      const res = await setEndTime(postData);
      console.log(res);
      if (res.status !== "success")
        return this.$message.error("修改失败,请查看校区是否正确");
      // this.getreviewActivities();
      this.$message.success("延期成功");
      // return
      this.editFrom.storeId = "";
      this.editFrom.endTime = "";
      this.InstitutionalDag = false;
      loading.close();
      // location.reload();

    },

    
    // 品牌校区搜索
    async querySearchAsync(queryString, cb) {
      const brandDataList = await brandList({ remarks: queryString });
      let restaurants = [];
      for (let a = 0; a < brandDataList.data.length; a++) {
        restaurants.push({
          id: brandDataList.data[a].id,
          value: brandDataList.data[a].name,
        });
      }
      
      // let results = queryString
      //   ? restaurants.filter(this.createStateFilter(queryString))
      //   : restaurants;

      let results = restaurants
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(results);
      }, 3000 * Math.random());
    },
    
    // 序列化操作
    createStateFilter(queryString){
      return (state) => {
        return (
          state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },

    // 校区搜索
    async handleSelect(item) {
      const data = {
        pageNumber: 1,
        pageSize: 100,
        brandId: item.id,
      };
      // this.editFrom.brandId = item.id;
      const mechanismDataList = await mechanismPageList(data);
      this.mechanismDataList = mechanismDataList.data.list;
      console.log(this.mechanismDataList);
    },
  },
  components: {
    beConfirmed,
    ContractInvoicing,
    CompletedVue,
    Upload,
  },

};
</script>

<style scoped>
/* 添加表单样式 */
.From_item {
  width: 90%;
  height: 70rpx;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.From_item div {
  width: 30%;
}
.From_item .el-input,
.From_item .el-select,
.From_item .el-cascader {
  width: 70%;
}
</style>