import requests from '@/api/request'

const apis = {

    // 工单获取表格数据
    workPageList(data, type = "post") {
        return requests({
            url: '/oam/work/workPageList',
            method: type,
            data
        })
    },
    // 提交工单
    workAdd(data, type = "post") {
        return requests({
            url: '/oam/work/workAdd',
            method: type,
            data
        })
    },

    // 工单提醒
    workRemind(data, type = "post") {
        return requests({
            url: '/oam/work/workRemind',
            method: type,
            data
        })
    },

    // 记录内容
    workRecordList(data, type = "post") {
        return requests({
            url: '/oam/work/workRecordList',
            method: type,
            data
        })
    },

    // 工单提交
    workRecordAdd(data, type = "post") {
        return requests({
            url: '/oam/work/workRecordAdd',
            method: type,
            data
        })
    },
    // 办结
    workEnd(data, type = "post") {
        return requests({
            url: '/oam/work/workEnd',
            method: type,
            data
        })
    },

    // 删除
    workDel(data, type = "post") {
        return requests({
            url: '/oam/work/workDel',
            method: type,
            data
        })
    },

    // 技术接收列表
    receivePageList(data, type = "post") {
        return requests({
            url: '/oam/work/receivePageList',
            method: type,
            data
        })
    },

    // 强制结单
    forceEnd(data, type = "post") {
        return requests({
            url: '/oam/work/forceEnd',
            method: type,
            data
        })
    },

    // 工单暂停
    workSuspend(data, type = "post") {
        return requests({
            url: '/oam//work/workSuspend',
            method: type,
            data
        })
    },



}


export { apis }