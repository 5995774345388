<template>
  <el-card>
    <!-- 值班表 -->
    <div v-loading="loadingTab" style="width: 95%; margin: 0 auto">
      <el-row>
        <el-col
          :span="2"
          :offset="0"
          style="margin-bottom: 15px"
        >
          <el-date-picker
            v-model="getFrom.dutyMoth"
            style=" margin-right: 20px; width: 160px"

            value-format="yyyy-MM"
            type="month"
            placeholder="选择月"
            @change="getreviewActivities()"
          >
          </el-date-picker>
        </el-col>
        <el-col
          :span="2"
          :offset="20"
          style="margin-bottom: 15px"
        >
          <el-button
            @click="formShowClick"
            type="primary"
            style="background-color: #5e6cf4; border-color: #5e6cf4"
            >添加
          </el-button>
        </el-col>
      </el-row>
          <!-- 日历组件 -->
        <el-calendar v-model="getFrom.dutyMoth" id="calendar">
          <div
            slot="dateCell"
            slot-scope="{ date, data }"
            @click="(e) => changeTime(e, date, data)"
          >
            <div style="font-weight: bold;">
              {{ data.day.split("-").slice(1).join("-") }}
            </div>
            <div class="calendar-day">
              <el-tag size="mini" style="margin-right:8px">售后</el-tag>{{ getTask(data) }}
            </div>
            <div class="calendar-day">
              <el-tag type="success" size="mini" style="margin-right:8px">技术</el-tag>{{ getTask2(data) }}
            </div>
          </div>
        </el-calendar>
    </div>

    <el-drawer
      title="添加值班记录"
      :visible.sync="drawer"
      direction="rtl"
      :size="this.$equipment() ? '100%' : '30%'"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="80px"
        label-position="left"
        style="padding: 0 30px"
      >
        <el-form-item label="值班时间">
          <el-date-picker
            type="date"
            placeholder="选择日期"
            v-model="form.dutyTime"
            style="width: 100%"
          >
          </el-date-picker>
        </el-form-item>
        <!-- <el-form-item label="售前值班">
          <el-select
            v-model="form.preSales"
            placeholder="请选择售前值班人"
            style="width: 100%"
          >
            <el-option
              v-for="item in preList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="售后值班">
          <el-select
            v-model="form.afterSales"
            placeholder="请选择售后值班人"
            style="width: 100%"
          >
            <el-option
              v-for="item in afterList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="技术值班">
          <el-select
            v-model="form.iteration"
            placeholder="请选择技术值班人"
            style="width: 100%"
          >
            <el-option
              v-for="item in iterationList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            v-model="form.remarks"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="formAddClick">确定</el-button>
          <el-button @click="formHiedClick">取消</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </el-card>
</template>

<script>
import {
  dutyPageList,
  dutyPreSalesList,
  dutyAfterSalesList,
  dutyIterationList,
  dutyAdd,
  dutyDel,
  getDutyData
} from "@/api/api";
import { formatYearMonthDay } from "@/util/time";


export default {
  data() {
    return {
      loadingTab: false,
      viewActiveFrom: {
        pageNum: 1,
        pageSize: 10,
      },
      tableData: [],
      total: 0, // 表格总数据
      // preList: [],
      afterList: [],
      iterationList: [],
      drawer: false,
      form: {
        dutyTime: "",
        // preSales: "",
        afterSales: "",
        iteration: "",
        remarks: "",
      },
      getFrom:{
        dutyMoth:'',
      }
    };
  },
  created() {
    this.getreviewActivities();
    this.getdutyList();
  },
  methods: {
    // 列表
    pageSizeChange(val) {
      this.viewActiveFrom.pageNum = 1;
      this.viewActiveFrom.pageSize = val;
      this.getreviewActivities();
    },
    async getreviewActivities(val) {
      // console.log(new Date().getMonth());
      // this.loadingTab = true;
      const dataLsit = await getDutyData(this.getFrom);
      console.log(dataLsit);
      this.tableData = dataLsit.result;
      // this.total = parseInt(dataLsit.data.total);
      // this.loadingTab = false;
    },
    // 售前 售后 技术
    async getdutyList() {
      // const preList = await dutyPreSalesList({});
      const afterList = await dutyAfterSalesList({});
      const iterationList = await dutyIterationList({});
      // this.preList = preList.data;
      this.afterList = afterList.data;
      this.iterationList = iterationList.data;
    },
    getTask2(val){
      return (this.tableData.find((c) => c.dutyDate === val.day) || {}).technologyStaffName;
    },
    getTask(val) {
      return (this.tableData.find((c) => c.dutyDate === val.day) || {}).serviceStaffName;
    },
    // 添加
    formShowClick() {
      this.form = {
        dutyTime: "",
        // preSales: "",
        afterSales: "",
        iteration: "",
        remarks: "",
      };
      this.drawer = true;
    },
    formHiedClick() {
      this.drawer = false;
    },
    async formAddClick() {
      if (this.form.dutyTime === "") {
        this.$message.warning("选择值班时间");
        return;
      }
      // if (this.form.preSales === "") {
      //   this.$message.warning("选择售前值班人员");
      //   return;
      // }
      if (this.form.afterSales === "") {
        this.$message.warning("选择售后值班人员");
        return;
      }
      if (this.form.iteration === "") {
        this.$message.warning("选择技术值班人员");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const data = {
        dutyTime: formatYearMonthDay(this.form.dutyTime),
        preSales: this.form.preSales,
        afterSales: this.form.afterSales,
        iteration: this.form.iteration,
        remarks: this.form.remarks,
      };
      console.log(data);
      const dataAdd = await dutyAdd(data);
      loading.close();
      if (dataAdd.status == "success") {
        this.$message.success("添加成功");
        this.drawer = false;
        this.getreviewActivities();
      } else {
        this.$message.error("添加失败");
      }
    },
    changeTime(e, date, data) {
      console.log(data.day);
      // this.$router.push({
      //   path: "/devManage/DevelopmentManagement/taskList",
      //   query: { time: data.day, staffId: this.from.staffId },
      // });
    },

    // 删除
    async dutyDelConfirm(item) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const dataDel = await dutyDel({ dutyId: item.dutyId });
      loading.close();
      if (dataDel.status == "success") {
        this.$message.success("删除成功");
        this.getreviewActivities();
      } else {
        this.$message.error("删除失败");
      }
    },
    
  },
};
</script> 

<style scoped>
.calendar-day{
  font-size: 14px;
  margin-bottom: 6px;
}

/deep/ .el-calendar__button-group {
  display: none;
}
/deep/ .el-calendar-table:not(.is-range) td.next {
  pointer-events: none;
}

/deep/ .el-calendar-table:not(.is-range) td.prev {
  pointer-events: none;
}
</style>