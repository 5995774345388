import requests from '@/api/request'
const apis = {
  // 数据总览
  dataOverview(data, type = "post") {
    return requests({
      url: '/oam/clue/dataOverview',
      method: type,
      data
    })
  },
  // 获取近期事务
  clueParamDetails(data, type = "post") {
    return requests({
      url: '/oam/clue/clueParamDetails',
      method: type,
      data
    })
  },
  // 事务修改
  clueParamSet(data, type = "post") {
    return requests({
      url: '/oam/clue/clueParamSet',
      method: type,
      data
    })
  },

  // 生成日报
  dailyPaper(data, type = "post") {
    return requests({
      url: '/oam/clue/dailyPaper',
      method: type,
      data
    })
  },

  // 线索待跟进
  beFollowList(data, type = "post") {
    return requests({
      url: '/oam/clue/beFollowList',
      method: type,
      data
    })
  },
  // 线索今日已跟进
  followList(data, type = "post") {
    return requests({
      url: '/oam/clue/followList',
      method: type,
      data
    })
  },
  // 线索签约待确认
  dealByList(data, type = "post") {
    return requests({
      url: '/oam//clue/dealByList',
      method: type,
      data
    })
  },
  // 线索今即将流失
  lossListBy(data, type = "post") {
    return requests({
      url: '/oam/clue/lossListBy',
      method: type,
      data
    })
  },
  // 线索池
  cluePool(data, type = "post") {
    return requests({
      url: '/oam/clue/cluePool',
      method: type,
      data
    })
  },
  // 校区线索池
  mechanismPool(data, type = "post") {
    return requests({
      url: '/oam/clue/mechanismPool',
      method: type,
      data
    })
  },
  // 线索领取
  clueReceive(data, type = "post") {
    return requests({
      url: '/oam/clue/clueReceive',
      method: type,
      data
    })
  },
  // 校区线索领取
  mechanismPoolReceive(data, type = "post") {
    return requests({
      url: '/oam/clue/mechanismPoolReceive',
      method: type,
      data
    })
  },
  // 查看KPI
  clueKPIStaff(data, type = "post") {
    return requests({
      url: '/oam/clueKPI/clueKPIStaff',
      method: type,
      data
    })
  },
  // 签约撤回
  financeWithdraw(data, type = "post") {
    return requests({
      url: '/oam/finance/financeWithdraw',
      method: type,
      data
    })
  },


  // 校区线索绑定
  mechanismPoolBind(data, type = "post") {
    return requests({
      url: '/oam/clue/mechanismPoolBind',
      method: type,
      data
    })
  },

  // 个人线索搜索
  clueSearch(data, type = "post") {
    return requests({
      url: '/oam/clue/clueSearch',
      method: type,
      data
    })
  },

  // KPI设置----------------------------------------------------------------------------------
  // 设置KPI
  clueParamSet(data, type = "post") {
    return requests({
      url: '/oam/clue/clueParamSet',
      method: type,
      data
    })
  },
  // 查看KPI
  clueParamDetails(data, type = "post") {
    return requests({
      url: '/oam/clue/clueParamDetails',
      method: type,
      data
    })
  },
  // 继承
  clueInherit(data, type = "post") {
    return requests({
      url: '/oam/clue/clueInherit',
      method: type,
      data
    })
  },
  // 获取KPI列表
  clueKPIList(data, type = "post") {
    return requests({
      url: '/oam/clueKPI/clueKPIList',
      method: type,
      data
    })
  },
  // 添加员工
  clueKPIAdd(data, type = "post") {
    return requests({
      url: '/oam/clueKPI/clueKPIAdd',
      method: type,
      data
    })
  },
  // 编辑员工
  clueKPIUpd(data, type = "post") {
    return requests({
      url: '/oam/clueKPI/clueKPIUpd',
      method: type,
      data
    })
  },
  // 删除员工
  clueKPIDel(data, type = "post") {
    return requests({
      url: '/oam/clueKPI/clueKPIDel',
      method: type,
      data
    })
  },

}

export { apis }
