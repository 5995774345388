// 电子签
import axios from 'axios'
const api = axios.create({
    baseURL: 'https://beibeike-qy-b33k4-1302318474.ap-shanghai.app.tcloudbase.com/', // 请求的公共地址部分
    header:{ "Content-Type": "application/json" }
})

// interceptors axios的拦截器对象
api.interceptors.request.use(config => {
    // config 请求的信息
   return config 
}, err => {
   // 请求发生错误时的处理 抛出错误
  Promise.reject(err)
})
api.interceptors.response.use(res => {
    console.log(res)
   return Promise.resolve(res)
}, err => {
    // 服务器响应发生错误时的处理
    Promise.reject(err)
})

  

function request({ method = "post",url='mapi_econtractAdd', params = {} }){
    return api({
        method,
        params,
    })
}

// export default reques

export const electronic =(data)=>request({
    data
})