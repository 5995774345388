import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

import user from './user';

// 创建一个新的 store 实例
const store = new Vuex.Store({
  modules: {
    user
  },
});

export default store;