<template>
  <el-card>
    <div class="title-top">
      <div class="title-top-left">
        <el-select
          v-model="from.staffId"
          placeholder="请选择负责人"
          style="margin-left: 20px; margin-right: 20px; width: 180px"
          clearable
          @change="getTabledata()"
        >
          <el-option
            v-for="(item, index) in staffIdList"
            :key="index"
            :label="item.name"
            :value="item.staffId"
          >
          </el-option>
        </el-select>
        <el-date-picker
          v-model="from.month"
          value-format="yyyy-MM"
          type="month"
          placeholder="选择月"
        >
        </el-date-picker>
      </div>
      <div class="title-top-right">
        <div class="title-box">
          <p>待开始</p>
          <h2>{{ ordata.beStart }}</h2>
        </div>
        <div class="title-box">
          <p>进行中</p>
          <h2>{{ ordata.beStart }}</h2>
        </div>
        <div class="title-box">
          <p>已完成</p>
          <h2>{{ ordata.beAccomplish }}</h2>
        </div>
        <div class="title-box">
          <p>逾期</p>
          <h2>{{ ordata.beExceed }}</h2>
        </div>
        <div class="title-box">
          <p>当月绩点</p>
          <h2>{{ ordata.scaleSum }}</h2>
        </div>
      </div>
    </div>

    <!-- 日历组件 -->
    <el-calendar v-model="from.month" id="calendar">
      <div
        slot="dateCell"
        slot-scope="{ date, data }"
        @click="(e) => changeTime(date, data)"
      >
        <div class="calendar-day">
          {{ data.day.split("-").slice(1).join("-") }}
        </div>
        <div class="calendar-content">
          {{ getTask(data) }}
        </div>
      </div>
    </el-calendar>
  </el-card>
</template>


<script>
import { iterationDataByMonth } from "@/api/DevelopmentManagement";
export default {
  data() {
    return {
      calendarData: [],
      timeValue: new Date(),
      ordata: {},
      from: {
        month: "",
        staffId: "",
      },
      staffIdList: [],
    };
  },
  created() {
    console.log(123);
    this.getdata();
    
  },
  methods: {
    async getdata() {
      let res = await iterationDataByMonth(this.from);
      console.log(res);
      this.calendarData = res.data.monthData;
      this.ordata = res.data;

    },
    changeTime(e, data) {
      e.stopPropagation();
    },
    getTask(val) {
      // console.log(val)
      // return (this.calendarData.find((c) => c.time === val.day) || {}).value;
      return this.calendarData[val.day];
    },
  },
};
</script>



<style  scoped>
#calendar /deep/ .el-calendar-day {
  height: auto;
}
.calendar-content {
  width: 100%;
  min-height: 80px;
  height: auto;
  font-size: 13px;
  color: #999;
}
.title-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title-top-right {
  display: flex;
  align-items: center;
}
.title-box {
  width: 110px;
  height: 80px;
  background-color: aqua;
  border-radius: 8px;
  margin-right: 13px;
  padding: 8px;
  box-sizing: border-box;
}
.title-box h2 {
  text-align: right;
  margin-top: 8px;
}
</style>