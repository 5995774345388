// 员工管理

import requests from "./request";

// 获取父节点选项
export const OAMDesignList = () => requests({
    url: '/oam/function/list',
    method: "get",
    // data: query1
});

// 新增功能
export const OAMDesign = (query1) => requests({
    url: '/oam/function',
    method: "POST",
    data: query1
});

// 获取功能列表
export const functionPage = (query1) => requests({
    url: `/oam/function/page?current=${query1.current}&size=${query1.size}`,
    method: "GET",
});

// 修改功能
export const functionEdit = (query1) => requests({
    url: '/oam/function',
    method: "PUT",
    data: query1
});

// 删除功能
export const functionDelete = (query1) => requests({
    url: '/oam/function',
    method: "DELETE",
    data: query1
});

const apis = {
    // 权限接口-----------------------
    // 获取权限列表
    perTreeList(data) {
        return requests({
            url: '/oam/function/tree',
            method: "GET",
            data
        })
    },
    // 添加权限组
    addGroup(data, type = "POST") {
        return requests({
            url: '/oam/group',
            method: type,
            data
        })
    },
    // 获取权限组列表数据
    getPerData(data) {
        return requests({
            url: '/oam/group/list',
            method: "GET",
            data
        })
    },
    // 获取权限组 权限勾选数据
    getFunctionids(query1) {
        return requests({
            url: `/oam/group/functionids/${query1}`,
            method: "GET",
        })
    },
    // 删除权限组
    deleteGroupData(data) {
        return requests({
            url: 'oam/group',
            method: "DELETE",
            data
        })
    },
    // 获取分页
    getCurrentPage(data) {
        return requests({
            url: 'oam/group/page',
            method: "GET",
            data
        })
    },

    // 员工设置接口-----------------------
    getUserData(data) {
        return requests({
            url: 'oam/user/list',
            method: "GET",
            data
        })
    },
    // 获取分页
    getUserPage({current, size}) {
        return requests({
            url: `oam/user/page?current=${current}&size=${size}`,
            method: "GET",
            // data
        })
    },
    // 新增用户
    addEmplUser(data, type = 'POST') {
        return requests({
            url: 'oam/user',
            method: type,
            data
        })
    },
    // 修改用户权限
    putEmplUser(userId, groupId) {
        return requests({
            url: `oam/user/authority/${userId}/${groupId}`,
            method: "PUT",
        })
    },
    // 删除用户权限
    deleteEmplUser(data) {
        return requests({
            url: `oam/user`,
            method: "DELETE",
            data
        })
    },
}


export { apis }
