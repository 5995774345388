<template>
  <!-- 操作记录 -->
  <el-card>
    <div v-loading="loadingTab" style="width: 95%; margin: 0 auto">
      <div :gutter="20" style="margin-bottom:15px;display: flex;flex-wrap: wrap;">
        <div :span="5" style="display: flex;align-items: center;margin-right: 15px;">
          <span style="white-space: nowrap;">模糊搜索：</span>
          <el-input placeholder="请输入内容" v-model="viewActiveFrom.remarks"> </el-input>
        </div>
        <div :span="5" style="display: flex;align-items: center;margin-right: 15px  ;">
          <span style="white-space: nowrap;">操作人：</span>
          <el-input placeholder="请输入内容" v-model="viewActiveFrom.name"> </el-input>
        </div>
        <div :span="2">
          <el-button style="background-color: #5e6cf4; color: #fff;" @click="searchClick()">
            <i class="el-icon-search"></i> 搜索
          </el-button>
        </div>
      </div>
      <!-- 表格 -->
      <el-table :data="tableData" style="width: 100%" border :cell-style="{ textAlign: 'center' }"
        :header-cell-style="{ textAlign: 'center', backgroundColor: '#f5f7fa' }">
         <el-table-column type="index" label="#" ></el-table-column>
        <el-table-column prop="name" label="操作人" width="200"></el-table-column>
        <el-table-column prop="creationTime" label="操作时间" width="200"></el-table-column>
        <el-table-column prop="type" label="操作类型" width="200"></el-table-column>
        <el-table-column prop="content" label="操作内容" show-overflow-tooltip></el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination style="margin-top: 18px;" @size-change="pageSizeChange" @current-change="getreviewActivities"
        :current-page.sync="viewActiveFrom.pageNum" :page-sizes="[10, 20, 30, 50]" :page-size="viewActiveFrom.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </el-card>
</template>

<script>
import { recordPageList } from "@/api/api";
export default {
  data() {
    return {
      loadingTab: false,
      viewActiveFrom: {
        pageNum: 1,
        pageSize: 10,
        name: '',
        remarks: ''
      },
      tableData: [],
      total: 0, // 表格总数据
    };
  },

  created() {
    this.getreviewActivities();
  },
  
  methods: {
    // 列表
    pageSizeChange(val) {
      this.viewActiveFrom.pageNum = 1
      this.viewActiveFrom.pageSize = val
      this.getreviewActivities()
    },

    async getreviewActivities() {
      this.loadingTab = true;
      const dataLsit = await recordPageList(this.viewActiveFrom);
      this.tableData = dataLsit.data.list;
      this.total = parseInt(dataLsit.data.total);
      this.loadingTab = false;
    },

    
    // 搜索
    searchClick() {
      this.viewActiveFrom.pageNum = 1
      this.getreviewActivities()
    }
  }

};
</script>