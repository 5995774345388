<template>
  <div class="max-card" >
    <!-- 顶部内容 -->
    <div class="Notice">
      <div class="Notice_label">最新评价:</div>
      <marquee class="Notice_content margin-right">
        {{ homePageFinanceStr }}
      </marquee>
      <!-- <div class="Notice_label">值班人员：</div>
      <div class="Notice_content">技术值班：谭佰成 -----售后值班：黄修宇</div> -->
    </div>
    <!-- 中间内容 -->
    <div class="centent">
      <div class="centent-left">
        <div class="centent-left-top">
          <div class="centent-left-top-t">
            <div class="centent-left-top-t-wanc flexcent">
              成交校区总量
              <h2>{{ dataFrom.financeNum }}</h2>
            </div>
            <div class="centent-left-top-t-cpts flexcent">
              投诉总量
              <h2>{{ dataFrom.complaintNum }}</h2>
            </div>
          </div>
          <div class="centent-left-top-c">
            <div class="centent-left-top-c-xq flexcent">
              <h2>{{ dataFrom.demandNum }}</h2>
              待解决需求
            </div>
            <div class="centent-left-top-c-btm">
              <div class="centent-left-top-c-btm-con flexcent">
                注册校区总量
                <h2>{{ dataFrom.mechanismNum }}</h2>
              </div>
              <div class="centent-left-top-c-btm-con2 flexcent">
                线索总量
                <h2>{{ dataFrom.clueNum }}</h2>
              </div>
            </div>
          </div>
          <div class="centent-left-top-l flexcent">
            <h2>{{ dataFrom.channelNum }}</h2>
            渠道总量
          </div>
        </div>
        <div class="centent-left-bottom">
          <p class="title"><img src="@/assets/zb.png" />值班</p>
          <div class="Table-zbb">
            <div class="Table_th">
              <p>时间</p>
              <p>技术</p>
              <p>售后</p>
            </div>
              <div class="Table_th Table_th-color" v-for="(item, index) in DutyList" :key="index">
                <p>{{ item.dutyTime }}</p>
                <p>{{ item.iteration }}</p>
                <p>{{ item.afterSales }}</p>
              </div>
          </div>
          <p class="title"><img src="@/assets/pj.png" />最新注册校区</p>
          <div class="Table-zbb">
            <div class="Table_th">
              <p>注册时间</p>
              <p>机构名称</p>
              <p>负责人</p>
            </div>
            <template v-for="(item, index) in MechanismList" >
              <div class="Table_th Table_th-color" v-if="index < 3" :key="index">
                <p>{{ item.creationTime }}</p>
                <p>{{ item.mechanism }}</p>
                <p>{{ item.chargeName }}</p>
              </div>
            </template>

            <!-- <div class="Table_th Table_th-color">
              <p>2022-9-24</p>
              <p>谭佰成</p>
              <p>修宇</p>
            </div> -->
            
          </div>
        </div>
      </div>
      <div class="centent-right">
        <p class="title"><img src="@/assets/zx.png" />最新渠道跟进</p>
        <div class="new_Table border">
          <div class="new_content">
            <p class="new_content-h1">渠道名称</p>
            <p class="new_content-h1">负责人</p>
            <p class="new_content-h1">日期</p>
            <p class="new_content-h4">内容</p>
          </div>
          <!-- <block  > -->
            <div class="new_content Table_th-color" v-for="(item,index) in channelList" :key="index">
              <p class="new_content-h1" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{item.channel}}</p>
              <p class="new_content-h1">{{item.name}}</p>
              <p class="new_content-h1" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{item.creationTime}}</p>
              <marquee class="new_content-h4">{{item.content}}</marquee>
            </div>
          <!-- </block> -->
        </div>
        <p class="title"><img src="@/assets/gj.png" style="" />最新线索跟进
          <!-- <el-pagination
            layout="prev, pager, next"
            :total="50"
            @size-change="pageSizeChange" @current-change="getreviewActivities">
          </el-pagination> -->
        </p>
        <div class="new_Table border">
          <div class="new_content">
            <!-- <p class="new_content-h1">机构名称</p> -->
            <p class="new_content-h1">机构名称</p>
            <p class="new_content-h1">负责人</p>
            <p class="new_content-h1">跟进时间</p>
            <p class="new_content-h4">最新内容</p>
          </div>
          <div class="new_content Table_th-color" v-for="(item,index) in clueList" :key="index" >
            <!-- <p class="new_content-h1">{{item.name}}</p> -->
            <p class="new_content-h1" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{item.clue}}</p>
            <p class="new_content-h1">{{item.name}}</p>
            <p class="new_content-h1" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{item.creationTime}}</p>
            <marquee class="new_content-h4">{{item.content}}
            </marquee>
          </div>
        </div>
      </div>
    </div>
    <!-- 下部分内容 -->
    <div class="bottom_content">
      <div class="bottom_content_item">
        <p class="title"><img src="@/assets/sh.png" />售后服务</p>
        <div class="bottomT border">
          <div class="Table_th">
            <p>日期</p>
            <p>完成人</p>
            <p>完成项目</p>
            <p>服务内容</p>
          </div>
          <div class="Table_th Table_th-color" v-for="(item,index) in afterServiceList" :key="index">
            <p style=" white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{item.creationTime}}</p>
            <p>{{item.name}}</p>
            <marquee style="width:30%">{{item.mechanism}}</marquee>
            <marquee style="width:30%">{{item.content}}</marquee>
          </div>
         
        </div>
      </div>
      <div class="bottom_content_item">
        <p class="title"><img src="@/assets/fw.png" />迭代记录</p>
        <div class="bottomT border">
          <div class="Table_th">
            <p>终端</p>
            <p>日期</p>
            <p>完成项目</p>
          </div>
          <template v-for="(item,index) in iterationList"  >
            <div class="Table_th Table_th-color" v-if="index<5" :key="index">
              <p  style="width:30%; ">{{item.systemName}}</p>
              <p style="width:30%; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{item.creationTime}}</p>
              <marquee style="width:30%">{{item.content}}</marquee>
            </div>
          </template>

        </div>
      </div>
      <div class="bottom_content_item">
        <p class="title"><img src="@/assets/yw.png" />运维记录</p>
        <div class="bottomT border">
          <div class="Table_th">
            <p >操作人</p>
            <p>日期</p>
            <p>操作内容</p>
          </div>
          <div class="Table_th Table_th-color" v-for="(item,index) in recordList" :key="index">
            <p style="width:30%">{{item.name}}</p>
            <p style="width:30%; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{item.creationTime}}</p>
            <marquee style="width:30%">{{item.content}}</marquee>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { HomeList } from "@/api/http";
// import { evaluatePageList } from "@/api/api";
import { apis } from "@/api/Welcome";

export default {
  data() {
    return {
      loadingTab: false,
      // 最新成交
      homePageFinanceStr: "",
      dataFrom: {},
      DutyList: [],
      MechanismList: [],
      channelList:[],
      clueList:[],
      iterationList:[],
      recordList:[],
      afterServiceList:[]
    };
  },
  methods: {

    // handleSizeChange(newsize) {
    //   this.dataFrom.pageSize = newsize;
    //   // 重新获取数据
    //   this.getTableList(0);
    // },
    // handleCurrentChange(newpage) {
    //   this.dataFrom.pageNum = newpage;
    //   // 重新获取数据
    //   this.getTableList(0);
    // },
    // 获取数据
    async getHomeList() {
      // 最新成交
      this.loadingTab = true;
      let FinanceStr = await apis.homePageFinanceStr({});
      console.log(FinanceStr);
      this.homePageFinanceStr = FinanceStr.data;

      // 数据汇总
      let NumCount = await apis.homePageNumCount({});
      this.dataFrom = {
        financeNum: NumCount.data.financeNum,
        complaintNum: NumCount.data.complaintNum,
        demandNum: NumCount.data.demandNum,
        mechanismNum: NumCount.data.mechanismNum,
        clueNum: NumCount.data.clueNum,
        channelNum: NumCount.data.channelNum,
      };
      // 最新注册校区
      let MechanismList = await apis.homePageMechanismList({});
      this.MechanismList = MechanismList.data;
      // 值班
      let DutyListt = await apis.homePageDutyListt({});
      this.DutyList = DutyListt.data;
      // 最新渠道
      let channelList = await apis.homePagechannelList({});
      this.channelList = channelList.data;
      // 最新线索
      let clueList = await apis.homePageclueList({});
      this.clueList = clueList.data;
      // 开发记录
      let iterationList = await apis.homePageiterationList({});
      this.iterationList = iterationList.data;
      // 运维记录
      let recordList = await apis.homePagerecordList({});
      this.recordList = recordList.data;
        // 售后记录
      let afterServiceList = await apis.homePageafterServiceList({});
      this.afterServiceList = afterServiceList.data;

      // this.loadingTab = false;
    },
  },

  created() {
    this.getHomeList();
    console.log(this.$equipment());
    if (this.$equipment()) {
      this.widthMax = "100%";
      this.widthMax2 = "100%";
    } else {
      this.widthMax = "31.5%";
      this.widthMax2 = "61.5%";
    }
  },
};
</script>

<style scoped>
.max-card {
  width: 100%;
  min-width: 1080px;
  height: auto;
  /* background-color: #ccc; */
  /* overflow: auto; */
}
.margin-right {
  margin-right: 55px;
}
.Notice {
  width: 100%;
  height: 60px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0 25px;
  box-sizing: border-box;
}
.Notice_label {
  width: 100px;
}
.Notice_content {
  flex: 1;
  padding: 0 90px 0 0;
  background-color: #f5f7f7;
  color: #959595;
  padding: 5px 20px;
  border-radius: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* 中间内容 */
.centent {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  display: flex;
  margin-top: 15px;
}
.centent-left {
  width: 500px;
  /* background-color: #959595; */
}
.centent-left-top {
  width: 100%;
  height: 210px;
  display: flex;
  justify-content: space-between;
  /* background-color: #fff; */
}
.centent-left-top-t {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.centent-left-top-t .centent-left-top-t-wanc {
  width: 100%;
  height: 115px;
  background-color: #34c7bf;
}
.centent-left-top-t .centent-left-top-t-cpts {
  width: 100%;
  height: 85px;
  background-color: #8a75f3;
}
.centent-left-top-c {
  width: 42%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.centent-left-top-c-xq {
  width: 100;
  height: 80px;
  background-color: #fd7eaa;
}
.centent-left-top-c-btm {
  width: 100%;
  /* margin-top: 20px; */
  height: 120px;
  display: flex;
  justify-content: space-between;
}
.centent-left-top-c-btm-con {
  width: 45%;
  background-color: #f76f61;
}
.centent-left-top-c-btm-con2 {
  width: 45%;
  background-color: #92d295;
}
.centent-left-top-l {
  width: 21%;
  background-color: #3e93ed;
}
.flexcent {
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  font-size: 14px;
}
.flexcent h2 {
  text-align: right;
}
/* 值班表 */
.centent-left-bottom {
  margin-top: 10px;
  box-sizing: border-box;
}
.Table-zbb {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 8px;
  box-sizing: border-box;
  margin-top: 5px;
  /* box-sizing: border-box; */
}
.Table_th {
  display: flex;
  justify-content: space-around;
  margin-bottom: 5px;
  font-size: 15px;
}
.Table_th-color {
  color: rgb(83, 82, 82);
}
.Table_th p {
  width: 33.3333%;
  text-align: center;
}
.title {
  font-size: 15px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.title img {
  width: 25px;
  margin-right: 8px;
}
/* 右侧 */
.centent-right {
  flex: 1;
  margin-left: 15px;
  /* background-color: aqua; */
}
/* 最新渠道 */
.border {
  /* background-color: #fff; */
  background-color: #fff;
  border-radius: 10px;
  padding: 8px 15px;
  box-sizing: border-box;
  margin-top: 5px;
}
.new_Table {
  width: 100%;
  height: 197px;
}
.new_content {
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 9px;
}
.new_content-h1 {
  width: 20%;
  /* text-align: center; */
  /* background-color: #3e93ed; */
}
.new_content-h2 {
  width: 15%;
}
.new_content-h3 {
  width: 25%;
  /* text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; */
}
.new_content-h4 {
  width: 40%;
}

/* 底部内容 */
.bottom_content {
  width: 100%;
  min-width: 1000px;
  display: flex;
  justify-content: space-between;
}
.bottom_content_item {
  width: 32%;
}

.bottomT {
  width: 100%;
  height: auto;
}
</style>