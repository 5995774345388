<template>
  <div>
    <el-row :gutter="10" style="display: flex; margin-bottom: 15px">
      <!-- <el-col :span="4"> -->
      <el-select
        v-model="dataFrom.systemName"
        placeholder="请选择终端"
        clearable
        @change="getTabelList()"
      >
        <el-option
          v-for="(item, index) in systemNameList"
          :key="index"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
      <el-button
        style="background-color: #5e6cf4; color: #fff; margin-left: 20px"
        @click="getTabelList()"
      >
        <i class="el-icon-search"></i>
      </el-button>
    </el-row>
    <el-table
      :data="tableData"
      style="width: 100%; margin-bottom: 8px"
      border=""
      :row-style="{ height: '60px' }"
      :cell-style="{ padding: '0', textAlign: 'center' }"
      :header-cell-style="{
        textAlign: 'center',
        backgroundColor: '#f5f7fa',
      }"
      @cell-dblclick="copyText"
    >
      <el-table-column prop="name" label="投诉机构"> </el-table-column>
      <el-table-column prop="systemName" label="产品终端"> </el-table-column>
      <el-table-column prop="content" label="内容描述"> </el-table-column>
      <el-table-column
        prop="enclosure"
        label="附件"
        show-overflow-tooltip
        width="200"
      >
      </el-table-column>
      <el-table-column prop="picUrl" label="投诉截图" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="remarks" label="备注"> </el-table-column>
      <el-table-column prop="time" label="预计完成时间"> </el-table-column>
      <el-table-column label="操作" width="200">
        <template v-slot="scope">
          <div class="ActionColumn"> 
            <el-link @click="Showeditdio(scope.row)" class="ActionColumn_h"  type="primary">编辑</el-link>
            <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              icon-color="red"
              title="确定转无效吗？"
              @confirm="OKdelstaff(scope.row)"
            >
              <el-link class="ActionColumn_h" slot="reference"  type="primary">转无效</el-link>
            </el-popconfirm>
            <el-link  class="ActionColumn_h" @click="Showtask(scope.row)" type="primary">绑定任务</el-link>
          </div>
          <!-- <div class="el-rowString">
            <el-button
              type="primary"
              icon="el-icon-edit"
              size="small"
              @click="Showeditdio(scope.row)"
              plain
              style="margin-right: 8px"
              >编辑</el-button>
            <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              icon-color="red"
              title="确定转无效吗？"
              @confirm="OKdelstaff(scope.row)">
              <el-button
                slot="reference"
                type="warning"
                icon="el-icon-refresh"
                size="small"
                plain
                style="margin-right: 8px"
                >转无效</el-button>
            </el-popconfirm>
            <el-button
              type="success"
              icon="el-icon-circle-plus-outline"
              size="small"
              plain
              @click="Showtask(scope.row)">绑定任务</el-button>
          </div> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div style="text-align: center">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="dataFrom.pageNum"
        :page-size="dataFrom.pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 编辑框 -->
    <el-drawer title="修改投诉" :visible.sync="addDialog" @close="closedrawer">
      <div>
        <div class="From_item">
          <div>投诉机构</div>
          <el-autocomplete
            v-model="state"
            :fetch-suggestions="querySearchAsync"
            placeholder="请输入内容"
            @select="handleSelect"
          ></el-autocomplete>
        </div>
        <div class="From_item">
          <div>产品终端</div>
          <el-select v-model="UserFromM.systemName" placeholder="请选择">
            <el-option
              v-for="(item, index) in systemNameList"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>

        <div class="From_item">
          <div>内容</div>
          <el-input
            type="textarea"
            :rows="2"
            v-model="UserFromM.content"
            placeholder="请输入内容"
            style="width: 70%"
          >
          </el-input>
        </div>

        <div class="From_item">
          <div>上传附件</div>
          <el-upload
            class="upload-demo"
            action=""
            :on-change="handleChange"
            :auto-upload="false"
            :on-remove="removeChange"
            :file-list="fileList"
            :limit="1"
            style="width: 70%"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip" style="width: 100%">
              支持文件：.ara .zip .doc .docx .pdf .jpg
            </div>
          </el-upload>
        </div>

        <div class="From_item">
          <div>上传截图</div>
          <el-upload
            class="upload-demo"
            action=""
            :on-change="handleChangeImg"
            :on-remove="removeChangeIMg"
            :file-list="fileListIMG"
            style="width: 70%"
            :auto-upload="false"
            :limit="9"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip" style="width: 100%">
              支持文件：.ara .zip .doc .docx .pdf .jpg
            </div>
          </el-upload>
        </div>

        <div class="From_item">
          <div>备注</div>
          <el-input
            type="textarea"
            :rows="2"
            v-model="UserFromM.remarks"
            placeholder="请输入内容"
            style="width: 70%"
          >
          </el-input>
        </div>
        <!-- </from> -->
        <div class="dialog-footer">
          <el-button @click="addDialogM = false">取 消</el-button>
          <el-button type="primary" @click="OKAdd()">确 定</el-button>
        </div>
      </div>
    </el-drawer>

    <!-- 选择工作项 -->
    <el-drawer title="绑定任务" :visible.sync="bindDialog">
      <div>
        <div class="From_item">
          <div>选择工作项</div>
          <el-select v-model="bindfrom.iterationId" placeholder="请选择">
            <el-option
              v-for="(item, index) in chIterationList"
              :key="index"
              :label="item.content"
              :value="item.iterationId"
            >
            </el-option>
          </el-select>
        </div>

        <!-- </from> -->
        <div class="dialog-footer">
          <el-button @click="bindDialog = false">取 消</el-button>
          <el-button type="primary" @click="OKbind()">确 定</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { brandList } from "@/api/api";

import {
  complaintPageList,
  iterationSystemList,
  iterationTypeList,
  complaintDel,
  searchIteration,
  complaintUpd,
  complaintToIteration,
} from "@/api/DevelopmentManagement";

import axios from "axios";
export default {
  data() {
    return {
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "18370969442",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ],
      status: "",
      // 文件
      fileList: [],
      total: 0,
      fileListIMG: [],
      dataFrom: {
        pageNum: 1,
        pageSize: 10,
        status: "0",
        systemName: "",
      },
      state: "",
      addDialog: false,
      taskDialog: false,
      bindDialog: false,
      UserFromM: {
        systemName: "",
        type: "",
        content: "",
        remarks: "",
        staffId: "",
        estimateTime: "",
        enclosure: "",
      },
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value1: "",
      systemNameList: [],
      TypeList: [],
      bindfrom: {
        complaintId: "",
        iterationId: "",
      },
      chIterationList: [],
      deldFalw: false,
      picUrldeldFalw: false,
    };
  },
  props: ["tabTypeIndex"],
  mounted() {
    this.dataFrom.status = this.tabTypeIndex;
    // //调用获取表格数据方法
    this.getTabelList();
    // this.areaData = area;
    this.getList();
  },
  methods: {
    async getList() {
      let res1 = await iterationTypeList({});
      // console.log(res1);
      this.TypeList = res1.data;
      let res = await iterationSystemList({});
      this.systemNameList = res.data;
      let data = await searchIteration({});
      this.chIterationList = data.data;
      console.log(this.chIterationList);
    },
    // 获取表格
    async getTabelList() {
      let res = await complaintPageList(this.dataFrom);
      this.tableData = res.data.list;
      this.total = parseInt(res.data.total);
      console.log(res);
      this.loadingTab = false;
    },
    handleSizeChange(newsize) {
      this.dataFrom.pageSize = newsize;
      // 重新获取数据
      this.getTabelList();
    },
    handleCurrentChange(newpage) {
      this.dataFrom.pageNum = newpage;
      // 重新获取数据
      this.getTabelList();
    },
    // 上传文件、
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
      console.log(this.fileList);
    },

    handleChangeImg(file, fileList) {
      this.fileListIMG = fileList.slice(-3);
      console.log(this.fileListIMG);
    },
    // 删除文件
    removeChange(file, fileList) {
      console.log(fileList);
      this.UserFromM.enclosure = "";

      this.deldFalw = true;
    },
    removeChangeIMg(file, fileList) {
      this.UserFromM.picUrl = "";
      this.picUrldeldFalw = true;
    },

    // 显示编辑弹窗
    Showeditdio(from) {
      this.addDialog = true;
      console.log(from);
      this.state = from.name;
      this.UserFromM = JSON.parse(JSON.stringify(from));
      this.fileList.push({
        name: from.enclosure.substring(from.enclosure.length - 10),
        url: from.enclosure,
      });
      this.fileListIMG.push({
        name: from.picUrl.substring(from.picUrl.length - 10),
        url: from.picUrl,
      });
    },

    // 显示任务弹框
    Showtask(from) {
      this.bindDialog = true;
      console.log(from);
      this.bindfrom.complaintId = from.complaintId;
    },

    closedrawer() {
      console.log(123);
      this.fileList = [];
      this.fileListIMG = [];
    },

    // 确定编辑
    async OKAdd() {
      // console.log("确定编辑");
      if (this.deldFalw) {
        console.log(1);
        let fileUrl = "";
        if (this.fileList && this.fileList.length) {
          let param = new FormData();
          param.append("file", this.fileList[0].raw);
          const fileUrlData = await axios.post(
            "https://api.jamboxsys.com/jambox/ass/file/upLoad",
            param,
            { headers: { "Content-Type": "multipart/form-data" } }
          );
          fileUrl = fileUrlData.data.data.url;
          console.log(fileUrl);
        }
        this.UserFromM.enclosure = fileUrl;
      }
      if (this.picUrldeldFalw) {
        let fileUrlIMG = "";
        if (this.fileListIMG && this.fileListIMG.length) {
          let param = new FormData();
          param.append("file", this.fileListIMG[0].raw);
          const fileUrlData = await axios.post(
            "https://api.jamboxsys.com/jambox/ass/file/upLoad",
            param,
            { headers: { "Content-Type": "multipart/form-data" } }
          );
          fileUrlIMG = fileUrlData.data.data.url;
          console.log(fileUrlIMG);
        }
        this.UserFromM.picUrl = fileUrlIMG;
      }
      let res = await complaintUpd(this.UserFromM);
      if (res.status === "success") {
        this.$message.success("修改成功");
      } else {
        this.$message.error("修改失败");
      }
      this.addDialog = false;
      this.getTabelList();
    },

    // 确定添加任务
    OKtask() {
      console.log("确定添加任务");
    },

    // 确定转无效
    async OKdelstaff(from) {
      // console.log(from);
      let res = await complaintDel({ complaintId: from.complaintId });
      if (res.status === "success") {
        this.$message.success("修改成功");
      } else {
        this.$message.error("修改失败");
      }
      this.getTabelList();
    },

    // 确定绑定
    async OKbind() {
      console.log(this.bindfrom);
      let res = await complaintToIteration(this.bindfrom);
      if (res.status === "success") {
        this.$message.success("绑定成功");
      } else {
        this.$message.error("绑定失败");
      }
      this.bindDialog = false;
      this.getTabelList();
    },

    // 品牌校区搜索
    async querySearchAsync(queryString, cb) {
      const brandDataList = await brandList({ remarks: queryString });
      let restaurants = [];
      for (let a = 0; a < brandDataList.data.length; a++) {
        restaurants.push({
          id: brandDataList.data[a].id,
          value: brandDataList.data[a].name,
        });
      }
      let results = queryString
        ? restaurants.filter(this.createStateFilter(queryString))
        : restaurants;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(results);
      }, 3000 * Math.random());
    },

    createStateFilter(queryString) {
      return (state) => {
        return (
          state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },

    // 选择品牌
    async handleSelect(item) {
      this.dataFrom.brandId = item.id;
      console.log(this.dataFrom.brandId);
    },
    // 复制内容
    copyText(row, column, cell, event) {
      // 双击复制
      let save = function (e) {
        e.clipboardData.setData("text/plain", event.target.innerText);
        e.preventDefault(); //阻止默认行为
      };
      console.log(save);
      document.addEventListener("copy", save); //添加一个copy事件
      document.execCommand("copy"); //执行copy方法
      this.$message({ message: "复制成功", type: "success" }); //提示
    },
  },
};
</script>

<style scoped>
.From_item {
  width: 90%;
  height: 70rpx;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.From_item div {
  width: 30%;
  display: flex;
  flex-wrap: wrap;
}
.From_item .el-input,
.From_item .el-select,
.From_item .el-upload,
.From_item .el-autocomplete,
.From_item .el-upload,
.From_item .el-dialog {
  width: 70%;
}

.From_item .From_item-imglist {
  display: flex;
  flex-wrap: wrap;
  width: 70%;
}

.From_item-imglist .avtar-upIMG .el-upload {
  width: 50px;
  height: 50px;
  display: inline-block;
}

.dialog-footer {
  text-align: center;
  margin-top: 40px;
}
</style>