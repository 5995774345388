import COS from 'cos-js-sdk-v5';
import request from '@/api/request';
import nprogress from "nprogress";

const bucket = 'dataresource-1302318474'
const region = 'ap-guangzhou'

const cos = new COS({
  // getAuthorization 必选参数
  getAuthorization: function (options, callback) {
    // 异步获取临时密钥
    // 服务端 JS 和 PHP 例子：https://github.com/tencentyun/cos-js-sdk-v5/blob/master/server/
    // 服务端其他语言参考 COS STS SDK ：https://github.com/tencentyun/qcloud-cos-sts-sdk
    // STS 详细文档指引看：https://cloud.tencent.com/document/product/436/14048
    request({
      url: '/oam/file/oss/security',
      method: 'GET'
    }).then(res => {
      const {
        status,
        data 
      } = res;
      if (status == "success") {
        callback({
          TmpSecretId: data.credentials.tmpSecretId,
          TmpSecretKey: data.credentials.tmpSecretKey,
          SecurityToken: data.credentials.sessionToken,
          // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
          StartTime: data.startTime, // 时间戳，单位秒，如：1580000000
          ExpiredTime: data.expiredTime, // 时间戳，单位秒，如：1580000000
          ScopeLimit: true, // 细粒度控制权限需要设为 true，会限制密钥只在相同请求时重复使用
        });
      } else {
        console.error("cos密钥获取失败！")
      }
    })
  }
});


const putObject = (fileObject) => {
  console.log(fileObject);
  nprogress.start();
  return new Promise((resolve, reject) => {
    const filename = fileObject.uid;
    const fileSplits = fileObject.name.split('.');
    const fileType = fileSplits[fileSplits.length - 1];
    cos.putObject({
      Bucket: bucket,
      Region: region,
      Key: `${filename}.${fileType}`,
      StorageClass: 'STANDARD',
      // Body: fileObject, // 上传文件对象
      Body:fileObject.raw
    }, function (err, data) {
      nprogress.done();
      // console.log(err);
      // console.log(data);
      if (err) {
        reject(err);
      } else {
        resolve({
          status: "success",
          data: {
            "url": `http://${data.Location}`,
            "resourceType": fileType
          },
          msg: "上传成功"
        });
      }
    });
  })

}

const putObjectHttp = (fileObject) => {
  console.log(fileObject);
  nprogress.start();
  return new Promise((resolve, reject) => {
    const filename = fileObject.uid;
    const fileSplits = fileObject.name.split('.');
    const fileType = fileSplits[fileSplits.length - 1];
    cos.putObject({
      Bucket: bucket,
      Region: region,
      Key: `${filename}.${fileType}`,
      StorageClass: 'STANDARD',
      Body: fileObject, // 上传文件对象
      // Body:fileObject.raw
    }, function (err, data) {
      nprogress.done();
      // console.log(err);
      // console.log(data);
      if (err) {
        reject(err);
      } else {
        resolve({
          status: "success",
          data: {
            "url": `http://${data.Location}`,
            "resourceType": fileType
          },
          msg: "上传成功"
        });
      }
    });
  })

}

export {
  putObject,
  putObjectHttp
}