<template>
  <!-- 需求记录 -->
  <el-card>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane
        v-for="item in tabsList"
        :label="item.name"
        :name="item.value"
        :key="item.value"
      ></el-tab-pane>
    </el-tabs>
    <p class="pTip">*Tip: A级/30天，B级/21天，C级/14天，D级/7天，E级/3天，F级/1天</p>
    <div v-loading="loadingTab">
      <div
        style="width: 95%; margin: 0 auto"
        v-show="activeName == 0 || activeName == 1"
        >
        <el-row style="margin-bottom: 15px; display: flex; align-items: center">
          <div style="flex: 1">
            <span style="margin-right: 40px">待处理</span>
            <!-- <el-checkbox v-model="checked" @change="tigChange">已标记</el-checkbox> -->
            <span style="margin-right: 10px">需求筛选</span>
            <el-select
              v-model="viewActiveFrom.mark"
              placeholder="请选择"
              @change="changeMark"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <span style="margin-left: 10px">校区搜索</span>
            <!-- <el-input  v-model="viewActiveFrom.remarks"></el-input> -->
            <el-input
              v-model="viewActiveFrom.remarks"
              placeholder="请输入校区搜索"
              style="width: 180px; margin-left: 10px"
            ></el-input>
            <el-button
              style="background-color: #5e6cf4; color: #fff; margin-left: 10px"
              @click="getreviewActivities()"
              ><i class="el-icon-search"></i>
            </el-button>
          </div>
          <el-button
            @click="formShowClick"
            type="primary"
            style="background-color: #5e6cf4"
            >添加</el-button
          >
        </el-row>
        <!-- 表格 -->
        <el-table
          :data="tableData"
          style="width: 100%"
          border
          :row-class-name="tableRowClassName"
          :cell-style="{ textAlign: 'center' }"
          :header-cell-style="{
            textAlign: 'center',
            backgroundColor: '#f5f7fa',
          }"
          @cell-dblclick="copyText"
        >
          <el-table-column label="标记" width="50" fixed>
            <template slot-scope="scope">
              <i
                class="el-icon-star-on"
                style="color: #ffa631; font-size: 25px"
                v-if="scope.row.mark !== 0"
                @click="signConfirm(scope.row)"
              ></i>
              <i
                class="el-icon-star-off"
                style="font-size: 20px"
                v-else
                @click="signConfirm(scope.row)"
              ></i>
            </template>
          </el-table-column>
          <el-table-column
            prop="brandName"
            label="品牌"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="mechanism"
            label="校区名称"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="type"
            label="类型"
            width="90"
          ></el-table-column>
          <el-table-column
            prop="grade"
            label="等级"
            width="90"
          ></el-table-column>
          <el-table-column
            prop=""
            label="需求等级"
            show-overflow-tooltip
          ></el-table-column>

          <el-table-column
            prop="content"
            label="需求内容"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="fileUrl"
            label="需求文件"
            show-overflow-tooltip
          ></el-table-column>

          <el-table-column
            prop="creationTime"
            label="提出时间"
            width="140"
          ></el-table-column>
          <el-table-column
            prop="iterationStatus"
            label="领取状态"
            width="140"
          ></el-table-column>
          <el-table-column
            prop="chargeName"
            label=" 领取人"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="creTime"
            label="领取时间"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="estimateTime"
            label="上线时间"
            width="120"
          ></el-table-column>

          <el-table-column
            prop="acceptanceName"
            label="接收人"
            width="120"
          ></el-table-column>
          <el-table-column label="操作" width="120" fixed="right">
            <template v-slot="scope">
              <div class="ActionColumn">
                <!-- <el-popconfirm
                  confirm-button-text="确定"
                  cancel-button-text="取消"
                  icon-color="red"
                  :title="`确定${scope.row.mark !== 0 ? '取消':''}标记该需求？`"
                  @confirm="signConfirm(scope.row)" >
                  <el-link  class="ActionColumn_h" slot="reference" type="primary">标记</el-link>
                </el-popconfirm> -->
                <!-- <el-popconfirm
                  confirm-button-text="确定"
                  cancel-button-text="取消"
                  icon-color="red"
                  title="确定将该需求状态更新为已解决？"
                    @confirm="solvedConfirm(scope.row)" >
                  <el-link  class="ActionColumn_h" slot="reference" type="primary">已解决</el-link>
                </el-popconfirm> -->
                <el-link
                  class="ActionColumn_h"
                  type="primary"
                  @click="Urgent(scope.row)"
                  >加急</el-link
                >
                <el-link
                  class="ActionColumn_h"
                  type="primary"
                  @click="grade(scope.row)"
                  >评级</el-link
                >
              </div>

              <!-- <div class="ActionColumn" v-if="userInfo.staffId === parseInt(scope.row.creationBy) || userInfo.jurisdiction"> 
                <el-link class="ActionColumn_h"   type="primary" @click="openEditdrawer(scope.row)">编辑</el-link>
                <el-popconfirm
                  confirm-button-text="确定"
                  cancel-button-text="取消"
                  icon-color="red"
                  title="确定删除该需求记录吗？"
                    @confirm="dutyDelConfirm(scope.row)" >
                  <el-link  class="ActionColumn_h" slot="reference" type="primary" >删除</el-link>
                </el-popconfirm>
              </div> -->
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          style="margin-top: 18px"
          @size-change="pageSizeChange"
          @current-change="getreviewActivities"
          :current-page.sync="viewActiveFrom.pageNum"
          :page-sizes="[3, 5, 8, 10]"
          :page-size="viewActiveFrom.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
      <div
        style="width: 95%; margin: 0 auto"
        v-show="activeName == 0 || activeName == 2"
      >
        <el-row
          style="
            margin-bottom: 15px;
            display: flex;
            align-items: center;
            width: 100%;
            margin-top: 10px; ">
          <span>已解决</span>
        </el-row>
        <!-- 表格 -->
        <el-table
          :data="tableData1"
          style="width: 100%"
          border
          :cell-style="{ textAlign: 'center' }"
          :header-cell-style="{
            textAlign: 'center',
            backgroundColor: '#f5f7fa',
          }"
          @cell-dblclick="copyText"
        >
          <el-table-column
            prop="brandName"
            label="品牌"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="mechanism"
            label="校区名称"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="type"
            label="类型"
            width="90"
          ></el-table-column>
          <el-table-column
            prop="content"
            label="需求内容"
            width="200"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="fileUrl"
            label="需求文件"
            width="200"
          ></el-table-column>
          <el-table-column
            prop="creationTime"
            label="提出时间"
            width="140"
          ></el-table-column>

          <el-table-column
            prop="grade"
            label="等级"
            width="140"
          ></el-table-column>
          <el-table-column label="签约图片" width="120">
            <template slot-scope="scope">
              <el-image
                style="width: 90px; height: 60px"
                :src="scope.row.busUrl"
                fit="cover"
                :previewSrcList="[scope.row.signPic]"
              >
              </el-image>
            </template>
          </el-table-column>
          <el-table-column prop="signUrl" label="签约链接" width="120">
            <template slot-scope="scope">
              <el-image
                style="width: 90px; height: 60px"
                :src="scope.row.busUrl"
                fit="cover"
                :previewSrcList="[scope.row.signUrl]"
              >
              </el-image>
            </template>
          </el-table-column>
          <el-table-column
            prop="payment"
            label="预付金额"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="price"
            label="应付金额"
            width="120"
          ></el-table-column>

          <el-table-column
            prop="chargeName"
            label=" 领取人"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="creTime"
            label="领取时间"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="estimateTime"
            label="上线时间"
            width="120"
          ></el-table-column>

          <el-table-column
            prop="acceptanceName"
            label="接收人"
            width="120"
          ></el-table-column>
          <el-table-column
            prop="lastTime"
            label="处理时间"
            width="120"
            fixed="right"
          ></el-table-column>
          <el-table-column
            prop="handleName"
            label="处理人"
            width="120"
            fixed="right"
          ></el-table-column>
          <el-table-column
            prop="timeDifference"
            label="处理用时"
            width="120"
            fixed="right"
          ></el-table-column>
          <el-table-column
            prop="remarks"
            label="备注"
            width="200"
            show-overflow-tooltip
            fixed="right"
          ></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          style="margin-top: 18px"
          @size-change="pageSizeChange1"
          @current-change="getreviewActivities1"
          :current-page.sync="viewActiveFrom1.pageNum"
          :page-sizes="[3, 5, 8, 10]"
          :page-size="viewActiveFrom1.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total1"
        >
        </el-pagination>
      </div>
    </div>

    <el-drawer
      title="需求"
      :visible.sync="drawer"
      direction="rtl"
      :size="this.$equipment() ? '100%' : '30%'"
      @close="closedrawer"
    >
      <el-form
        ref="form"
        :model="form"
        label-width="80px"
        label-position="left"
        style="padding: 0 30px"
      >
        <el-form-item label="选择品牌">
          <el-autocomplete
            v-model="state"
            :disabled="form.demandId !== undefined"
            :fetch-suggestions="querySearchAsync"
            placeholder="请选择品牌"
            @select="handleSelect"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="选择校区">
          <el-select
            :disabled="form.demandId !== undefined"
            v-model="form.mechanismId"
            placeholder="请选择校区"
          >
            <el-option
              v-for="item in mechanismDataList"
              :key="item.mechanismId"
              :label="item.mechanism"
              :value="item.mechanismId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择类型">
          <el-select v-model="form.type" placeholder="请选择类型">
            <el-option
              v-for="item in typeList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="需求内容">
          <el-input type="textarea" v-model="form.content"></el-input>
        </el-form-item>
        <el-form-item label="上传文件">
          <el-upload
            class="upload-demo"
            drag
            action=""
            :on-change="handleChange"
            :on-remove="removeChange"
            :auto-upload="false"
            :file-list="fileList"
            :limit="1"
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
            <div class="el-upload__tip" slot="tip">
              只能上传1个文件, 且不超过500kb
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="formAddClick">确定</el-button>
          <el-button @click="formHiedClick">取消</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>

    <el-dialog title="加急需求" :visible.sync="UrgentStatus" width="900">
      <div class="From_item">
        <div>签约二维码</div>
        <el-image
          style="width: 100px"
          :src="itemFrom.signUrl"
          :preview-src-list="[itemFrom.signUrl]"
          v-if="itemFrom.signUrl"
        >
        </el-image>
        <label v-else> 需求暂未评级</label>
      </div>
      <div class="From_item">
        <div>签约图片</div>
        <el-image
          style="width: 100px"
          :src="itemFrom.signAgree"
          :preview-src-list="[itemFrom.signAgree]"
          v-if="itemFrom.signAgree"
        >
        </el-image>
        <label v-else> 暂未签约</label>
      </div>
      <div class="From_item">
        <div>签名图片</div>
        <el-image
          style="width: 100px"
          :src="itemFrom.signPic"
          :preview-src-list="[itemFrom.signPic]"
          v-if="itemFrom.signPic">
        </el-image>
        <label v-else> 暂未签约</label>
      </div>
      <div class="From_item">
        <div>需求等级</div>
        <b>{{ itemFrom.grade || '待评级'}}</b>
      </div>
      <div class="From_item">
        <div>预付金额</div>
        <label> ￥{{ itemFrom.payment }}</label>
      </div>
      <div class="From_item">
        <div>应付金额</div>
        <label> ￥{{ itemFrom.price }}</label>
      </div>
    </el-dialog>

    <el-dialog title="需求评级" :visible.sync="gradeStatus" width="900">
      <p class="pTip" style="margin-bottom: 15px">
        *Tip: A级/30天，B级/21天，C级/14天，D级/7天，E级/3天，F级/1天
      </p>

      <div class="From_item">
        <div>需求内容</div>
        <p style="width: 70%; padding-left: 20px">
          {{ gradeFrom.content }}
        </p>
      </div>
      <div class="From_item">
        <div>需求等级</div>
        <el-select
          v-model="gradeFrom.grade"
          placeholder="请选择等级"
          style="margin-left: 20px"
          clearable
        >
          <el-option
            v-for="(item, index) in gradeList"
            :key="index"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <div class="dialog-footer">
        <el-button @click="gradeStatus = false">取 消</el-button>
        <el-button type="primary" @click="OKgrade()">确 定</el-button>
      </div>
    </el-dialog>


  </el-card>
</template>

<script>
import {
  demandPageList,
  demandTypeList,
  brandList,
  mechanismPageList,
  demandAdd,
  demandStatusChange,
  demandDel,
} from "@/api/api";
import { apis } from "@/api/DemandRecords";
import axios from "axios";

import { mapGetters } from "vuex";
import { putObject } from "@/util/cos-helper";


export default {
  data() {
    return {
      // 标签页
      tabsList: [
        { value: "0", name: "总  览" },
        { value: "1", name: "待处理" },
        { value: "2", name: "已解决" },
      ],
      activeName: "0",
      loadingTab: false,
      viewActiveFrom: {
        pageNum: 1,
        pageSize: 5,
        status: 0,
        mark: -1,
        remarks: "",
      },
      tableData: [],
      total: 0, // 表格总数据
      loadingTab1: false,
      viewActiveFrom1: {
        pageNum: 1,
        pageSize: 5,
        status: 1,
      },
      tableData1: [],
      total1: 0, // 表格总数据

      drawer: false,
      restaurants: [],
      state: "",
      timeout: null,
      mechanismDataList: [],
      typeList: [],
      fileList: [],
      form: {
        mechanismId: "",
        type: "",
        content: "",
        fileUrl: "",
      },
      checked: false,
      deldFalw: false,
      options: [
        { value: -1, label: "全部" },
        { value: 1, label: "已标记" },
        { value: 0, label: "未标记" },
      ],
      Id: "",
      // 加急弹框
      UrgentStatus: false,
      itemFrom: {},

      // 需求等级
      gradeList: ["A", "B", "C", "D", "E","F"],
      gradeStatus: false,
      gradeFrom: {
        grade: "",
        content: "",
      },
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    this.getreviewActivities();
    this.getreviewActivities1();
    this.gettypeList();
    console.log(this.userInfo);
  },

  methods: {
    // 列表
    pageSizeChange(val) {
      this.viewActiveFrom.pageNum = 1;
      this.viewActiveFrom.pageSize = val;
      this.getreviewActivities();
    },
    async getreviewActivities(val) {
      this.loadingTab = true;
      const dataLsit = await demandPageList(this.viewActiveFrom);
      console.log(dataLsit);
      this.tableData = dataLsit.data.list;
      this.total = parseInt(dataLsit.data.total);
      this.loadingTab = false;
    },
    pageSizeChange1(val) {
      this.viewActiveFrom1.pageNum = 1;
      this.viewActiveFrom1.pageSize = val;
      this.getreviewActivities1();
    },

    // 标签切换
    handleClick(tab, event) {
      console.log(this.activeName);
      let index = tab.index == 0 ? 3 : 10;
      this.viewActiveFrom.pageSize = index;
      this.viewActiveFrom.pageNum = 1;
      this.viewActiveFrom1.pageSize = index;
      this.viewActiveFrom1.pageNum = 1;
      // this.dataFrom2.pageSize = index;
      // this.dataFrom2.pageNum = 1;
      this.getreviewActivities();
      this.getreviewActivities1();
      this.activeName = tab.index;
    },
    tigChange(e) {
      console.log(e);
    },
    async getreviewActivities1(val) {
      this.loadingTab1 = true;
      const dataLsit = await demandPageList(this.viewActiveFrom1);
      console.log(dataLsit.data.list);
      this.tableData1 = dataLsit.data.list;
      this.total1 = parseInt(dataLsit.data.total);
      this.loadingTab1 = false;
    },
    // 售前 售后 技术
    async gettypeList() {
      const typeList = await demandTypeList({});
      this.typeList = typeList.data;
    },
    // 添加
    formShowClick() {
      this.restaurants = [];
      this.state = "";
      this.timeout = null;
      this.fileList = [];
      this.mechanismDataList = [];
      this.form = {
        mechanismId: "",
        type: "",
        content: "",
      };
      this.drawer = true;
      this.type = 0;
    },

    formHiedClick() {
      this.drawer = false;
    },

    // 加急需求
    Urgent(from) {
      this.itemFrom = JSON.parse(JSON.stringify(from));
      console.log(from);
      this.UrgentStatus = true;
    },

    // 需求评级
    grade(from) {
      this.itemFrom = JSON.parse(JSON.stringify(from));
      this.gradeFrom.content = this.itemFrom.content;
      this.gradeFrom.grade = this.itemFrom.grade;


      this.gradeStatus = true;
    },

    tableRowClassName({ row, rowIndex }) {
      if (row.signAgree) {
        return "warning-row";
      }
      return '';
    },

    async OKgrade() {
      let data = {
        demandId: this.itemFrom.demandId,
        grade: this.gradeFrom.grade,
      };
      if (data.grade == "" || data.grade == null) {
        this.$message.error("请选择等级");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      console.log(data);
      let res = await apis.demandGrade(data);
      if (res.status == "success") {
        this.$message.success("评级成功");
        this.gradeStatus = false;
        this.getreviewActivities();
      } else {
        this.$message.error(res.msg);
      }
      loading.close();
    },

    async formAddClick() {
      if (this.form.mechanismId === "") {
        this.$message.warning("选择品牌和校区");
        return;
      }
      if (this.form.type === "") {
        this.$message.warning("选择类型");
        return;
      }
      if (this.form.content === "") {
        this.$message.warning("输入需求内容");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      // 添加
      if (this.type == 0) {
        let fileUrl = "";
        console.log(this.fileList);
        // return
        if (this.fileList && this.fileList.length) {
          let res = await putObject(this.fileList[0]);
           fileUrl = res.data.url;

          // let param = new FormData();
          // param.append("file", this.fileList[0].raw);
          // const fileUrlData = await axios.post(
          //   "https://api.jamboxsys.com/jambox/ass/file/upLoad",
          //   param,
          //   { headers: { "Content-Type": "multipart/form-data" } }
          // );
          // fileUrl = fileUrlData.data.data.url;
        }
        const data = {
          mechanismId: this.form.mechanismId,
          type: this.form.type,
          content: this.form.content,
          fileUrl: fileUrl || "",
        };
        const dataAdd = await demandAdd(data);
        loading.close();
        if (dataAdd.status == "success") {
          this.$message.success("添加成功");
          this.drawer = false;
          this.getreviewActivities();
        } else {
          this.$message.error("添加失败");
        }
      }
      // 编辑
      if (this.type == 1) {
        if (this.deldFalw) {
          let fileUrl = "";
          if (this.fileList && this.fileList.length) {
            let param = new FormData();
            param.append("file", this.fileList[0].raw);
            const fileUrlData = await axios.post(
              "https://api.jamboxsys.com/jambox/ass/file/upLoad",
              param,
              { headers: { "Content-Type": "multipart/form-data" } }
            );
            fileUrl = fileUrlData.data.data.url;
            this.form.fileUrl = fileUrl;
          }
        }
        console.log(this.form);
        this.form.mechanismId = this.Id;
        console.log(this.form);
        let that = this;
        apis.OAMDemandEdit(this.form).then(
          await function (res) {
            if (res.status == "success") {
              that.$message.success("编辑成功");
              that.getreviewActivities();
            } else {
              that.$message.error("编辑失败");
            }
          }
        );
        that.drawer = false;
        loading.close();
      }
    },

    // 删除
    async dutyDelConfirm(item) {
      const dataDel = await demandDel({ demandId: item.demandId });
      if (dataDel.status == "success") {
        this.$message.success("删除成功");
        this.getreviewActivities();
      } else {
        this.$message.error("删除失败");
      }
    },

    // 改变状态
    async solvedConfirm(item) {
      const dataDel = await demandStatusChange({ demandId: item.demandId });
      if (dataDel.status == "success") {
        this.$message.success("更新成功");
        this.getreviewActivities();
        this.getreviewActivities1();
      } else {
        this.$message.error("更新失败");
      }
    },

    // 文件上传
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },

    // 删除文件
    removeChange(file, fileList) {
      this.fileList = [];
      this.deldFalw = true;
    },

    // 品牌校区搜索
    async querySearchAsync(queryString, cb) {
      const brandDataList = await brandList({ remarks: queryString });
      let restaurants = [];
      for (let a = 0; a < brandDataList.data.length; a++) {
        restaurants.push({
          id: brandDataList.data[a].id,
          value: brandDataList.data[a].name,
        });
      }
      // let results = queryString
      //   ? restaurants.filter(this.createStateFilter(queryString))
      //   : restaurants;
      let results = restaurants;

      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(results);
      }, 3000 * Math.random());
    },

    createStateFilter(queryString) {
      return (state) => {
        return (
          state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },

    async handleSelect(item) {
      const data = {
        pageNumber: 1,
        pageSize: 100,
        brandId: item.id,
      };
      const mechanismDataList = await mechanismPageList(data);
      this.mechanismDataList = mechanismDataList.data.list;
    },

    // 下载
    copyText(row, column, cell, event) {
      // 双击复制
      let save = function (e) {
        e.clipboardData.setData("text/plain", event.target.innerText);
        e.preventDefault(); //阻止默认行为
      };
      console.log(save);
      document.addEventListener("copy", save); //添加一个copy事件
      document.execCommand("copy"); //执行copy方法
      this.$message({ message: "复制成功", type: "success" }); //提示
    },

    // 筛选标记
    changeMark(e) {
      this.getreviewActivities();
    },

    // 标记
    async signConfirm(from) {
      console.log("标记", from);
      let postData = {
        id: from.demandId,
        mark: from.mark === 0 ? 1 : 0,
      };
      let that = this;
      apis.OAMDemandMark(postData).then(
        await function (res) {
          if (res.status == "success") {
            that.$message.success("更新成功");
            that.getreviewActivities();
          } else {
            that.$message.error("更新失败");
          }
        }
      );
    },

    // 打开编辑框
    openEditdrawer(from) {
      this.type = 1;
      console.log(from);
      this.drawer = true;
      this.form = JSON.parse(JSON.stringify(from));
      this.state = JSON.parse(JSON.stringify(from.brandName));
      this.form.mechanismId = JSON.parse(JSON.stringify(from.mechanism));
      this.Id = from.mechanismId;
      if (from.fileUrl) {
        this.fileList.push({
          name: from.fileUrl,
          url: from.fileUrl,
        });
      }
    },

    // 关闭
    closedrawer() {
      this.state = "";
      this.fileList = [];
      this.form.mechanismId = "";
    },
  },
};
</script>

<style scoped>
.From_item {
  width: 90%;
  height: 70rpx;
  margin: auto;
  display: flex;
  /* justify-content: space-between; */
  margin-bottom: 15px;
}
.From_itemradio {
  width: 100%;
  background-color: aqua;
  display: flex;
  text-align: left;
}
.From_item div {
  width: 30%;
  text-align: center;
}
.From_item .el-input,
.From_item .el-select,
.From_item .el-upload,
.From_item .From_item-editor {
  width: 70%;
}
.dialog-footer {
  text-align: center;
  margin-top: 40px;
}

.edit-btn {
  margin: 0 6px;
}
.edit-btn:hover {
  font-weight: 900;
}
.demo-drawe_buttpm {
  text-align: center;
}
.pTip {
  position: relative;
  z-index: 99;
  margin-bottom: 20px;
  color: red;
}
 ::v-deep.el-table .warning-row {
  background: oldlace ;
}
</style>