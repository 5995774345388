<template>
  <!-- 员工管理 -->
  <el-card>
    <el-row>
      <el-button
        style="background-color: #5e6cf4; color: #fff"
        @click="Showstaff()"
        >添加员工</el-button
      >
    </el-row>
    <el-table
      v-loading="tableLoading"
      :data="tableData"
      style="width: 100%; margin-bottom: 8px"
      border=""
      :row-style="{ height: '60px' }"
      :cell-style="{ padding: '0', textAlign: 'center' }"
      :header-cell-style="{
        textAlign: 'center',
        backgroundColor: '#f5f7fa',
      }"
    >
      <el-table-column prop="name" label="名称"> </el-table-column>
      <!-- <el-table-column prop="type" label="部门"> </el-table-column> -->
      <el-table-column prop="phone" label="手机号"> </el-table-column>
      <el-table-column prop="type" label="部门"> </el-table-column>

      <el-table-column prop="author" label="权限组"> </el-table-column>
      <el-table-column prop="plaintext" label="密码明文"> </el-table-column>
      <el-table-column prop="creationTime" label="创建时间"> </el-table-column>
      <el-table-column label="操作" width="150">
        <template v-slot="scope">
          <div class="ActionColumn"> 
            <el-link @click="ShowEditPassword(scope.row)" class="ActionColumn_h"  type="primary">修改密码</el-link>
            <el-link @click="Showstaff(scope.row)" class="ActionColumn_h"  type="primary">编辑</el-link>
          </div>
          <div class="ActionColumn"> 
            <el-link @click="ShowEditJurisdiction(scope.row)" class="ActionColumn_h"  type="primary">修改权限</el-link>
            <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                icon-color="red"
                title="确定删除吗？"
                @confirm="OKdelstaff(scope.row)" >
              <el-link  class="ActionColumn_h" slot="reference" type="primary">删除</el-link>
            </el-popconfirm>
          </div>
          <!-- </div> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div style="text-align: center">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="dataFrom.current"
        :page-size="dataFrom.size"
        layout="prev, pager, next, jumper"
        :total="dataFrom.total"
      >
      </el-pagination>
    </div>
  
    <!-- 添加/编辑弹窗 -->
    <el-drawer :title="isAdd ? '编辑员工' : '添加员工'" :visible.sync="addDialog">
      <div class="From_item" style="margin-bottom: 10rpx">
        <div>名称</div>
        <el-input v-model="UserFrom.name" placeholder="请输入内容"></el-input>
      </div>
      <div class="From_item">
        <div>电话</div>
        <el-input v-model="UserFrom.phone" placeholder="请输入内容"></el-input>
      </div>
      <div class="From_item">
        <div>部门</div>
        <el-select v-model="UserFrom.type" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <!-- <div class="From_item">
        <div>权限组</div>
        <el-select v-model="UserFrom.jurisdiction" placeholder="请选择">
          <el-option
            v-for="item in getJurisdictionList"
            :key="item.groupId"
            :label="item.groupName"
            :value="item.groupId"
          >
          </el-option>
        </el-select>
      </div> -->
      <!-- </from> -->
      <div class="dialog-footer">
        <el-button @click="addDialog = false">取 消</el-button>
        <el-button type="primary" @click="OKAdd()"
          >确 定</el-button
        >
      </div>
    </el-drawer>

    <!-- 修改密码弹窗 -->
    <el-dialog
      title="提示"
      :visible.sync="editPassword"
      width="30%"
    >
      <div class="From_item" style="margin-bottom: 10rpx">
        <div>新密码</div>
        <el-input v-model="UserFrom.plaintext" placeholder="请输入新密码"></el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editPassword = false">取 消</el-button>
        <el-button type="primary" @click="OKEditPassword()">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 修改权限弹窗 -->
    <el-dialog title="修改权限" :visible.sync="editJurisdiction" width="30%">
      <div class="From_item" style="margin-bottom: 10rpx">
        <div>权限组</div>
        <el-select v-model="UserFrom.authorId" placeholder="请选择">
          <el-option
            v-for="item in getJurisdictionList"
            :key="item.groupId"
            :label="item.groupName"
            :value="item.groupId"
          >
          </el-option>
        </el-select>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editJurisdiction = false">取 消</el-button>
        <el-button type="primary" @click="OKJurisdiction()"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import { apis } from '../../api/EmployeeManagement'

export default {
  data() {
    return {
      tableData: [],
      dataFrom: {
        current: 1,
        size: 10,
        total: 0,
      },
      options: [
        {
          value: "iteration",
          label: "开发部",
        },
        {
          value: "afterSales",
          label: "售后部",
        },
        {
          value: "preSales",
          label: "市场部",
        },
        {
          value: "other",
          label: "其他部门",
        },
      ],
      getJurisdictionList: [], //权限列表
      UserFrom: {
        staffId: null,
        name: "",
        phone: "",
        department: "",
        jurisdiction: "",
      },
      addDialog: false,
      editPassword: false,
      editJurisdiction: false,
      delDialog: false,
      NEWpassword: "",
      groupId: '',
      tableLoading: false, //表格loading
    };
  },
  computed: {
    isAdd() {
      return !this.UserFrom.staffId;
    }
  },
  created(){
    this.getCurrentPage(); //请求分页数据
    this.getJurisdictionData(); // 获取权限列表
  },
  methods: {
     // 获取表格数据
    getTableData(){
      apis.getUserData().then(res => {
        console.log('getData', res)
        this.tableData = res;
        this.getCurrentPage(); //请求分页数据
      })
    },
    // 获取权限列表数据
    getJurisdictionData(){
      apis.getPerData().then(res => {
        this.getJurisdictionList = res;
      })
    },
    // 监听pageSize事件
    handleSizeChange(newsize) {
      console.log('newsize', newsize)
      // this.dataFrom.current = newsize;
      // 重新获取数据
      // this.getTableList(0);
    },
    handleCurrentChange(newpage) {
      // console.log('newsize', newsize)
      this.dataFrom.current = newpage;
      // 重新获取数据
      this.getCurrentPage();
    },

    // 显示员工编辑弹窗
    Showstaff(from) {
      this.addDialog = true;
      this.UserFrom = from || {}
      console.log('from数据' ,from);
    },

    // 显示修改密码弹出
    ShowEditPassword(from) {
      this.editPassword = true;
      this.UserFrom = JSON.parse(JSON.stringify(from))  || {};
    },

    // 显示修改权限框
    ShowEditJurisdiction(from) {
      console.log('groupId', from)
      this.UserFrom = from || {};
      this.editJurisdiction = true;
      this.staffId = from.staffId
    },

    // 确定修改（添加）
    OKAdd() {
      let type;
      if(this.isAdd){
        // 添加
        for(let key in this.UserFrom){
          if(!this.UserFrom[key]) return this.$message.error('请完善信息')
        }
      }else{
        // 修改
        if(!this.UserFrom.phone && !this.UserFrom.name) return this.$message.error('请完善信息')
        type = 'PUT';
        // apis.addEmplUser(this.UserFrom).then(res => {
        //   if(res.status != 'success') return this.$message.error('操作失败')
        //   this.$message.success('操作成功')
        //   this.getJurisdictionData();
        // })
      }
      apis.addEmplUser(this.UserFrom, type).then(res => {
        if(res.status != 'success') return this.$message.error('操作失败')
        this.$message.success('操作成功')
        this.addDialog = this.editPassword = false;
        this.getTableData();
      })
    },

    OKEditPassword() {
      console.log("确定修改");
      this.OKAdd(); // 方法和编辑用户一致
    },

    // 确定修改权限
    OKJurisdiction(e){
      // console.log("确定修改权限", this.UserFrom.jurisdiction,  this.groupId);
      apis.putEmplUser(this.staffId, this.UserFrom.authorId).then(res => {
        if(res.status != 'success') return this.$message.error('修改失败')
        this.$message.success('修改成功')
        this.getTableData();
        this.editJurisdiction = false;
      })
    },

    // 删除员工
    OKdelstaff(from){
      apis.deleteEmplUser(from).then(res => {
        if(res.status != 'success') return this.$message.error('删除失败')
        this.$message.success('删除成功')
        this.getTableData();
      })
      console.log("删除员工");
    },
    // 获取当前分页
    getCurrentPage(){
      this.tableLoading = true;
      console.log('fenye chanshu', this.dataFrom)
      apis.getUserPage(this.dataFrom).then(res => {
        this.tableLoading = false;
        console.log('请求数据', res)
        this.dataFrom = {
          current: parseInt(res.current),
          size: res.size,
          total: res.total,
        }
        this.tableData = res.records;
      })
    }

  },
};
</script>

<style scoped>
.el-row {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 15px;
}
.el-rowString {
  width: 100%;
  height: 170rpx;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
}
.el-rowString div {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
.el-tooltip {
  text-align: center;
}

/* 添加表单样式 */
.From_item {
  width: 90%;
  height: 70rpx;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  /* background-color: aqua; */
}
.From_item div {
  width: 80px;
}
.From_item .el-input,
.From_item .el-select {
  width: 80%;
}
.dialog-footer {
  text-align: center;
}
</style>