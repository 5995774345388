<template>
  <!-- 待开发 -->
  <div>

    <el-row :gutter="10" style="display: flex; margin-bottom: 15px">
      <!-- <el-col :span="4"> -->
      <el-select v-model="dataFrom.systemName" placeholder="请选择终端" clearable  @change="getTabelList()">
        <el-option
          v-for="(item, index) in systemNameList"
          :key="index"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="dataFrom.staffId"
        placeholder="请选择负责人"
        style="margin-left: 20px"
        clearable  @change="getTabelList()"
      >
        <el-option
          v-for="(item, index) in staffIdList"
          :key="index"
          :label="item.name"
          :value="item.staffId"
        >
        </el-option>
      </el-select>
      <!-- </el-col>
        <el-col :span="2"> -->
      <el-button
        style="background-color: #5e6cf4; color: #fff; margin-left: 20px"
        @click="getTabelList()"
      >
        <i class="el-icon-search"></i>
      </el-button>
      <!-- </el-col> -->
      <!-- <el-col :span="10"> </el-col> -->
    </el-row>
    <el-table
      v-loading="tableLoading"
      :data="tableData"
      style="width: 100%; margin-bottom: 8px"
      border=""
      :row-style="{ height: '60px' }"
      :cell-style="{ padding: '0', textAlign: 'center' }"
      :header-cell-style="{
        textAlign: 'center',
        backgroundColor: '#f5f7fa',
      }"
      @cell-dblclick="copyText"
    >
      <el-table-column prop="statusTitle" label="开发阶段"> </el-table-column>
      <el-table-column prop="systemName" label="产品终端"> </el-table-column>
      <el-table-column prop="type" label="类型"> </el-table-column>
      <el-table-column prop="content" label="内容"> </el-table-column>
      <el-table-column prop="grade" label="等级"> </el-table-column>
      <el-table-column prop="costWei" label="耗费权重"> </el-table-column>

      <el-table-column prop="enclosure" label="附件" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="remarks" label="备注"> </el-table-column>
      <el-table-column prop="chargeName" label="负责人"> </el-table-column>
      
      <el-table-column prop="estimateTime" label="预计完成时间">
      </el-table-column>
      <el-table-column label="操作" width="110">
        <template v-slot="scope">
          <div class="ActionColumn"> 
            <el-link @click="ShowHandOverDialog(scope.row)" class="ActionColumn_h"  type="primary">转交</el-link>
            <el-link @click="Showrecord(scope.row)" class="ActionColumn_h"  type="primary">记录</el-link>
          </div>
          <!-- <div class="el-rowString">
            <el-button
              type="warning"
              icon="el-icon-refresh"
              size="small"
              plain
              @click="ShowHandOverDialog(scope.row)"
              >转交</el-button >
            <el-button
              type="success"
              icon="el-icon-tickets"
              size="small"
              plain
              style="margin-right: 8px"
              @click="Showrecord(scope.row)"
              >记录</el-button>
          </div> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div style="text-align: center">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="dataFrom.pageNum"
        :page-size="dataFrom.pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 转交弹窗 -->
    <el-drawer title="转交任务" :visible.sync="HandOverDialog" @close="closeDrawer">
      <!-- <div class="From_item">
        <div>开发阶段</div>
        <el-select v-model="HandOverFrom.department" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div> -->
      <div class="From_item">
        <div>负责人</div>
        <el-select v-model="HandOverFrom.staffId" placeholder="请选择">
          <el-option
            v-for="(item, index) in staffIdList"
            :key="index"
            :label="item.name"
            :value="item.staffId"
          >
          </el-option>
        </el-select>
      </div>
      <!-- <div class="From_item">
        <div>截止时间</div>
        <el-date-picker
          v-model="HandOverFrom.estimateTime"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期">
        </el-date-picker>
      </div> -->
      <div class="From_item">
        <div>转待开发</div>
        <div style="flex:1;">
          <el-checkbox v-model="status" @change="changeBOX"></el-checkbox>
        </div>
      </div>

      <div class="dialog-footer">
        <el-button @click="HandOverDialog = false">取 消</el-button>
        <el-button type="primary" @click="OKHandOver()">确 定</el-button>
      </div>
    </el-drawer>

    <!-- 记录弹窗 -->
    <el-dialog title="操作记录" :visible.sync="dialogrecord">
      <el-table
        :data="recordTable"
        max-height="400px"
        style="width: 100%; margin-bottom: 8px"
        border=""
        :row-style="{ height: '60px' }"
        :cell-style="{ padding: '0', textAlign: 'center' }"
        :header-cell-style="{ textAlign: 'center', backgroundColor: '#f5f7fa' }"
      >
        <el-table-column property="time" label="时间"></el-table-column>
        <el-table-column property="statusTitle" label="操作"></el-table-column>
        <el-table-column property="chargeName" label="操作人"></el-table-column>
        <el-table-column property="staffId" label="负责人"></el-table-column>
        <el-table-column
          property="estimateTime"
          label="预计完成时间"
        ></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import {
  iterationTypeList,
  dutyIterationList,
  iterationSystemList,
} from "@/api/DevelopmentManagement";

import {
  iterationPageList,
  iterationStatusChange,
  iterationRecordList,
} from "@/api/DevelopmentManagement";
export default {
  data() {
    return {
      tableData: [],
      gridData: [],
      // 修改表单
      UserFrom: {},
      addDialog: false,
      HandOverDialog: false,
      dialogrecord: false,
      dataFrom: {
        pageNum: 1,
        pageSize: 10,
        status: "",
        staffId: "",
        systemName: "",
      },
      // 文件
      fileList: [],
      total: 0,
      HandOverFrom: {
        status:false
      },
      status:false,
      staffIdList: [],
      recordTable: [],
      systemNameList:[],
      tableLoading:false,
    };
  },
  props: ["tabTypeIndex"],
  mounted() {
    console.log(this.HandOverFrom);

    this.dataFrom.status = this.tabTypeIndex;
    // //调用获取表格数据方法
    this.getTabelList();
    // this.areaData = area;
    this.getList();
  },
  methods: {
    async getList() {
      let res1 = await iterationTypeList({});
      // console.log(res1);
      this.TypeList = res1.data;
      let res2 = await dutyIterationList({});
      // console.log(res2);
      this.staffIdList = res2.data;
      let res3 = await iterationSystemList({});
      // console.log(res3);
      this.systemNameList = res3.data;
    },
    // 获取表格
    async getTabelList() {
      this.tableLoading = true;
      let res = await iterationPageList(this.dataFrom);
      this.tableData = res.data.list;
      this.total = parseInt(res.data.total);
      console.log(res);
      this.tableLoading = false;
    },
    handleSizeChange(newsize) {
      this.dataFrom.pageSize = newsize;
      // 重新获取数据
      this.getTabelList();
    },
    handleCurrentChange(newpage) {
      this.dataFrom.pageNum = newpage;
      // 重新获取数据
      this.getTabelList();
    },
    // 文件上传
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },
    // 编辑弹窗
    Showstaff(from) {
      console.log("编辑" + from);
      this.addDialog = true;
    },

    // 转交弹窗
    ShowHandOverDialog(from) {
      console.log(from);
      this.HandOverDialog = true;
      this.HandOverFrom = from;
    },

    // 记录弹窗
    async Showrecord(from) {
      this.dialogrecord = true;
      console.log(from);
      
      let res = await iterationRecordList({ iterationId: from.iterationId });
      console.log(res);
      this.recordTable = res.data;
    },

    // 提交编辑
    OKEdit() {
      console.log("提交编辑");
    },

    // 提交转交
    async OKHandOver() {
      // console.log("提交转交");
      this.HandOverFrom.status = this.status ? 0 : ''
      // console.log(this.HandOverFrom);
      let postData = {
        iterationId:this.HandOverFrom.iterationId,
        estimateTime:this.HandOverFrom.estimateTime,
        staffId:this.HandOverFrom.staffId
      }
      // return
      let res = await iterationStatusChange(postData);
      if (res.status === "success") {
        this.$message.success("转交成功");
      } else {
        this.$message.error("转交失败");
      }
      this.HandOverDialog = false;
      this.getTabelList();
      this.HandOverFrom.status = false
    },
    // 确定删除
    OKdelstaff(from) {
      console.log("确定删除" + from);
    },
    // 复制内容
    copyText(row, column, cell, event) {
      // 双击复制
      let save = function (e) {
        e.clipboardData.setData("text/plain", event.target.innerText);
        e.preventDefault(); //阻止默认行为
      };
      console.log(save);
      document.addEventListener("copy", save); //添加一个copy事件
      document.execCommand("copy"); //执行copy方法
      this.$message({ message: "复制成功", type: "success" }); //提示
    },
      
    changeBOX(e){
      // console.log(e);
      this.status = e
    },

    closeDrawer(){
      this.status = false
      // console.log(this.status);
    }
  },
};
</script>

<style scoped>
/* 添加表单样式 */
.From_item {
  width: 90%;
  height: 70rpx;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.From_item div {
  width: 30%;
}
.From_item .el-input,
.From_item .el-select {
  width: 70%;
}
.dialog-footer {
  text-align: center;
  margin-top: 50px;
}
</style>