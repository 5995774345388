<template>
  <!-- 已完成 -->
  <div>
    <div class="Financial-max">
      <div class="Financial-max-item">
        <div>搜索</div>
        <el-input placeholder="请输入内容" v-model="searchData.remarks" clearable></el-input>
      </div>
      <div class="Financial-max-item">
        <div>负责人</div>
        <el-select v-model="searchData.staffId" placeholder="请选择" clearable>
          <el-option
            v-for="item in personCharges"
            :key="item.name"
            :label="item.name"
            :value="item.staffId">
          </el-option>
        </el-select>
      </div>
      <div class="Financial-max-item">
        <el-button
          style="background-color: #5e6cf4; color: #fff;margin-left: 20px;"
          @click="getTableList(searchData)">
          <i class="el-icon-search"></i>
        </el-button>
      </div>
    </div>
     <!-- 表格 -->
     <el-table
      :data="tableData"
      style="width: 100%;margin-top: 10px;"
      border
      :cell-style="{ textAlign: 'center' }"
      :header-cell-style="{ textAlign: 'center', backgroundColor: '#f5f7fa' }"
      @cell-dblclick="copyText"
    >
      <el-table-column prop="brandName" label="机构名称"   width="200" ></el-table-column>
      <el-table-column prop="stage" label="支付套餐"   show-overflow-tooltip width="200"></el-table-column>
      <el-table-column prop="chargeName" label="负责人" width="200" ></el-table-column>
      <el-table-column prop="reason" label="购买理由" min-width="110"></el-table-column>
      <el-table-column prop="phone" label="营业执照" min-width="115">
        <template slot-scope="scope">
          <el-image
            style="width: 90px; height: 60px"
            :src="scope.row.busUrl"
            fit="cover" :previewSrcList="[scope.row.busUrl]">
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="picUrl" label="支付凭证" min-width="110" fixed="right"> 
        <template slot-scope="scope">
          <el-image
            style="width: 90px; height: 60px"
            :src="scope.row.picUrl"
            fit="cover" :previewSrcList="[scope.row.picUrl]">
          </el-image>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="invoiceStatus" label="是否需要开票" ></el-table-column> -->
      <el-table-column prop="dealRemarks" label="成交备注" show-overflow-tooltip fixed="right"></el-table-column>
      <el-table-column prop="mechanismList" label="绑定校区" show-overflow-tooltip fixed="right"></el-table-column>
      <el-table-column prop="remarks" label="财审备注" show-overflow-tooltip fixed="right"></el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      style="margin-top: 18px"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="paging.pageNum"
      :page-sizes="[8, 10, 15, 50]"
      :page-size="paging.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="paging.total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { apis } from '@/api/channelManagement'
export default {
  name: "Completed",
  data(){
    return{
      options: [],
      tableData: [],
      total:0,
      paging: {
        pageSize: 6, // 单页数量
        pageNum: 1, // 页码
        total: 0, // 总数
        status: 2,
      }, // 表格数据分页
      personCharges: [], // 负责人
      searchData: {}, // 搜索数据
    }
  },
  created(){
    this.getTableList();
    this.getStaffSaleSearch()
  },
  methods:{
    // 获取表格数据
    getTableList(remak = {}){
      this.tableLoading = true;
      apis.financePageList({...this.paging, ...remak}).then(res => {
        console.log('分页列表', res);
        const { data, msg , status} = res;
        this.tableLoading = false;
        if(status != 'success') return this.$message.error(msg)
        this.tableData = data.list;
        this.paging.total = parseInt(data.total);
      })
    },
    // 获取负责人列表
    getStaffSaleSearch(){
      apis.staffSaleSearch().then(res => {
        console.log('渠道人列表', res)
        this.personCharges = res.data;
      })
    },
    // 监听pageSize事件
    handleSizeChange(newsize) {
      this.paging.pageSize = newsize;
      // 重新获取数据
      this.getTableList(this.searchData)
    },

    handleCurrentChange(newpage) {
      this.paging.pageNum = newpage;
      // 重新获取数据
      this.getTableList(this.searchData)
    },
    copyText(row, column, cell, event) {
      // 双击复制
      let save = function (e) {
        e.clipboardData.setData("text/plain", event.target.innerText);
        e.preventDefault(); //阻止默认行为
      };
      console.log(save);
      document.addEventListener("copy", save); //添加一个copy事件
      document.execCommand("copy"); //执行copy方法
      this.$message({ message: "复制成功", type: "success" }); //提示
    },
  }
};
</script>


<style scoped>
 .Financial-max {
  width: 100%;
  display: flex;
}
.Financial-max-item {
  display: flex;
  align-items: center;
}
.Financial-max-item div {
  width: 60px;
  margin-right: 5px;
  text-align: center;
}
.Financial-max-item .el-input,
.Financial-max-item .el-select{
  width: 200px;
}
</style>