import Vue from 'vue'
import App from './App.vue'
import router from "./router/index" //导入路由
import store from './storage/index';



// ElementUI的导入和使用
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// 富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)


// 判断设备
Vue.prototype.$equipment = function () {
    let flag = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
    return flag;
}

// 自定义指令
/**
 * @description 防抖点
 */
Vue.directive("throttle", {
    bind: (el, binding) => {
        let throttleTime = binding.value || 800;//防抖时间
        let cbFun;
        el.addEventListener('click', event => {
            console.log('执行了')
            if (!cbFun) cbFun = setTimeout(() => {cbFun = null;}, throttleTime);
            else event && event.stopImmediatePropagation();
        }, true);
    },
    
});

Vue.config.productionTip = false;
Vue.use(ElementUI);

new Vue({
    render: h => h(App),
    router,
    store,
    beforeCreate() {

    }
}).$mount('#app')