<template>
  <div class="login_container">
    <div class="login_Maxbox">
      <h1>
        <img
          style="width: 130px"
          src="https://6a61-jambox-dance-student-1b5e4294ddf-1308699755.tcb.qcloud.la/oam.png?sign=2e489bbe2648c264dac8ccd43a1aae25&t=1657265698"
          alt=""
        />
      </h1>
      <!-- 登录盒子 -->
      <el-card class="login_box">
        <el-form
          ref="loginformRef"
          :model="loginFrom"
          :rules="loginFromRules"
          size="small"
        >
          <el-form-item prop="username">
            <el-input
              type="text"
              placeholder="username"
              v-model="loginFrom.loginNo"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              type="password"
              placeholder="password"
              v-model="loginFrom.passWord"
              @keyup.enter.native="submitForm()"
            >
            </el-input>
          </el-form-item>
          <el-form-item class="jizu">
            <span style="font-size: 10px">内部管理系统</span>
          </el-form-item>
          <el-form-item>
            <el-button class="primary" type="primary" @click="submitForm()"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
      </el-card>
      <p>果酱盒子</p>
    </div>
  </div>
</template>

<script>
import { Base64 } from "js-base64";
import { LoginApi } from "@/api/http";
import { REQUEST_URL } from "@/api/project.config";

export default {
  name: "LogiN",
  data() {
    return {
      // 登录表单
      loginFrom: {
        // loginNo: "18370969442",
        // passWord: "jambox9442",
        // loginNo: "17674106121",
        // passWord: "jambox6121",
        loginNo: "17676104112",
        passWord: "jambox4112", //超级管理员
        // loginNo: "",
        // passWord: "",
      },
      // 表单验证
      loginFromRules: {
        loginNo: [
          { required: true, message: "请输入登录名", trigger: "blur" },
          { min: 3, max: 11, message: "用户名长度在3-11之间", trigger: "blur" },
        ],
        passWord: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 15, message: "密码长度在6-15之间", trigger: "blur" },
        ],
      },
      // 记住密码
      remPassword: false,
    };
  },
  created() {
    console.log(this);
    if (REQUEST_URL === "https://test.cpopsz.com/test") {
      this.loginFrom.loginNo = "17676104112";
      this.loginFrom.passWord = "jambox4112";
    } else {
      //  if(REQUEST_URL === 'https://api.jamboxsys.com/jambox'){
      this.loginFrom.loginNo = "";
      this.loginFrom.passWord = "";
      // }else
    }
  },
  methods: {
    // 登录按钮
    async submitForm() {
      // let now = new Date();
      // if (now - this.lastTime > 5000) {
      // 判断表单验证是否通过validate
      this.$refs.loginformRef.validate(async (valid) => {
        // 表单验证是否通过
        if (!valid) return;
        // console.log(this.loginFrom);
        // Base64密码加密转码
        let str = JSON.parse(JSON.stringify(this.loginFrom.passWord));
        console.log(str);
        // this.loginFrom.passWord = Base64.encode(str);
        console.log(this.loginFrom.passWord);
        // let obj = {
        //   passWord:this.loginFrom.passWord,
        //   loginNo:this.loginFrom.
        // }
        // console.log(this.loginFrom.passWord);
        let postData={
          loginNo:this.loginFrom.loginNo,
          passWord:Base64.encode(str)
        }
        const res = await LoginApi(postData);
        if (res.status !== "success")
          return this.$message.error("账号或密码错误！！！");
        this.$message.success("登录成功！");
        sessionStorage.setItem("activepath", "/Welcomes");

        window.sessionStorage.setItem("token", res.data.token);
        this.$store.dispatch("loginSuccess", res.data);
        // 路由跳转
        this.$router.push("/Home");
      });
    },
  },
};
</script>

<style scoped>
.login_container {
  background-color: #5e6cf4;
  width: 100%;
  height: 100vh;
}
/* 中间定位盒子 */
.login_Maxbox {
  width: 800px;
  height: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* title文字 */
.login_Maxbox h1 {
  font-size: 50px;
  margin: 0;
  text-align: center;
  color: #fff;
}
/* 登录盒子 */
.login_Maxbox p {
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 80px;
  color: #fff;
  font-size: 13px;
}
.login_box {
  width: 320px;
  height: 290px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.el-form {
  margin-top: 30px;
}
.el-input {
  font-size: 14px;
}
.el-checkbox {
  font-size: 14px;
}
/* 记住密码样式 */
.jizu {
  margin: 0px 0 20px 8px;
  color: rgb(77, 74, 74);
}
.primary {
  width: 100%;
  background-color: #5e6cf4;
  border: 1px solid #5e6cf4;
  opacity: 0.9;
  font-size: 13px;
}
</style>