<template>
  <!-- 产品投诉 -->
  <el-card>
    <el-row :gutter="13">
      <el-col :span="22">
        <el-tabs v-model="status" @tab-click="handleClick">
          <el-tab-pane
            v-for="item in tabsList"
            :label="item.value"
            :name="item.id"
            :key="item.id"
          >
          </el-tab-pane>
        </el-tabs>
      </el-col>
      <el-col :span="2">
        <el-button
          style="background-color: #5e6cf4; color: #fff"
          @click="addDialogM = true"
          >添加投诉</el-button
        >
      </el-col>
    </el-row>

    <ProductComplaints ref="ProductComplaints" :tabTypeIndex="status" v-if="status == '0'" />
    <ResolvedVue :tabTypeIndex="status" v-if="status == '1'" />
    <ReceivedBUGVue :tabTypeIndex="status" v-if="status == '2'" />
    <InvalidComplaintVue :tabTypeIndex="status" v-if="status == '3'" />

    <!-- 添加/编辑弹窗 -->
    <el-drawer title="添加投诉" :visible.sync="addDialogM">
      <div>
        <div class="From_item">
          <div>投诉机构</div>
          <el-autocomplete
            v-model="state"
            :fetch-suggestions="querySearchAsync"
            placeholder="请输入内容"
            @select="handleSelect"
          ></el-autocomplete>
        </div>
        <div class="From_item">
          <div>产品终端</div>
          <el-select v-model="UserFromM.systemName" placeholder="请选择">
            <el-option
              v-for="(item, index) in systemNameList"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>

        <div class="From_item">
          <div>内容</div>
          <el-input
            type="textarea"
            :rows="2"
            v-model="UserFromM.content"
            placeholder="请输入内容"
            style="width: 70%"
          >
          </el-input>
        </div>

        <div class="From_item">
          <div>上传附件</div>
          <el-upload
            class="upload-demo"
            action=""
            :on-change="handleChange"
            :auto-upload="false"
            :file-list="fileList"
            :limit="1"
            style="width: 70%"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip" style="width: 100%">
              支持文件：.ara .zip .doc .docx .pdf .jpg
            </div>
          </el-upload>
        </div>

        <div class="From_item">
          <div>上传截图</div>
          <el-upload
            class="upload-demo"
            action=""
            :on-change="handleChangeImg"
            :file-list="fileListIMG"
            style="width: 70%"
            :auto-upload="false"
            :limit="9"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip" style="width: 100%">
              支持文件：.ara .zip .doc .docx .pdf .jpg
            </div>
          </el-upload>
        </div>

        <div class="From_item">
          <div>备注</div>
          <el-input
            type="textarea"
            :rows="2"
            v-model="UserFromM.remarks"
            placeholder="请输入内容"
            style="width: 70%">
          </el-input>
        </div>
        <!-- </from> -->
        <div class="dialog-footer">
          <el-button @click="addDialogM = false">取 消</el-button>
          <el-button type="primary" @click="OKAdd()">确 定</el-button>
        </div>
      </div>
    </el-drawer>
  </el-card>
</template>

<script>
import ProductComplaints from "./Product_table/ProductComplaints.vue";
import ReceivedBUGVue from "./Product_table/ReceivedBUG.vue";
import InvalidComplaintVue from "./Product_table/InvalidComplaint.vue";
import ResolvedVue from "./Product_table/Resolved.vue";

import { brandList } from "@/api/api";
import { complaintAdd, iterationSystemList } from "@/api/DevelopmentManagement";

import axios from "axios";

export default {
  data() {
    return {
      tabsList: [
        {
          id: "0",
          value: "产品投诉",
        },
        {
          id: "1",
          value: "已接收",
        },
        {
          id: "2",
          value: "已解决",
        },

        {
          id: "3",
          value: "无效投诉",
        },
      ],
      status: "",
      // 文件
      fileList: [],
      fileListIMG: [],
      // 添加表单
      UserFromM: {
        systemName: "",
        phone: "",
        data: "",
        brandId: "",
        enclosure: "",
        picUrl: "",
        content:''
      },
      // 弹窗
      addDialogM: false,
      state: "",
      dialogImageUrl: "",
      dialogVisible: false,
      systemNameList: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      let res = await iterationSystemList({});
      this.systemNameList = res.data;
    },
    handleClick(tab, event) {
      // 获取token
      const tokenstr = window.sessionStorage.getItem("token");
      // 如果不存在这个令牌的话就强制跳转到登录页面
      if (!tokenstr) return this.$router.push("/Login");
      this.status = tab.index;
    },
    // 上传文件、
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
      console.log(this.fileList);
    },
    // 上传图片
    handleChangeImg(file, fileList) {
      this.fileListIMG = fileList.slice(-3);
      console.log(this.fileListIMG);
    },
    async OKAdd() {
      if(this.UserFromM.systemName === ''){
        this.$message.error("请选择终端");
        return 
      }
      if(this.UserFromM.content === ''){
        this.$message.error("请输入投诉内容");
        return 
      }
      let fileUrl = "";
      let fileListIMG = "";
      const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      if (this.fileList && this.fileList.length) {
        let param = new FormData();
        param.append("file", this.fileList[0].raw);
        const fileUrlData = await axios.post(
          "https://api.jamboxsys.com/jambox/ass/file/upLoad",
          param,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        fileUrl = fileUrlData.data.data.url;
        console.log(fileUrl);
      }
      if (this.fileListIMG && this.fileListIMG.length) {
        let param = new FormData();
        param.append("file", this.fileListIMG[0].raw);
        const fileUrlData = await axios.post(
          "https://api.jamboxsys.com/jambox/ass/file/upLoad",
          param,
          { headers: { "Content-Type": "multipart/form-data" } }
        );
        fileListIMG = fileUrlData.data.data.url;
        console.log(fileListIMG);
      }
      this.UserFromM.enclosure = fileUrl;
      this.UserFromM.picUrl = fileListIMG;
      console.log(this.UserFromM);

      let res = await complaintAdd(this.UserFromM);
      loading.close();
      if (res.status === "success") {
        this.$message.success("添加成功");
      } else {
        this.$message.error("添加失败");
      }
      location.reload();
      console.log(res);
    },

    // 品牌校区搜索
    async querySearchAsync(queryString, cb) {
      const brandDataList = await brandList({ remarks: queryString });
      let restaurants = [];
      for (let a = 0; a < brandDataList.data.length; a++) {
        restaurants.push({
          id: brandDataList.data[a].id,
          value: brandDataList.data[a].name,
        });
      }
      let results = queryString
        ? restaurants.filter(this.createStateFilter(queryString))
        : restaurants;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(results);
      }, 3000 * Math.random());
    },

    createStateFilter(queryString) {
      return (state) => {
        return (
          state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },

    // 选择品牌
    async handleSelect(item) {
      this.UserFromM.brandId = item.id;
    },
    
  },
  components: {
    ProductComplaints,
    InvalidComplaintVue,
    ResolvedVue,
    ReceivedBUGVue,
  },
};
</script>

<style scoped>
.From_item {
  width: 90%;
  height: 70rpx;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.From_item div {
  width: 30%;
  display: flex;
  flex-wrap: wrap;
}
.From_item .el-input,
.From_item .el-select,
.From_item .el-upload,
.From_item .el-autocomplete,
.From_item .el-upload,
.From_item .el-dialog {
  width: 70%;
}

.From_item .From_item-imglist {
  display: flex;
  flex-wrap: wrap;
  width: 70%;
}

.From_item-imglist .avtar-upIMG .el-upload {
  width: 50px;
  height: 50px;
  display: inline-block;
}

.dialog-footer {
  text-align: center;
  margin-top: 40px;
}
</style>