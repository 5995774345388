import Vue from "vue"
import VueRouter from "vue-router"
import store from '../storage'

Vue.use(VueRouter)


// 路由组件的导入
import Login from "@/components/Login" //登录   
import Home from "@/components/Home"   //主界面


// 页面
import Welcomes from "@/pages/Welcome.vue"
import Test from "@/pages/test/Test"


import LterationRecord from "@/pages/LterationRecord.vue"
import InstitutionSetting from "@/pages/InstitutionSetting.vue"
import DataImporting from "@/pages/DataImporting.vue"
import DemandRecBrandDesignrds from "@/pages/BrandDesign.vue"
import DemandRecords from "@/pages/DemandRecords.vue"
import OperationalRecords from "@/pages/OperationalRecords.vue"
import MonthlyEvaluation from "@/pages/MonthlyEvaluation.vue"
import DutyRoster from "@/pages/DutyRoster.vue"

// 员工管理
import PermissionGroup from "@/pages/EmployeeManagement/PermissionGroup"
import EmployeeSettings from "@/pages/EmployeeManagement/EmployeeSettings"
import FunctionList from "@/pages/EmployeeManagement/FunctionList"

// 开发管理
import DevelopmentManagement from "@/pages/DevelopmentManagement/DevelopmentManagement"
import ProductComplaints from "@/pages/DevelopmentManagement/ProductComplaints"


// 工单记录
import recordPages from "@/pages/workOrder/recordPages"

// 查看合同
import Loohtpact from "@/pages/distributionChannel/Loohtpact"

// 日历
import calendar from "@/pages/DevelopmentManagement/calendar"


import BrandDesign from "@/pages/BrandDesign"

// 开发管理
import kfgl from "@/pages/DevelopmentManagement/DevelopmentManagement"
// 财务机构延期
import FinancialAudit from "@/pages/distributionChannel/FinancialAudit"

// 页面测试
import routerTest from "@/pages/distributionChannel/CustomerFollowUp.vue"

// 线索/KPI
import KPIpage from "@/pages/distributionChannel/clue_table/KPI-page"

export const routes = [
    { path: "/", redirect: "Login" },
    { path: '/Login', component: Login },
    { path: '/Test', component: Test },
    {
        path: '/Home', component: Home,
        redirect: "/Welcomes",
        children: [
            // 开了
            { path: "/Welcomes", component: Welcomes },
            // 工单记录
            { path: "/recordPages", component: recordPages },
            { path: "/BrandDesign", component: BrandDesign },
            // 查看合同
            { path: "/Loohtpact", component: Loohtpact },
            // 开发日历
            { path: "/calendar", component: calendar },
            // 开发管理
            { path: "/kfgl/DevelopmentManagement", component: kfgl },
            // 线索/KPI
            { path: "/KPIpage", component: KPIpage },

            // 财务机构延期
            { path: "/FinancialAudit", component: FinancialAudit },


            // pages/distributionChannel/CustomerFollowUp

            // 无路由时页 面测试
            { path: "/routerTest", component: routerTest },



            // { path: "/LterationRecord", component: LterationRecord },
            // { path: "/InstitutionSetting", component: InstitutionSetting },
            // { path: "/DataImporting", component: DataImporting },
            // { path: "/DemandRecBrandDesignrds", component: DemandRecBrandDesignrds },
            // { path: "/DemandRecords", component: DemandRecords },
            // { path: "/OperationalRecords", component: OperationalRecords },
            // { path: "/MonthlyEvaluation", component: MonthlyEvaluation },
            // { path: "/DutyRoster", component: DutyRoster },
            // { path: "/PermissionGroup", component: PermissionGroup },
            // { path: "/EmployeeSettings", component: EmployeeSettings },
            // { path: "/DevelopmentManagement", component: DevelopmentManagement },
            // { path: "/ProductComplaints", component: ProductComplaints },
            // { path: "/FunctionList", component: FunctionList },
        ]
    }
];

const createRouter = () => new VueRouter({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes
})

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

const router = createRouter()

// 挂在路由守卫导航
router.beforeEach((to, from, next) => {
    // 如果是登录页面的话，就直接放行
    if (to.path === "/Login") return next();
    // 获取token
    const tokenstr = sessionStorage.getItem("token");
    // 如果不存在这个令牌的话就强制跳转到登录页面
    if (!tokenstr) return next("/Login");
    // 都没有问题的话就放行
    if(!store.state.user.functions || store.state.user.functions.length === 0) {
        const routes = JSON.parse(window.sessionStorage.getItem("functions"))
        const user = JSON.parse(window.sessionStorage.getItem("userInfo") || "{}")
        // console.log("重新组装路由",routes)
        store.dispatch('loginSuccess', {functions: routes, userInfo: user}).then(res => {
            // console.log('跳转路由生效')
            next({...to, replace: true});
        });
    }else{
        next();
    }
})

export default router;