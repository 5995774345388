<template>
  <!-- 查看合同 -->
  <el-card>
    <!-- <div style="margin-bottom: 6px">
      
    </div> -->
    <div class="record_top">
      <el-button
        type=""
        icon="el-icon-arrow-left"
        circle
        style="width:40px;height:40px"
        @click="backHistory"
      ></el-button>
      <div class="record_top-right">
        <el-button
          type="primary"
          style="margin-bottom:10px"
          plain
          @click="handleEdit(item.value)"
          v-for="(item, index) in options"
          :key="index"
          >{{ item.label }}</el-button
        >
      </div>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%; margin-top: 10px"
      border
      :cell-style="{ textAlign: 'center' }"
      :header-cell-style="{ textAlign: 'center', backgroundColor: '#f5f7fa' }"
      @cell-dblclick="copyText"
    >
    
      <el-table-column prop="title" label="合同标题" align="center">
      </el-table-column>
      <el-table-column prop="url" label="合同地址" align="center" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="content" label="合同内容" align="center" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="statusTitle" label="签署状态" align="center">
      </el-table-column>
      <el-table-column prop="creationTime" label="发送时间" align="center">
      </el-table-column>
      <el-table-column prop="signTime" label="签署时间" align="center">
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      style="margin-top: 18px"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="list.pageNum"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <el-dialog title="签署合同" :visible.sync="dialogFormVisible">
      <el-form :model="roles" :rules="rules" ref="ruleForm">
        <el-form-item label="合同类型" label-width="130px">
          <el-select v-model="roles.mode" placeholder="请选择类型">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 公共 -->

        <el-form-item label="个人/机构" label-width="130px" v-if="(roles.mode === 5)" >
          <el-select v-model="roles.type" placeholder="请选择">
            <el-option
              v-for="item in organization"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="公司名" label-width="130px" prop="companyName" v-if="((roles.mode!==10)&&(roles.mode !== 4))">
          <el-input
            v-model.trim="roles.companyName"
            autocomplete="off"
            placeholder="公司名"
          ></el-input>
        </el-form-item>

        <el-form-item label="负责人" label-width="130px"  prop="customerName" v-if="((roles.mode!==10)&&(roles.mode !== 4))">
          <el-input
            v-model.trim="roles.customerName"
            autocomplete="off"
            placeholder="负责人"
          ></el-input>
        </el-form-item>

        <el-form-item label="联系电话" label-width="130px" prop="companyPhone" v-if="((roles.mode!==10)&&(roles.mode !== 4))">
          <!-- if(value.length>11)value=value.slice(0,11) -->
          <el-input
            v-model.trim="roles.companyPhone"
            autocomplete="off"
            placeholder="联系电话"
            oninput="value=value.replace(/^(\(\d{3,4}-)|\d{3.4}-)?\d{7,8}$/);"
          ></el-input>
        </el-form-item>

        <!-- 3.卓信--品牌授权合同 -->
        <el-form-item label="品牌名"  label-width="130px" v-if="roles.mode == 3" prop="brand">
          <el-input
            v-model.trim="roles.brand"
            autocomplete="off"
            placeholder="品牌名"
          ></el-input>
        </el-form-item>

        <!-- 4.卓信--数智广告合同 -->
        <el-form-item label="首付金额" label-width="130px"  v-if="(roles.mode == 4)" prop="firstPrice">
          <el-input
            v-model.trim="roles.firstPrice"
            autocomplete="off"
            placeholder="首付金额"
          ></el-input>
        </el-form-item>

        <el-form-item label="首付金额大写" label-width="130px"  v-if="(roles.mode == 4)" prop="firstPriceUPP">
          <el-input
            v-model.trim="roles.firstPriceUPP"
            autocomplete="off"
            placeholder="首付金额大写"
          ></el-input>
        </el-form-item>

        <el-form-item  label="收益总额" label-width="130px"  v-if="(roles.mode == 4)" prop="getPrice">
          <el-input
            v-model.trim="roles.getPrice"
            autocomplete="off"
            placeholder="单条收益总额"
          ></el-input>
        </el-form-item>

        <el-form-item label="收益总额大写" label-width="135px"  v-if="(roles.mode == 4)" prop="getPriceUPP">
          <el-input
            v-model.trim="roles.getPriceUPP"
            autocomplete="off"
            placeholder="单条收益总额大写"
          ></el-input>
        </el-form-item>

        <!-- 5.街舞盒子系统协议 -->
        <el-form-item label="公司地址"  label-width="130px" v-if="(roles.mode == 5 || roles.mode == 3 || roles.mode == 9)" prop="address">
          <el-input
            v-model.trim="roles.address"
            autocomplete="off"
            placeholder="公司地址"
          ></el-input>
        </el-form-item>

        <el-form-item label="员工电话" label-width="130px" v-if="roles.mode == 5" >
          <el-input
            v-model.trim="roles.staffPhone"
            autocomplete="off"
            placeholder="员工电话"
          ></el-input>
        </el-form-item>

        <el-form-item label="购买数量" label-width="130px" v-if="roles.mode == 5">
          <el-input
            v-model.trim="roles.number"
            autocomplete="off"
            placeholder="购买数量"
          ></el-input>
        </el-form-item>

        <el-form-item label="购买单价" label-width="130px" v-if="roles.mode == 5" >
          <el-input
            v-model.trim="roles.uninPrice"
            autocomplete="off"
            placeholder="购买单价"
          ></el-input>
        </el-form-item>

        <el-form-item label="购买折扣" label-width="130px" v-if="roles.mode == 5" >
          <el-input
            v-model.trim="roles.discount"
            autocomplete="off"
            placeholder="购买折扣"
          ></el-input>
        </el-form-item>

        <el-form-item label="首付金额" label-width="130px"  v-if="(roles.mode == 5)" >
          <el-input
            v-model.trim="roles.firstPrice"
            autocomplete="off"
            placeholder="首付金额"
          ></el-input>
        </el-form-item>

        <el-form-item label="首付金额大写" label-width="130px"  v-if="roles.mode == 5" >
          <el-input
            v-model.trim="roles.firstPriceUPP"
            autocomplete="off"
            placeholder="首付金额大写"
          ></el-input>
        </el-form-item>

        <el-form-item label="需付金额" label-width="130px"  v-if="roles.mode == 5 || roles.mode == 10"  >
          <el-input
            v-model.trim="roles.payPrice"
            autocomplete="off"
            placeholder="需付金额"
          ></el-input>
        </el-form-item>
        
        <el-form-item label="需付金额大写" label-width="130px" v-if="roles.mode == 5 || roles.mode == 10" >
          <el-input
            v-model.trim="roles.payPriceUPP"
            autocomplete="off"
            placeholder="需付金额大写"
          ></el-input>
        </el-form-item>

        <el-form-item label="服务年数" label-width="130px"  v-if="roles.mode == 5" >
          <el-input
            v-model.trim="roles.forYear"
            autocomplete="off"
            placeholder="服务年数"
          ></el-input>
        </el-form-item>

        <el-form-item  label="总计年数"  label-width="130px"   v-if="roles.mode == 5" >
          <el-input
            v-model.trim="roles.toYear"
            autocomplete="off"
            placeholder="总计年数"
          ></el-input>
        </el-form-item>

        <el-form-item  label="违约金额"  label-width="130px"  v-if="roles.mode == 5" >
          <el-input
            v-model.trim="roles.defaul"
            autocomplete="off"
            placeholder="违约金额"
          ></el-input>
        </el-form-item>
        <el-form-item  label="到期时间"  label-width="130px"  v-if="(roles.mode == 7 || roles.mode == 8)" >
          <el-input
            v-model.trim="roles.expire"
            autocomplete="off"
            placeholder="到期时间"
          ></el-input>
        </el-form-item>
        <!-- 哇塞卡 -->
        <el-form-item  label="总计月数"  label-width="130px"  v-if="(roles.mode == 7 || roles.mode == 8)" >
          <el-input-number v-model="roles.number" :min="1" :max="20" label="总计月数"></el-input-number>
        </el-form-item>

        <!-- 果酱多校区合同 -->
        <el-form-item label="支付金额" label-width="130px"  v-if="(roles.mode == 9 )" >
          <el-input
            v-model.trim="roles.firstPrice"
            autocomplete="off"
            placeholder="支付金额"
          ></el-input>
        </el-form-item>
        <el-form-item label="支付金额大写" label-width="130px"  v-if="(roles.mode == 9)" >
          <el-input
            v-model.trim="roles.firstPriceUPP"
            autocomplete="off"
            placeholder="支付金额大写"
          ></el-input>
        </el-form-item>
        <el-form-item label="顾问电话" label-width="130px" v-if="roles.mode == 9" >
          <el-input
            v-model.trim="roles.staffPhone"
            autocomplete="off"
            placeholder="顾问电话"
          ></el-input>
        </el-form-item>
        <el-form-item  label="签署数量"  label-width="130px"  v-if="(roles.mode == 9)" >
          <el-input-number v-model="roles.number" :min="1" :max="20" label="签署数量"></el-input-number>
        </el-form-item>
        <el-form-item  label="服务年数"  label-width="130px"   v-if="roles.mode == 9" >
          <el-input-number v-model="roles.toYear" :min="1" :max="100" label="服务年数"></el-input-number>
        </el-form-item>
        <el-form-item label="违约金额" label-width="130px"  v-if="(roles.mode == 9)" >
          <el-input
            v-model.trim="roles.default1"
            autocomplete="off"
            placeholder="违约金额"
          ></el-input>
        </el-form-item>
        
        <el-form-item label="校区名称" label-width="130px"  v-if="(roles.mode == 9)" >
          <el-input
            type="textarea"
            v-model.trim="roles.storeList"
            autocomplete="off"
            placeholder="填多校区以逗号隔开"
          ></el-input>
        </el-form-item>
        <div class="doh" v-if="(roles.mode == 9)">*校区以中文逗号（，）隔开</div>

        <el-form-item label="品牌名称" label-width="130px"  v-if="(roles.mode == 10 ||  roles.mode == 11)" >
          <el-input
            v-model.trim="roles.brandList"
            autocomplete="off"
            placeholder="填多校区以逗号隔开"
          ></el-input>
        </el-form-item>


        <el-form-item label="校区列表" label-width="130px" v-if="(roles.mode == 10|| roles.mode == 11)">
          <div class="storeArr" v-for="(item,index) in storeArr" :key="index">
            <div class="storeArr-label">{{index+1}}校区名称</div>
            <el-input
              v-model.trim="item.name"
              autocomplete="off"
              placeholder="请输入校区名称"
            ></el-input>
            <div class="storeArr-label">{{index+1}}校区地址</div>
            <el-input
              v-model.trim="item.address"
              autocomplete="off"
              placeholder="请输入校区地址"
            ></el-input>
          </div>
          <div class="storeArr-add" v-if="storeArr.length < 10">
            <el-button  icon="el-icon-plus" circle @click="storeArrPush"></el-button>
          </div>
        </el-form-item>


        <!-- 12 果酱盒子管理系统协议（版本）-->
        <el-form-item label="合同金额"  label-width="130px" v-if="roles.mode==12" prop="payPrice">
          <el-input
            v-model.trim="roles.payPrice"
            autocomplete="off"
            placeholder="合同金额"
          ></el-input>
        </el-form-item>
        <el-form-item label="乙方地址"  label-width="130px" v-if="roles.mode==12 || roles.mode==13 || roles.mode==14 || roles.mode==15 || roles.mode==16" prop="address">
          <el-input
            v-model.trim="roles.address"
            autocomplete="off"
            placeholder="乙方地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="顾问电话"  label-width="130px" v-if="roles.mode==12" prop="phone">
          <el-input
            v-model.trim="roles.phone"
            autocomplete="off"
            placeholder="顾问电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="版本"  label-width="130px" v-if="roles.mode==12" prop="edition">
          <el-input
            v-model.trim="roles.edition"
            autocomplete="off"
            placeholder="版本"
          ></el-input>
        </el-form-item>
        <el-form-item label="视频存储"  label-width="130px" v-if="roles.mode==12" prop="videoRation">
          <el-input
            v-model.trim="roles.videoRation"
            autocomplete="off"
            placeholder="版本"
          ></el-input>
        </el-form-item>
        <el-form-item label="学员量"  label-width="130px" v-if="roles.mode==12" prop="studentRation">
          <el-input
            v-model.trim="roles.studentRation"
            autocomplete="off"
            placeholder="学员量"
          ></el-input>
        </el-form-item>
        <el-form-item label="日志量"  label-width="130px" v-if="roles.mode==12" prop="logRation">
          <el-input
            v-model.trim="roles.logRation"
            autocomplete="off"
            placeholder="日志量"
          ></el-input>
        </el-form-item>
        <el-form-item label="课程海报"  label-width="130px" v-if="roles.mode==12" prop="coursepicRation">
          <el-input
            v-model.trim="roles.coursepicRation"
            autocomplete="off"
            placeholder="课程海报"
          ></el-input>
        </el-form-item>
        <el-form-item label="电子签赠送"  label-width="130px" v-if="roles.mode==12" prop="signRation">
          <el-input
            v-model.trim="roles.signRation"
            autocomplete="off"
            placeholder="电子签赠送"
          ></el-input>
        </el-form-item>
        <el-form-item label="短信赠送"  label-width="130px" v-if="roles.mode==12" prop="smsRation">
          <el-input
            v-model.trim="roles.smsRation"
            autocomplete="off"
            placeholder="短信赠送"
          ></el-input>
        </el-form-item>
        <el-form-item label="功能升级"  label-width="130px" v-if="roles.mode==12" prop="functionRation">
          <el-input
            v-model.trim="roles.functionRation"
            autocomplete="off"
            placeholder="功能升级"
          ></el-input>
        </el-form-item>

        
        <!-- 13,14,15 -->
        <el-form-item label="人数"  label-width="130px" v-if="roles.mode==13 || roles.mode==14 ||roles.mode==16" prop="number">
          <el-input
            v-model.trim="roles.number"
            autocomplete="off"
            placeholder="人数"
          ></el-input>
        </el-form-item>


        <!-- 15.辽宁CHUC系统合同 -->
        <el-form-item label="负责人（乙方）"  label-width="130px" v-if="roles.mode==15" prop="customername">
          <el-input
            v-model.trim="roles.customername"
            autocomplete="off"
            placeholder="负责人（乙方）"
          ></el-input>
        </el-form-item>
        <el-form-item label="参与人"  label-width="130px" v-if="roles.mode==15" prop="addressList">
          <el-input
            v-model.trim="roles.addressList"
            autocomplete="off"
            placeholder="参与人"
          ></el-input>
        </el-form-item>



      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="edit('ruleForm')">确 定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import { contract } from "@/pages/distributionChannel/api";
import axios from "axios";
import { electronic } from "@/api/electronic";
export default {
  data() {
    return {
      tableData: [],
      roles: {
        mode: 3,
        type: "个人",
        companyName: "",
        customerName: "",
        companyPhone: "",
        financeId: "",
        staffId: "",
        brand:"", //3.品牌名称

        address: "",
        staffPhone: "",
        number: "",
        uninPrice: "",
        discount: "",
        firstPrice: "",
        firstPriceUPP: "",
        payPrice: "",
        payPriceUPP: "",
        forYear: "",
        toYear: "",
        defaul: "",

        getPrice: "",
        getPriceUPP: "",
        expire:'',

        default1:'',
        storeList:'',
        barndList:"",

        // 12
        phone:'', // (顾问电话),
        edition:"" ,  //(版本),
        videoRation:"" ,//  (视频存储),
        studentRation:"" ,//  (学员量),
        logRation:"" ,//  (日志量),
        coursepicRation:"" ,//  (课程海报),
        signRation:""  ,// (电子签赠送),
        smsRation:""  ,// (短信赠送),
        functionRation:""  ,// (功能升级),

        customername:'',//乙方负责人
        addressList:'',//参与人
      },
      list: {
        financeId: "",
        pageNum: 1,
        pageSize: 20,
      },
      total: 0,
      // staff: "",
      dialogFormVisible: false, //弹出框
      options: [
        {
          value: 3,
          label: "卓信--品牌授权合同",
        },
        {
          value: 5,
          label: "街舞盒子系统协议",
        },
        {
          value: 4,
          label: "卓信--数智广告合同",
        },
        {
          value: 6,
          label: "卓信--机构授权合同",
        },
        {
          value: 7,
          label: "6个月哇塞卡",
        },
        {
          value: 8,
          label: "12个月哇塞卡",
        },
        {
          value: 9,
          label: "果酱多校区合同",
        },
        {
          value: 10,
          label: "卓信-机构授权（多机构）",
        },
        {
          value: 11,
          label: "卓信-机构广告（多机构）",
        },
        {
          value: 12,
          label: "果酱盒子管理系统协议（版本）",
        },
        {
          value: 13,
          label: "广东CHUC报名合同",
        },
        {
          value: 14,
          label: "广东CHUC系统合同",
        },
        {
          value: 15,
          label: "辽宁CHUC系统合同",
        },
        {
          value: 16,
          label: "广东chuc老客户版合同",
        },
      ],
      organization: [
        {
          value: "机构",
          label: "机构",
        },
        {
          value: "个人",
          label: "个人",
        },
      ],
      rules: {
        companyName: [
          //公司名称
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        customerName: [
          //负责人
          { required: true, message: "请输入负责人", trigger: "blur" },
        ],
        companyPhone: [
          //联系电话
          { required: true, message: "请输入手机号", trigger: "blur" },
          { min: 11, message: "手机号格式不正确", trigger: "blur" },
        ],
        brand: [
          //品牌名
          { required: true, message: "请输入品牌名", trigger: "blur" },
        ],
        address: [
          //公司地址
          { required: true, message: "请输入公司地址", trigger: "blur" },
        ],
        firstPrice: [
          //首付金额
          { required: true, message: "请输入首付金额", trigger: "blur" },
        ],
        firstPriceUPP: [
          //首付金额大写
          { required: true, message: "请输入首付金额大写", trigger: "blur" },
        ],
        getPrice: [
          //单条收益总额
          { required: true, message: "请输入单条收益总额", trigger: "blur" },
        ],
        getPriceUPP: [
          //单条收益总额大写
          { required: true, message: "请输入单条收益总额大写", trigger: "blur" },
        ],
      },

      storeArr:[{name:"",address:""}]
    };
  },
  created() {
    this.list.financeId = this.$route.query.id;
    this.roles.staffId = this.$route.query.staff;
    this.roles.financeId = parseInt(this.$route.query.id);
    this.initData();
  },
  methods: {
    storeArrPush(){
      if(this.storeArr.length === 10) return
      this.storeArr.push({name:"",address:""})
    },
    initData() {
      console.log(this.list);
      contract(this.list).then((res) => {
        console.log(res)
        if (res.status === "success") {
          this.tableData = res.data.list ? res.data.list : [];
          this.total = parseInt(res.data.total);
          console.log(res);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleCurrentChange(newpage) {
      this.list.pageNum = newpage;
      // 重新获取数据
      this.getTableList();
    },
    // 监听pageSize事件
    handleSizeChange(newsize) {
      this.list.pageSize = newsize;
      // 重新获取数据
      this.getTableList();
    },
    edit(formName) {
      //确认
      let that = this;
     
      that.$refs[formName].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          let postData = {}
          // console.log(that.roles);
          // ret
          let { companyName, customerName,  companyPhone,  financeId,
            staffId,  address,  brand, firstPrice,  firstPriceUPP,
            getPrice, getPriceUPP, staffPhone, number, uninPrice,
            discount,  payPrice, payPriceUPP, forYear, toYear, defaul,type,expire
            ,storeList,default1,brandList,phone,edition,videoRation,studentRation, logRation,
             coursepicRation,signRation,smsRation,functionRation,customername,addressList
          } = this.roles
          let storeListA = ''
          let addressListA = ''

          switch (this.roles.mode) {
            case 3:
                console.log("操作3 -- 卓信--品牌授权合同");
                postData={
                  type: '机构',
                  companyName,
                  customerName,
                  companyPhone,
                  address,
                  brand,
                }

             break;
            case 4:
              console.log("操作4 --卓信--数智广告合同 ");
              postData={
                  type: '机构',
                  firstPrice,
                  firstPriceUPP,
                  getPrice,
                  getPriceUPP
              }

              break;
            case 5:
              console.log("操作5 -- 街舞盒子系统协议");
              postData = {
                  type,
                  companyName,
                  customerName,
                  companyPhone,
                  address,
                  staffPhone,
                  number,
                  uninPrice,
                  discount,
                  firstPrice,
                  firstPriceUPP,
                  payPrice,
                  payPriceUPP,
                  forYear,
                  toYear,
                  defaul,
              }

              break;
            case 6:
              console.log("操作6 -- 机构授权合同");
              postData = {
                  type: '机构',
                  companyName,
                  customerName,
                  companyPhone,
              }
              
              break;
              case 7:
              console.log("操作7 -- 6个月哇塞卡");
              postData = {
                  type: '机构',
                  companyName,
                  customerName,
                  companyPhone,
                  number,
                  expire
              }
              
                break;
              case 8:
                console.log("操作8 -- 12个月哇塞卡");
                postData = {
                    type: '机构',
                    companyName,
                    customerName,
                    companyPhone,
                    number,
                    expire
                }
              
              break;
              case 9:
                console.log("操作9 -- 果酱多校区合同");
                postData = {
                    type: '机构',
                    companyName,
                    customerName,
                    companyPhone,
                    address,
                    staffPhone,
                    firstPrice,
                    firstPriceUPP,
                    number,
                    toYear,
                    default:default1,
                    storeList,
                }
                
              break;
              case 10:
                console.log("操作9 -- 卓信-机构授权（多机构）");
                if((this.storeArr[0].name ==='') || (this.storeArr[0].address ==='')){
                      loading.close();
                      this.$message.error("最少最少输入一个机构")
                    return
                }

                this.storeArr.forEach((item,index) =>{
                  storeListA += item.name
                  addressListA += item.address
                  if(index !== this.storeArr.length-1){
                    storeListA += ','
                    addressListA += ','
                  }
                })

                postData={
                  type: '机构',
                  companyName:'广州数智传播文化有限公司',
                  customerName:'陈炼',
                  companyPhone:'18675856167',
                  payPrice,
                  payPriceUPP,
                  storeList:storeListA,
                  addressList:addressListA,
                  brandList,
                }
                break;
                case 11:
                  console.log("操作11 --卓信-机构广告（多机构）");

                  this.storeArr.forEach((item,index) =>{
                    storeListA += item.name
                    addressListA += item.address
                    if(index !== this.storeArr.length-1){
                      storeListA += ','
                      addressListA += ','
                    }
                  })
                  console.log(storeListA);
                  console.log(addressListA);

                  postData={
                    type: '机构',
                    companyName,
                    customerName,
                    companyPhone,
                    storeList:storeListA,
                    addressList:addressListA,
                    brandList,
                  }
                break;
                case 12:
                  console.log("操作12 --果酱盒子管理系统协议（版本）")
                  postData={
                    type: '机构',
                    companyName,
                    customerName,
                    companyPhone,
                    payPrice,
                    address,
                    phone,
                    edition,
                    videoRation,
                    studentRation,
                    logRation,
                    coursepicRation,
                    signRation,
                    smsRation,
                    functionRation,
                  }
                  break;
                case 13:
                  console.log("操作13 --广东CHUC报名合同 ");
                  postData={
                      type: '机构',
                      companyName,
                      customerName,
                      companyPhone,
                      address,
                      number,
                  }
                  break;   
                case 14:
                  console.log("操作14 --广东CHUC系统合同 ");
                  postData={
                      type: '机构',
                      companyName,
                      customerName,
                      companyPhone,
                      address,
                      number,
                  }
                   break;   
                case 15:
                  console.log("操作15 --辽宁CHUC系统合同 ");
                  postData={
                      type: '机构',
                      companyName,
                      customerName,
                      companyPhone,
                      address,
                      addressList,
                      customername,
                  }
                  break;
                case 16:
                  console.log("操作16 -- 广东chuc老客户版合同");
                  postData={
                      type: '机构',
                      companyName,
                      customerName,
                      companyPhone,
                      address,
                      number,
                  }
                  break;      
            default:
                  
              break;
          }
          postData.mode = this.roles.mode
          postData.financeId = financeId
          postData.staffId = staffId
          console.log(postData);
          // return
          let that = this
          axios({
                methods: "post",
                url: "https://beibeike-qy-b33k4-1302318474.ap-shanghai.app.tcloudbase.com/mapi_econtractAdd",
                header: {
                  "Content-Type": "application/json",
                },
                params: postData,
              }).then(res=>{
                that.dialogFormVisible = false
                loading.close();
                that.$notify({
                  title: "提示",
                  message: "发送成功",
                  type: "success",
                });
                console.log(res);

              }).catch(function (err) {
                that.dialogFormVisible = false
                loading.close();
                that.$notify({
                  title: "提示",
                  message: "发送成功",
                  type: "success",
                });
                console.log(err);
              })
              that.initData()
              console.log(this.list);
              contract(this.list).then((res) => {
                console.log(res)
                if (res.status === "success") {
                  this.tableData = res.data.list ? res.data.list : [];
                  this.total = parseInt(res.data.total);
                  console.log(res);
                } else {
                  this.$message.error(res.msg);
                }
              });
              that.$refs[formName].resetFields();

        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 返回
    backHistory() {
      this.$router.go(-1); //返回上一层
    },
    handleEdit(index) {
      this.dialogFormVisible = true;
      this.roles.mode = index;
    },
        // 复制内容
    copyText(row, column, cell, event) {
      // 双击复制
      let save = function (e) {
        e.clipboardData.setData("text/plain", event.target.innerText);
        e.preventDefault(); //阻止默认行为
      };
      console.log(save);
      document.addEventListener("copy", save); //添加一个copy事件
      document.execCommand("copy"); //执行copy方法
      this.$message({ message: "复制成功", type: "success" }); //提示
    },
  },
  beforeDestroy(){
    setTimeout(()=>{
      window.localStorage.setItem("Transaction",0)
    },1300)
  }
};
</script>

<style scoped>
.record_top {
  width: 100%;
  /* height: 60px; */
  display: flex;
  justify-content: space-between;
}
.record_top-right{
  flex: 1;
  margin-left: 30px;
}
.doh{
  width: 100%;
  color: red;
  margin-left: 130px;
}
.storeArr{
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  /* flex-wrap: wrap; */
}
.storeArr-label{
  width: 80px;
}
.storeArr .el-input{
  width: 200px;
}
.storeArr-add{
  width: 100%;
  text-align: right;
  margin-top: 10px;
}
</style>