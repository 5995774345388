<template>
  <el-card>
    <el-row :gutter="10" style="display: flex">
      <el-date-picker
        v-model="dataFrom.monthTime"
        type="month"
        placeholder="选择月度"
        value-format="yyyy-MM"
        style="margin-left: 8px"
      >
      </el-date-picker>

      <el-button
        style="background-color: #5e6cf4; color: #fff; margin-left: 25px"
        @click="Selectdata()"
      >
        <i class="el-icon-search"></i>
      </el-button>

      <el-button 
        style="background-color: #5e6cf4; color: #fff; margin-left: auto" 
        type="primary" 
        icon="el-icon-share"
        @click="cloneUrl"
      ></el-button>
    </el-row>
    <br />
    <!-- 表格 -->
    <el-table
      :data="dataList"
      style="width: 100%"
      border
      :cell-style="{ textAlign: 'center' }"
      :header-cell-style="{ textAlign: 'center', backgroundColor: '#f5f7fa' }"
    >
      <el-table-column prop="serId" label="评价编号"> </el-table-column>
      <el-table-column prop="name" label="机构名称"> </el-table-column>
      <el-table-column prop="serScore" label="产品评分"> </el-table-column>
      <el-table-column prop="serOpinion" label="产品评价"  show-overflow-tooltip> </el-table-column>
      <el-table-column prop="proScore" label="服务评分"> </el-table-column>
      <el-table-column prop="proOpinion" label="服务评价"  show-overflow-tooltip> </el-table-column>
      <el-table-column prop="creationTime" label="评价时间"> </el-table-column>
    </el-table>
    <br />   
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="dataFrom.pageNum"
      :page-sizes="[3, 5, 8, 10]"
      :page-size="dataFrom.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </el-card>
</template>

<script>
import { evaluatePageList } from "@/api/api";
export default {
  data() {
    return {
      dataFrom: {
        monthTime: "",
        pageNum: 1,
        pageSize: 10,
      },
      dataList: [],
      total: 0,
      loadingTab: false,
    };
  },
  methods: {
    // 赋值粘贴板
    cloneUrl(row, column, cell, event){
      // 双击复制
      let save = function (e) {
        e.clipboardData.setData("text/plain", ' https://oam.jamboxsys.com/evaluate');
        e.preventDefault(); //阻止默认行为
      };
      console.log(save);
      document.addEventListener("copy", save); //添加一个copy事件
      document.execCommand("copy"); //执行copy方法
      this.$message({ message: "复制链接成功", type: "success" }); //提示
    },
    // 监听pageSize事件
    handleSizeChange(newsize) {
      this.dataFrom.pageSize = newsize;
      // 重新获取数据
      this.getevaluatePageList();
    },

    handleCurrentChange(newpage) {
      this.dataFrom.pageNum = newpage;
      // 重新获取数据
      this.getevaluatePageList();
    },
    // 日期
    Selectdata() {
      // console.log(this.dataFrom.monthTime);
      this.getevaluatePageList();
      this.dataFrom.monthTime = "";
    },
    async getevaluatePageList() {
      const res = await evaluatePageList(this.dataFrom);
      console.log(res);
      if (res.status === "success") {
        this.dataList = res.data.list;
        this.total = parseInt(res.data.total);
        this.loadingTab = true;
      }
    },
  },
  created() {
    this.getevaluatePageList();
  },
};
</script>

<style>
</style>