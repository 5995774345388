// 对axios进行二次封装
import axios from "axios";

// 导入数据响应条
import nprogress from "nprogress";

// 导入响应条样式
import "nprogress/nprogress.css"


// import { param } from "@/util/arrar";

import { REQUEST_URL } from "@/api/project.config"

// 利用axios的create方法创建实例
const requests = axios.create({
  baseURL: REQUEST_URL, //测试环境 
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    'token': (() => { return window.sessionStorage.getItem("token") })()
  }
});


// 设置请求拦截器 
requests.interceptors.request.use((config) => {
  // config:是一个配置对象，对象中有header请求头属性  
  if (!config.headers.token) {
    config.headers.token = window.sessionStorage.getItem("token")
  }
  // if (method === 'get') {
  //   config.url += "?" + param(config.params)
  //   delete config.params
  // }
  // 进度条启动
  nprogress.start();
  return config
})


// 响应拦截器
requests.interceptors.response.use(
  // 成功回调
  (res) => {
    //进度条结束
    nprogress.done();
    return res.data
  },
  //失败回调
  (error) => {
    // return Promise.reject(new Error("faile")) ;   //终结Promise
  }
)

// 对外暴露
export default requests