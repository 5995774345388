// const REQUEST_URL = 'https://api.jamboxsys.com/test'; // 测试地址
// 
// const REQUEST_URL = 'https://api.jamboxsys.com/jambox'; // 线上地址
// 
// const REQUEST_URL = 'http://192.168.3.130:8078/test'; // 蜘蛛地址

// const REQUEST_URL = 'http://192.168.3.182:8077/oam'; // 成文地址

// const aaa = 'https://beibeike-qy-b33k4-1302318474.ap-shanghai.app.tcloudbase.com/mapi_econtractAdd'; //电子合同云接口

const REQUEST_URL = "https://oamapi.cpopsz.com/test" // 最新测试地址

// const REQUEST_URL = "https://oamapi.cpopsz.com/oam" // 最新线上地址


// const REQUEST_URL = "http://localhost:8077/oam" // 最新线上地址






export {
    REQUEST_URL 
}