import router, { routes } from '../router';
import createPersistedState from "vuex-persistedstate"

export default {
    state: () => ({
        user: JSON.parse(window.sessionStorage.getItem("userInfo") || "{}"), // 用户信息
        functions: [],
        originFuncs: JSON.parse(window.sessionStorage.getItem("functions")) || [],
        token: undefined, // 用户token
        authors: []
    }),
    mutations: {
        setUser(state, userInfo) {
            window.sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
            state.user = userInfo || {ll: 1};
        },
        setFunctions(state, functions) {
            state.functions = functions;
        },
        setAuthors(state, authors) {
            state.authors = authors;
        }
    },
    actions: {
        loginSuccess({ commit }, { userInfo, functions }) {
            window.sessionStorage.setItem("functions", JSON.stringify(functions));
            commit('setUser', userInfo);
            const buttons = [];
            let _functions = (function recursion(list, parentNode) {
                return list.map(tmp => {
                    if (tmp.buttons) {
                        buttons.push(...tmp.buttons.map(b => { return b.tag }));
                    }
                    if (tmp.parentId == 0 && !tmp.children) {
                        return {
                            hide: true,
                            path: `/${tmp.tag || ""}`,
                            component: () => import('@/components/Home'),
                            children: [{
                                meta: {
                                    title: tmp.name,
                                    icon: tmp.icon
                                },
                                name: tmp.tag,
                                component: () => import(`@/${tmp.component}.vue`),
                                // redirect: tmp.redirect,
                                path: tmp.path || "/",
                            }]
                        }
                    } else {
                        return {
                            // component: (tmp.parentId == 0 ? () => import('@/components/Home') : () => import(`@/${tmp.component}.vue`)),
                            component: (tmp.children ? () => import('@/components/Home') : () => import(`@/${tmp.component}.vue`)),
                            meta: {
                                title: tmp.name,
                                icon: tmp.icon
                            },
                            name: tmp.tag,
                            // redirect: tmp.redirect,
                            path: (parentNode ? parentNode.path + tmp.path : tmp.path) || "/",
                            children: (tmp.children && recursion(tmp.children, tmp))
                        };
                    }
                });
            })(functions)
            // console.log('buttons', _functions)
            router.addRoutes(_functions);
            // console.info(router.matcher)
            commit('setFunctions', _functions);
            commit('setAuthors', [...new Set(buttons)])
        }
    },
    getters: {
        userInfo(state) {
            return state.user;
        },
        functionList(state) {
            return state.functions;
        },
        authors(state) {
            return state.authors;
        }
    }
}
