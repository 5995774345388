<template>
  <div>
    <!-- 无效投诉 -->
    <el-row :gutter="10" style="display: flex; margin-bottom: 15px">
      <!-- <el-col :span="4"> -->
      <el-select
        v-model="dataFrom.systemName"
        placeholder="请选择终端"
        clearable
        @change="getTabelList()"
      >
        <el-option
          v-for="(item, index) in systemNameList"
          :key="index"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
      <el-button
        style="background-color: #5e6cf4; color: #fff; margin-left: 20px"
        @click="getTabelList()"
      >
        <i class="el-icon-search"></i>
      </el-button>
    </el-row>
    <el-table
      :data="tableData"
      style="width: 100%; margin-bottom: 8px"
      border=""
      :row-style="{ height: '60px' }"
      :cell-style="{ padding: '0', textAlign: 'center' }"
      :header-cell-style="{
        textAlign: 'center',
        backgroundColor: '#f5f7fa',
      }"
      @cell-dblclick="copyText"
    >
      <el-table-column prop="name" label="投诉机构"> </el-table-column>
      <el-table-column prop="systemName" label="产品终端"> </el-table-column>
      <el-table-column prop="content" label="内容描述"> </el-table-column>
      <el-table-column prop="enclosure" label="附件"> </el-table-column>
      <el-table-column prop="picUrl" label="投诉截图"> </el-table-column>
      <el-table-column prop="remarks" label="备注"> </el-table-column>
      <el-table-column prop="time" label="预计完成时间"> </el-table-column>
    </el-table>
    <div style="text-align: center">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="dataFrom.pageNum"
        :page-size="dataFrom.pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { complaintPageList,iterationSystemList } from "@/api/DevelopmentManagement";
export default {
  data() {
    return {
      tableData: [],
      dataFrom: {
        pageSize: 10,
        pageNum: 1,
        status: "0",
        systemName: "",
      },
      total: 0,
      systemNameList: [],
    };
  },
  props: ["tabTypeIndex"],
  mounted() {
    this.dataFrom.status = this.tabTypeIndex;
    // //调用获取表格数据方法
    this.getTabelList();
    // // this.areaData = area;
    // this.getList();
  },
  methods: {
    // 获取表格
    async getTabelList() {
      console.log(this.dataFrom);
      let res = await complaintPageList(this.dataFrom);
      this.tableData = res.data.list;
      this.total = parseInt(res.data.total);
      console.log(res);
      this.loadingTab = false;
      let rest = await iterationSystemList({});
      this.systemNameList = rest.data;
    },
    handleSizeChange(newsize) {
      this.dataFrom.pageSize = newsize;
      // 重新获取数据
      // this.getTableList(0);
    },
    handleCurrentChange(newpage) {
      this.dataFrom.pageNum = newpage;
      // 重新获取数据
      // this.getTableList(0);
    },
    // 复制内容
    copyText(row, column, cell, event) {
      // 双击复制
      let save = function (e) {
        e.clipboardData.setData("text/plain", event.target.innerText);
        e.preventDefault(); //阻止默认行为
      };
      console.log(save);
      document.addEventListener("copy", save); //添加一个copy事件
      document.execCommand("copy"); //执行copy方法
      this.$message({ message: "复制成功", type: "success" }); //提示
    },
  },
};
</script>

<style>
</style>