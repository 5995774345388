import requests from "./request";
/***
 *  
 * 机构设置
 * 
 */

// 获取售后服务筛选
export const afterServiceSearch = (query1) => requests({
    url: '/oam/type/afterServiceSearch',
    method: "post",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});
// 机构设置------------------------------------------------
export const mechanismPageList = (query1) => requests({
    url: '/oam/afterService/afterServicePageList',
    method: "post",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});


// 修改到到期时间
export const setEndTime = (query1) => requests({
    url: '/oam/signContract/setEndTime',
    method: "post",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});

// 删除机构
export const mechanismDel = (query1) => requests({
    url: '/oam/mechanism/mechanismDel',
    method: "post",
    data: query1,
});
// 生成二维码
export const setQrCodel = (query1) => requests({
    url: '/oam/afterService/setQrCode',
    method: "post",
    data: query1,
});

// 设置备注
export const setRemarks = (query1) => requests({
    url: '/oam/afterService/setRemarks',
    method: "post",
    data: query1,
});

// 服务编辑
export const serviceUpd = (query1) => requests({
    url: '/oam/afterService/serviceUpd',
    method: "post",
    data: query1,
});



// 上传品牌页
export const afterServiceSerpic = (query1) => requests({
    url: '/oam/afterService/setPic',
    method: "post",
    data: query1,
});