import requests from "./request";

/**
 * 开发管理
 */

// 获取开发管理数据
export const iterationPageList = (query1) => requests({
    url: '/oam/OAMIteration/iterationPageList',
    method: "POST",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
        // 'token': "asddd"
    }
});

// 获取系统终端
export const iterationSystemList = (query1) => requests({
    url: '/oam/OAMIteration/iterationSystemList',
    method: "POST",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});
// 获取技术列表
export const dutyIterationList = (query1) => requests({
    url: '/oam/OAMIteration/searchIterationStaffList',
    method: "POST",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});
// 获取类型课宣表
export const iterationTypeList = (query1) => requests({
    url: '/oam/OAMIteration/iterationTypeList',
    method: "POST",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});


// 添加任务
export const iterationAdd = (query1) => requests({
    url: '/oam/OAMIteration/iterationAdd',
    method: "POST",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});

// 编辑任务
export const iterationUpd = (query1) => requests({
    url: '/oam/OAMIteration/iterationUpd',
    method: "POST",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});

// 确定转交
export const iterationStatusChange = (query1) => requests({
    url: '/oam/OAMIteration/iterationStatusChange',
    method: "POST",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});

// 工作项记录
export const iterationRecordList = (query1) => requests({
    url: '/oam/OAMIteration/iterationRecordList',
    method: "POST",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});

// 删除记录
export const iterationDel = (query1) => requests({
    url: '/oam/OAMIteration/iterationDel',
    method: "POST",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});

// 驳回任务
export const iterationRejection = (query1) => requests({
    url: '/oam/OAMIteration/iterationRejection',
    method: "POST",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});

// 查询已绑定的需求
export const OAMDemandDoundDemand = (query1) => requests({
    url: '/oam/OAMDemand/boundDemand?iterationId='+ query1.iterationId,
    method: "GET",
});

// 获取未绑定任务的需求
export const OAMDemandnotBindDemand = (query1) => requests({
    url: `/oam/OAMDemand/notBindDemand?pageNum=${query1.pageNum}&pageSize=${query1.pageSize}&remarks=${query1.remarks}`,
    method: "GET",
    // data:query1
});

// 绑定需求
export const OAMDemanditerationBindDemand = (query1) => requests({
    url: '/oam/OAMDemand/iterationBindDemand',
    method: "PUT",
    data:query1
});




// 产品投诉=================================================
// 获取产品投诉列表
export const complaintPageList = (query1) => requests({
    url: '/oam/OAMIteration/complaintPageList',
    method: "POST",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});

// 添加投诉
export const complaintAdd = (query1) => requests({
    url: '/oam/OAMIteration/complaintAdd',
    method: "POST",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});
// 编辑投诉
export const complaintUpd = (query1) => requests({
    url: '/oam/OAMIteration/complaintUpd',
    method: "POST",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});


// 转无效
export const complaintDel = (query1) => requests({
    url: '/oam/OAMIteration/complaintDel',
    method: "POST",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});

// 绑定任务
export const complaintToIteration = (query1) => requests({
    url: '/oam/OAMIteration/complaintToIteration',
    method: "POST",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});

// 获取可选列表
export const searchIteration = (query1) => requests({
    url: '/oam/OAMIteration/searchIteration',
    method: "POST",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});


// 开发排期
export const iterationScheduling = (query1) => requests({
    url: '/oam/OAMIteration/iterationScheduling',
    method: "POST",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});


// 开发成员
export const scaleList = (query1) => requests({
    url: '/oam/OAMIteration/scaleList',
    method: "POST",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});


// 新增成员
export const scaleAdd = (query1) => requests({
    url: '/oam/OAMIteration/scaleAdd',
    method: "POST",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
}); 

// 新增编辑
export const scaleUpd = (query1) => requests({
    url: '/oam/OAMIteration/scaleUpd',
    method: "POST",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});


// 删除
export const scaleDel = (query1) => requests({
    url: '/oam/OAMIteration/scaleDel',
    method: "POST",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});

// 待领取任务列表
export const demandDrawList = (query1) => requests({
    url: '/oam/OAMDemand/demandDrawList',
    method: "POST",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});

// 领取需求
export const drawDemand = (query1) => requests({
    url: '/oam/OAMDemand/drawDemand',
    method: "POST",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});

// 获取个人任务
export const staffIterationList = (query1) => requests({
    url: '/oam/OAMIteration/staffIterationList',
    method: "POST",
    data: query1,
});


// 获取开发进度
export const iterationDetailList = (query1) => requests({
    url: '/oam/OAMIteration/iterationDetailList',
    method: "POST",
    data: query1,
});

// 获取开发进度编辑
export const iterationDetailUpd = (query1) => requests({
    url: '/oam/OAMIteration/iterationDetailUpd',
    method: "POST",
    data: query1,
});

// 获取月度统计数据
export const iterationDataByMonth = (query1) => requests({
    url: '/oam/OAMIteration/iterationDataByMonth',
    method: "POST",
    data: query1,
});

// 生成日报
export const dailyPaper = (query1) => requests({
    url: '/oam/OAMIteration/dailyPaper',
    method: "POST",
    data: query1,
});