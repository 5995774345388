import requests from "./request";
/**
 * 
 * 需求记录（API文档） 
 * 
 */

const apis = {
    // 需求标记接口
    OAMDemandMark(data, type = "PUT") {
        return requests({
            url: `/oam/OAMDemand/mark/${data.id}/${data.mark}`,
            method: type
        })
    },
    OAMDemandEdit(data, type = "PUT") {
        return requests({
          url: '/oam/OAMDemand',
          method: type,
          data
        })
    },


    // 评级
    demandGrade(data, type = "POST") {
      return requests({
        url: '/oam/OAMDemand/demandGrade',
        method: type,
        data
      })
  },
}

export { apis }
