<template>
  <!-- 测试 -->
  <div class=''>
    <SideForm :formDataHead="formDataHead" v-model="formData">
      <!-- 自定义form表单内容，可配置同keyName字段的具名插槽 -->
      <template #address>
        <el-input v-model="formData.address" placeholder="这里是测试自定义表单组件内容-address"></el-input>
      </template>
    </SideForm>
  </div>
</template>
 
<script >
import SideForm from '@/components/SideForm'
export default ({
  name: 'test',
  components:{ SideForm },
  data() {
    return {
      formDataHead: [], // TODO 表单字段
      formData: {
        userName: 123,
        passWord: 345
      }, // 表单数据
    }
  },
  created(){
    this.initFormHead();
  },
  watch: {
    // formData: {
    //   handler(newVal, oldVal){
    //     console.log('父组件测试监听 数据是否更改',newVal, oldVal)
    //   },
    //   deep: true
    // }
  },
  methods: {
    // 初始化表单key值
    initFormHead(){
      this.formDataHead = [
        // slotName 插槽名，isCustom 是否自定义内容，formItemEl 表单元素
        {keyName: 'userName', label: '用户名', placeholder: '请输入用户名'},
        {keyName: 'passWord', label: '密码', type: 'passWord'},
        {keyName: 'address', label: '地址', slotName: 'address', isCustom: true},
        {keyName: 'imgUrl', label: '图片', formItemEl: 'img', imgUrl: ''},
        {keyName: 'imgUrl', label: '图片', slotName: 'address', formItemEl: 'img'},
      ];
    },
  }
})
 
</script>
<style scoped>
</style>