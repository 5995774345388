<template>
  <!-- 售后中心 -->
  <el-card>
    <div v-loading="loadingTab">
      <el-row :gutter="10" style="display: flex; align-items: center;font-size: 14px;">
        <!-- <el-col :span="4"> -->
        <label style="margin-right: 8px">搜索</label>
        <el-input
          placeholder="校区/创建人/联系方式搜索"
          v-model="getFrom.remarks"
          style="width: 140px; margin-right: 20px"
        >
        </el-input>
        <label style="margin-right: 8px">已完成</label>
        <el-select
          v-model="getFrom.comOpeList"
          placeholder="请选择待操作"
          multiple
          style="width: 140px; margin-right: 10px"
          @change="selectChange(0)">
          <el-option
            v-for="(item, index) in beOperated"
            :key="index"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
        <label style="margin-right: 8px">待操作
        </label>
        <el-select
          v-model="getFrom.beOpeLis"
          placeholder="请选择待操作"
          multiple
          style="width: 140px; margin-right: 20px"
          @change="selectChange(1)"
        >
          <el-option
            v-for="(item, index) in beOperated"
            :key="index"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
        <label style="margin-right: 8px">不需要</label>
        <el-select
          v-model="getFrom.notOperList"
          placeholder="请选择待操作"
          multiple
          style="width: 150px; margin-right: 20px"
          @change="selectChange(2)"
        >
          <el-option
            v-for="(item, index) in beOperated"
            :key="index"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
        <!-- </el-col>
        <el-col :span="2"> -->
        <el-button
          style="background-color: #5e6cf4; color: #fff"
          @click="getreviewActivities()"
        >
          <i class="el-icon-search"></i>
        </el-button>
        <!-- </el-col> -->
        <!-- <el-col :span="10"> </el-col> -->
      </el-row>

      <!-- 表格 -->
      <el-table
        :data="tableData0"
        style="width: 100%; margin-bottom: 8px"
        :row-class-name="tableRowClassName"
        border
        :cell-style="{ textAlign: 'center' }"
        :header-cell-style="{ textAlign: 'center', backgroundColor: '#f5f7fa' }"
      >
        <el-table-column
          prop="brandName"
          label="机构名称"
          fixed
          width="110"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="mechanism"
          label="校区名称"
          fixed
          width="110"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="联系人"
          fixed
          width="110"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="staffName"
          label="售前负责"
          fixed
          width="80"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="endTime"
          label="到期时间"
          fixed
          width="80"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="chargePhone"
          label="校区二维码"
          fixed
          width="100"
        >
          <template slot-scope="scope">
            <div>
              <el-button
                type="success"
                size="small"
                @click="GenerateCode(scope.row)"
                v-if="!scope.row.qrCode"
                >生成</el-button>

                <el-image style="width: 90px; height: 60px" :src="scope.row.qrCode" fit="cover" :previewSrcList="[scope.row.qrCode]" v-else>
                </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="chargePhone"
          label="品牌页"
          fixed
          width="100">
          <template slot-scope="scope">
            <el-image
            style="width: 90px; height: 60px"
            :src="scope.row.picUrl"
            fit="cover" :previewSrcList="[scope.row.picUrl]" v-if="scope.row.picUrl">
          </el-image>
          <label v-else>未上传</label>
          </template>
        </el-table-column>
        <el-table-column prop="param1" label="定制小程序" >
        </el-table-column>
        <el-table-column prop="param2" label="公众号关联">
        </el-table-column>
        <el-table-column prop="param3" label="电子签安装" >
        </el-table-column>
        <el-table-column prop="param4" label="使用培训" >
        </el-table-column>
        <el-table-column prop="param5" label="数据导入">
        </el-table-column>
        <el-table-column prop="param6" label="品宣设计" >
        </el-table-column>
        <el-table-column
          prop="remarks"
          label="备注"
          width="80"
          show-overflow-tooltip
          fixed="right"
        >
        </el-table-column>
        <el-table-column label="操作" width="190" fixed="right">
          <template slot-scope="scope">
          <div class="ActionColumn"> 
            <el-link @click="optiobChange('Service',scope)" class="ActionColumn_h"  type="primary">服务编辑</el-link>
            <el-link @click="optiobChange('remarks',scope)" class="ActionColumn_h"  type="primary">设置备注</el-link>
          </div>
          <div class="ActionColumn"> 
            <el-link @click="optiobChange('brand',scope)" class="ActionColumn_h"  type="primary">上传品牌页</el-link>
            <!-- <el-link @click="optiobChange('delay',scope)" class="ActionColumn_h"  type="primary">延期</el-link> -->
          </div>

          <!-- <el-dropdown
              :hide-on-click="hideClick"
              @command="handleCommand($event, scope)"
              :data-scope="scope"
              trigger="click"
            > 
              <span class="el-dropdown-link">
                操作<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="delay" >延期</el-dropdown-item>
                <el-dropdown-item command="Service">服务编辑</el-dropdown-item>
                <el-dropdown-item command="remarks">设置备注</el-dropdown-item>
                <el-popconfirm
                  confirm-button-text="确定"
                  cancel-button-text="取消"
                  icon-color="red"
                  title="确定删除客户吗？"
                  @confirm="optiobChange()"
                >
                  <el-dropdown-item slot="reference" command="del"
                    >删除</el-dropdown-item
                  >
                </el-popconfirm>
              </el-dropdown-menu>
            </el-dropdown> -->
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="getFrom.pageNum"
        :page-sizes="[1, 3, 6, 8]"
        :page-size="getFrom.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>

    </div>

    <!-- 延期操作框 -->
    <el-drawer
      title="修改到期时间"
      :visible.sync="EditTimeShow"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      :size="this.$equipment() ? '100%' : '30%'"
    >
      <div class="demo-drawer_content">
        <div class="drawer-item">
          <label>延期校区:</label>
          <p style="flex: 1; padding-left: 60px; color: red">
            {{ editFrom.storename }}
          </p>
        </div>
        <div class="drawer-item">
          <label>到期时间</label>
          <el-date-picker
            v-model="editFrom.endTime"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
        <div style="text-align: center; margin-top: 25px">
          <el-button
            style="background-color: #5e6cf4; color: #fff"
            @click="submilt()"
          >
            修改</el-button
          >
        </div>
      </div>
    </el-drawer>

    <!-- 备注框 -->
    <el-drawer title="备注" :visible.sync="Remarksdrawer" @close="colseDrawer" v-loading="loadingTab" :size="this.$equipment() ? '100%' : '30%'">
      <div class="From_item">
        <div>内容</div>
        <el-input
          type="textarea"
          :rows="4"
          v-model="RemarksFrom.remarks"
          placeholder="请输入内容"
          style="width: 70%"
        >
        </el-input>
      </div>
      <!-- </from> -->
      <div class="dialog-footer">
        <el-button @click="Remarksdrawer = false">取 消</el-button>
        <el-button type="primary" @click="SubmissionComments()"
          >确 定</el-button
        >
      </div>
    </el-drawer>

    <!-- 服务编辑 -->
    <el-drawer title="服务编辑" :visible.sync="ServiceDrawer" v-loading="loadingTab" :size="this.$equipment() ? '100%' : '30%'">
      <div class="From_item"> 
        <div>定制小程序</div>
        <el-select
          v-model="ServiceFrom.param1"
          placeholder="请选择"
          style="width: 250px; margin-right: 20px"
        >
          <el-option
            v-for="(item, index) in Completed"
            :key="index"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <div class="From_item">
        <div>公众号关联</div>
        <el-select
          v-model="ServiceFrom.param2"
          placeholder="请选择"
          style="width: 250px; margin-right: 20px"
        >
          <el-option
            v-for="(item, index) in Completed"
            :key="index"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <div class="From_item">
        <div>腾讯电子签</div>
        <el-select
          v-model="ServiceFrom.param3"
          placeholder="请选择"
          style="width: 250px; margin-right: 20px"
        >
          <el-option
            v-for="(item, index) in Completed"
            :key="index"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <div class="From_item">
        <div>使用培训</div>
        <el-select
          v-model="ServiceFrom.param4"
          placeholder="请选择"
          style="width: 250px; margin-right: 20px"
        >
          <el-option
            v-for="(item, index) in Completed"
            :key="index"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <div class="From_item">
        <div>数据导入</div>
        <el-select
          v-model="ServiceFrom.param5"
          placeholder="请选择"
          style="width: 250px; margin-right: 20px"
        >
          <el-option
            v-for="(item, index) in Completed"
            :key="index"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <div class="From_item">
        <div>品宣设计</div>
        <el-select
          v-model="ServiceFrom.param6"
          placeholder="请选择"
          style="width: 250px; margin-right: 20px"
        >
          <el-option
            v-for="(item, index) in Completed"
            :key="index"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>

      <div class="dialog-footer">
        <el-button @click="ServiceDrawer = false">取 消</el-button>
        <el-button type="primary" @click="ServiceEditor()">确 定</el-button>
      </div>
    </el-drawer>

    <!-- 上传品牌页 -->
    <el-drawer title="上传品牌页" :visible.sync="Branddrawer" @close="colseDrawer" v-loading="loadingTab" :size="this.$equipment() ? '100%' : '30%'">
      <div class="From_item">
        <div>请上传文件</div>
        <el-upload  
          class="upload-demo" 
          action="" 
          :on-change="handleChange" 
          :on-remove="removeChange" 
          :auto-upload="false"  
          :file-list="fileList" 
          :limit="1"  
          style="width: 70%">
          <el-button size="small" type="primary" style="float: left">点击上传</el-button>
            <div class="el-upload__tip" slot="tip" style="width: 100%;">只能上传1张图片</div>
      </el-upload>
      </div>
      <!-- </from> -->
      <div class="dialog-footer">
        <el-button @click="Branddrawer = false">取 消</el-button>
        <el-button type="primary" @click="BrandComments()"
          >确 定</el-button >
      </div>
    </el-drawer>
  </el-card>
</template>

<script>
import {
  mechanismPageList,
  setEndTime,
  mechanismDel,
  setQrCodel,
  setRemarks,
  serviceUpd,
  afterServiceSearch,
  afterServiceSerpic
} from "@/api/InstitutionSetting";

import {putObject} from "@/util/cos-helper"
export default {
  data() {
    return {
      tableData0: [],
      total: 0, // 表格总数据
      
      // 获取数据表单
      getFrom: {
        remarks: "",
        pageNum: 1,
        pageSize: 6,
        beOpeList:[],
        notOpeList:[],
        comOpeList:[]
      },
      options: [],
      editFrom: {
        storeId: "",
        endTime: "",
        storename: "",
      },
      loadingTab: "",
      EditTimeShow: false,

      // 待操作
      beOperated: [
        "定制小程序",
        "公众号关联",
        "腾讯电子签",
        "演示情况",
        "数据导入",
        "品宣设计",
      ],
      Completed: ["不需要","待操作", "已完成"],

      // 下拉操作
      hideClick: false,
      operationType: "",
      // 单行数据
      scopeRowFrom: {},

      // 备注
      Remarksdrawer: false,
      RemarksFrom: {
        remarks: "",
      },

      // 服务编辑框
      ServiceDrawer: false,
      ServiceFrom:{
        param1:'',
        param2:'',
        param3:'',
        param4:'',
        param5:'',
        param6:'',
      },
      Branddrawer:false,
      fileList:[]
    };
  },
  methods: {
    // 搜索
    // getreviewActivities() {},
    // 获取数据方法
    async getreviewActivities() {
      this.loadingTab = true;
      console.log(this.getFrom);
      const res1 = await mechanismPageList(this.getFrom);
      console.log(res1);
      this.tableData0 = res1.data.list;
      this.total = parseInt(res1.data.total);
      this.loadingTab = false;
    },
    // 监听pageSize事件
    handleSizeChange(newsize) {
      this.getFrom.pageSize = newsize;
      // 重新获取数据
      this.getreviewActivities();
    },

    handleCurrentChange(newpage) {
      this.getFrom.pageNum = newpage;
      // 重新获取数据
      this.getreviewActivities();
    },

    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 1) {
        return "warning-row";
      } else if (rowIndex === 3) {
        return "success-row";
      }
      return "";
    },

    // 延期按钮
    EditTime(item) {
      console.log(item);
      this.EditTimeShow = true;
      this.editFrom.storeId = item.storeId;
      this.editFrom.storename = item.mechanism;
    },

    // 修改到期时间
    async submilt() {
      if (this.editFrom.endTime === "") {
        return this.$message.error("请填写到期时间");
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const res = await setEndTime(this.editFrom);
      // console.log(res);
      if (res.status !== "success")
        return this.$message.error("修改失败,请查看校区是否正确");
      this.getreviewActivities();
      this.$message.success("修改成功");
      this.editFrom.storeId = "";
      this.editFrom.endTime = "";
      this.EditTimeShow = false;
      loading.close();
    },

    // 删除校区
    async registerDialog(item) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      // console.log(item);
      const res = await mechanismDel({ mechanismId: item.mechanismId });
      if (res.status !== "success")
        return this.$message.error("删除,请查看校区是否正确");
      this.getreviewActivities();
      this.$message.success("删除成功");
      this.editFrom.storeId = "";
      this.editFrom.endTime = "";
      this.EditTimeShow = false;
      loading.close();
    },

    // 选择操作
    handleCommand(option, scope) {
      console.log(scope.row);
      this.operationType = option; // 设置操作类型
      this.scopeRowFrom = scope.row;
      if (option != "del") {
        this.hideClick = false; // 非删除、流失操作 自动关闭下拉列表
        this.optiobChange();
      }
    },

    // 执行操作
    optiobChange(type,scope) {
      // const scope = this.operationType;
      this.scopeRowFrom = scope.row;
      switch (type) {
        case "delay":
          console.log("延期");
          this.EditTime(this.scopeRowFrom);
          break;
        case "Service":
          this.ServiceDrawer = true;
          console.log("服务编辑");
          this.ServiceFrom = JSON.parse(JSON.stringify(this.scopeRowFrom)) 
          break;
        case "remarks":
          this.Remarksdrawer = true;
          console.log("备注");
          break;
        case "del":
          console.log("删除");
          this.registerDialog(this.scopeRowFrom);
          break;
        case "brand":
          console.log("品牌页");
          this.Branddrawer = true
          // this.registerDialog(this.scopeRowFrom);
          break;
        default:
          console.log("无此操作");
          break;
      }
    },

    // 搜索
    Selectdata() {
      this.getreviewActivities();
    },

    // 生成二维码
    async GenerateCode(item) {
      console.log(item);
      this.loadingTab = true 
      let res = await setQrCodel({ serviceId: item.serviceId });
     console.log(res);
      if (res.status === "success") {
        this.$message.success("操作成功！");
      } else {
        this.$message.error("操作失败");
      }
      this.getreviewActivities()
      this.loadingTab = false
    },
 
    // 提交备注
    async SubmissionComments() {
      this.RemarksFrom.serviceId = this.scopeRowFrom.serviceId;
      console.log(this.RemarksFrom);
      if(this.RemarksFrom.remarks === ''){
        this.$message.error('请填写内容')
        return
      }
      this.loadingTab = true
      let res = await setRemarks(this.RemarksFrom);
      if (res.status === "success") {
        this.$message.success("操作成功");
      } else {
        this.$message.error("操作失败");
      }
      this.getreviewActivities();
      this.Remarksdrawer = false;
      this.loadingTab = false
      this.RemarksFrom.remarks = "";
    },

    // 关闭备注框
    colseDrawer(){
      this.RemarksFrom.remarks = ""
    },

    // 服务编辑确定
    async ServiceEditor() {
      this.ServiceFrom.serviceId = this.scopeRowFrom.serviceId
      let propsData = {
        serviceId:this.ServiceFrom.serviceId,
        param1:this.ServiceFrom.param1,
        param2:this.ServiceFrom.param2,
        param3:this.ServiceFrom.param3,
        param4:this.ServiceFrom.param4,
        param5:this.ServiceFrom.param5,
        param6:this.ServiceFrom.param6,
      }
      this.loadingTab = true
      let res = await serviceUpd(propsData)

      if (res.status === "success") {
        this.$message.success("操作成功");
      } else {
        this.$message.error("操作失败");
      }
      this.getreviewActivities();
      this.ServiceDrawer = false;
      this.loadingTab = false
      this.ServiceFrom = {};
    },
    // 下拉变化
    selectChange(ind) {
      switch (ind) {
        case 0:
          this.getFrom.beOpeLis = [];
          this.getFrom.notOperList = [];

          break;
        case 1:
          this.getFrom.comOpeList = [];
          this.getFrom.notOperList = [];
          break;
        case 2:
          this.getFrom.comOpeList = [];
          this.getFrom.beOpeLis = [];
          break;
        default:
          break;
      }
    },

    // 文件上传
    handleAvatarSuccess(res, file) {
        console.log(URL.createObjectURL(file.raw));
        this.imageUrl = URL.createObjectURL(file.raw);
    },

    // 上传品牌页
    async BrandComments(){
      console.log(this.scopeRowFrom);
      let fileUrl =''
      console.log(this.fileList.length);
      if(this.fileList.length === 0){
        this.$message.error("请上传图片")
        return
      }
      let data = await putObject(this.fileList[0])
        console.log(data);
      fileUrl = data.data.url
      console.log(fileUrl);
      // return
      let postData = {
        serviceId:this.scopeRowFrom.serviceId,
        picUrl:fileUrl
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res =await afterServiceSerpic(postData)
      loading.close();
      if (res.status === "success") {
        this.$message.success("操作成功");
      } else {
        this.$message.error("操作失败");
      }
      this.fileList = []
      this.Branddrawer = false
      this.getreviewActivities()
    },

    // 文件上传
    handleChange(file, fileList) {
      console.log(fileList);
      this.fileList = fileList;
    },
    removeChange(file, fileList) {
      this.fileList = [];
      console.log(fileList);
      this.deldFalw = true;
    },
  },
 async created() {
    this.getreviewActivities();
    let res = await afterServiceSearch({})
    console.log(res);
    this.beOperated = res.data
  },
};
</script>

<style scoped>
.el-table {
  margin-top: 10px;
}

.drawer-item {
  width: 80%;
  margin: auto;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.drawer-item .el-input {
  width: 68%;
}
input:focus,
textarea:focus {
  outline: none;
}
.el-dropdown-item {
  text-align: center;
}
/* 添加表单样式 */
.From_item {
  width: 90%;
  height: 70rpx;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.From_item div {
  width: 30%;
}
.From_item .el-input,
.From_item .el-select {
  width: 70%;
}
.dialog-footer {
  text-align: center;
  margin-top: 50px;
}
.active_imgs{
  width: 80px;
  height: 80px;
}
</style>