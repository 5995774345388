import requests from './request'

const apis = {
  // 渠道管理 -----------------------
  // 渠道列表 分页
  channelList(data, type = "post") {
    return requests({
      url: '/oam/channel/channelPageList',
      method: type,
      data
    })
  },
  // 渠道列表
  getChannelList(data, type = "post") {
    return requests({
      url: '/oam/channel/channelPageList',
      method: type,
      data
    })
  },
  // 添加渠道
  channelAdd(data, type = "post") {
    return requests({
      url: '/oam/channel/channelAdd',
      method: type,
      data
    })
  },
  // 渠道列表
  staffSaleSearch(data, type = "post") {
    return requests({
      url: '/oam/type/staffSaleSearch',
      method: type,
      data
    })
  },
  // 编辑渠道数据
  channelUpd(data, type = "post") {
    return requests({
      url: '/oam/channel/channelUpd',
      method: type,
      data
    })
  },
  // 添加渠道记录
  channelRecordAdd(data, type = "post") {
    return requests({
      url: '/oam/channel/channelRecordAdd',
      method: type,
      data
    })
  },
  // 删除渠道数据
  channelDel(data, type = "post") {
    return requests({
      url: '/oam/channel/channelDel',
      method: type,
      data
    })
  },
  // 渠道记录数据
  channelRecordPageList(data, type = "post") {
    return requests({
      url: '/oam/channel/channelRecordPageList',
      method: type,
      data
    })
  },
  // 财务审核 --------------------------
  // 审核列表
  financePageList(data, type = "post") {
    return requests({
      url: '/oam/finance/financePageList',
      method: type,
      data
    })
  },
  // 驳回
  financeReturn(data, type = "post") {
    return requests({
      url: '/oam/finance/financeReturn',
      method: type,
      data
    })
  },
  // 修改备注
  financeUpdRemarks(data, type = "post") {
    return requests({
      url: '/oam/finance/financeUpdRemarks',
      method: type,
      data
    })
  },
  // 财审确认
  financeUpdConfirm(data, type = "post") {
    return requests({
      url: '/oam/finance/financeUpdConfirm',
      method: type,
      data
    })
  },
  // 修改合同票据
  financeUpd(data, type = "post") {
    return requests({
      url: '/oam/finance/financeUpd',
      method: type,
      data
    })
  },
  // 修改合同票据
  financeUpd(data, type = "post") {
    return requests({
      url: '/oam/finance/financeUpd',
      method: type,
      data
    })
  },
  // 跟进列表
  financeUpdclueList(data, type = "POST") {
    return requests({
      url: `/oam/homePage/clueList?page=${data.page}&pageSize=${data.pageSize}&staffId=${data.staffId}&content=${data.content}`,
      //url: `/oam/homePage/clueList`,
      method: type,
    })
  },



}




export { apis }