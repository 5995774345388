<template>
  <!-- 迭代记录 -->
  <el-card>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane v-for="item in tabsList" :label="item.name" :name="item.value" :key="item.value"></el-tab-pane>      
    </el-tabs>
    <div v-loading="loadingTab">
      <div class="heard-top" v-show="activeName == 0 || activeName == 1">
        <div style="color: #5e6cf4">待开发</div>
        <el-button
          style="background-color: #5e6cf4; color: #fff"
          @click="addList()">添加</el-button>
      </div>

      <!-- 待开发 -->
      <div v-show="activeName == 0 || activeName == 1">
        <el-table
          :data="tableData0"
          style="width: 100%; margin-bottom: 8px"
          border=""
          :row-style="{ height: '40px' }"
          :cell-style="{ padding: '0', textAlign: 'center' }"
          :header-cell-style="{ textAlign: 'center', backgroundColor: '#f5f7fa', }">
          <el-table-column prop="systemName" label="系统端"> </el-table-column>
          <el-table-column prop="type" label="类型"> </el-table-column>
          <el-table-column prop="content" label="内容"> </el-table-column>
          <el-table-column prop="estimateTime" label="预计完成时间">
          </el-table-column>
          <el-table-column label="操作" width="210">
            <template v-slot="scope">
              <el-row :gutter="0">
                <el-col :span="14">
                  <el-popconfirm
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    icon-color="red"
                    title="确定修改状态吗？"
                    @confirm="paymentByTrasferClick(scope.row)"
                  >
                    <el-button
                      slot="reference"
                      type="success"
                      plain
                      icon="el-icon-check"
                      size="small"
                      >修改状态</el-button
                    >
                  </el-popconfirm>
                </el-col>
                <el-col :span="6">
                  <el-popconfirm
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    icon-color="red"
                    title="确定修改状态吗？"
                    @confirm="registerDialog(scope.row)"
                  >
                    <el-button
                      slot="reference"
                      type="danger"
                      plain
                      icon="el-icon-close"
                      size="small"
                      >删除</el-button
                    >
                  </el-popconfirm>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div style="text-align: center">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="dataFrom.pageNum"
            :page-size="dataFrom.pageSize"
            layout="prev, pager, next, jumper"
            :total="total0"
          >
          </el-pagination>
        </div>
      </div>

      <!-- 待发布 -->
      <div v-show="activeName == 0 || activeName == 2">
        <div class="heard-top" style="margin-top: 10px">
          <div style="color: #5e6cf4">待发布</div>
        </div>
        <el-table
          :data="tableData1"
          style="width: 100%; margin-bottom: 8px"
          border=""
          :row-style="{ height: '40px' }"
          :cell-style="{ padding: '0', textAlign: 'center' }"
          :header-cell-style="{
            textAlign: 'center',
            backgroundColor: '#f5f7fa',
          }"
        >
          <el-table-column prop="systemName" label="系统端"> </el-table-column>
          <el-table-column prop="type" label="类型"> </el-table-column>
          <el-table-column prop="content" label="内容"> </el-table-column>
          <el-table-column prop="lastTime" label="完成时间"> </el-table-column>
          <el-table-column label="操作" width="210">
            <template v-slot="scope">
              <el-row :gutter="0">
                <el-col :span="14">
                  <el-popconfirm
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    icon-color="red"
                    title="确定修改状态吗？"
                    @confirm="paymentByTrasferClick(scope.row)"
                  >
                    <el-button
                      slot="reference"
                      type="success"
                      plain
                      icon="el-icon-check"
                      size="small"
                      >修改状态</el-button
                    >
                  </el-popconfirm>
                </el-col>
                <el-col :span="6">
                  <el-popconfirm
                    confirm-button-text="确定"
                    cancel-button-text="取消"
                    icon-color="red"
                    title="确定修改状态吗？"
                    @confirm="registerDialog(scope.row)"
                  >
                    <el-button
                      slot="reference"
                      type="danger"
                      plain
                      icon="el-icon-close"
                      size="small"
                      >删除</el-button
                    >
                  </el-popconfirm>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div style="text-align: center">
          <el-pagination
            @size-change="handleSizeChange1"
            @current-change="handleCurrentChange1"
            :current-page="dataFrom1.pageNum"
            :page-size="dataFrom1.pageSize"
            layout="prev, pager, next, jumper"
            :total="total1"
          >
          </el-pagination>
        </div>
      </div>

      <!-- 已部署 -->
      <div v-show="activeName == 0 || activeName == 3">
        <div class="heard-top" style="margin-top: 10px">
          <div style="color: #5e6cf4">已部署</div>
        </div>
        <el-table
          :data="tableData2"
          style="width: 100%; margin-bottom: 8px"
          border=""
          :row-style="{ height: '40px' }"
          :cell-style="{ padding: '0', textAlign: 'center' }"
          :header-cell-style="{
            textAlign: 'center',
            backgroundColor: '#f5f7fa',
          }"
        >
          <el-table-column prop="systemName" label="系统端"> </el-table-column>
          <el-table-column prop="type" label="类型"> </el-table-column>
          <el-table-column prop="content" label="内容"> </el-table-column>
          <el-table-column prop="lastTime" label="部署时间"> </el-table-column>
          <el-table-column label="操作" width="210">
            <template v-slot="scope">
              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                icon-color="red"
                title="确定修改状态吗？"
                @confirm="registerDialog(scope.row)"
              >
                <el-button
                  slot="reference"
                  type="danger"
                  plain
                  icon="el-icon-close"
                  size="small"
                  >删除</el-button
                >
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页 -->
        <div style="text-align: center">
          <el-pagination
            @size-change="handleSizeChange2"
            @current-change="handleCurrentChange2"
            :current-page="dataFrom2.pageNum"
            :page-size="dataFrom2.pageSize"
            layout="prev, pager, next, jumper"
            :total="total2"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 修改数据的抽屉 -->
    <el-drawer
      title="添加内容"
      :visible.sync="EditAccDrawer"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      :size="this.$equipment() ? '100%' : '30%'"
    >
      <div class="demo-drawer_content">
        <div class="drawer-item">
          <label>系统端</label>
          <el-select v-model="addFrom.systemName" placeholder="请选择">
            <el-option
              v-for="item in systemList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <div class="drawer-item">
          <label>类型</label>
          <el-select v-model="addFrom.type" placeholder="请选择">
            <el-option
              v-for="item in typeList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>

        <div class="drawer-itemss">
          <label>内容</label>
          <textarea v-model="addFrom.content" placeholder="请输入"></textarea>
        </div>

        <div class="drawer-item">
          <label>预计完成时间</label>
          <el-date-picker
            style="width: 63%"
            v-model="addFrom.estimateTime"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
        <div style="text-align: center; margin-top: 25px">
          <el-button
            style="background-color: #5e6cf4; color: #fff"
            @click="submilt()"
          >
            提交</el-button
          >
        </div>
      </div>
    </el-drawer>

  </el-card>
</template>

<script>
import {
  iterationPageList,
  iterationTypeList,
  iterationSystemList,
  iterationAdd,
  iterationStatusChange,
  iterationDel,
} from "@/api/http";
export default {
  data() {
    return {
      // 标签页
      tabsList: [
        { value: "0", name: "总  览" },
        { value: "1", name: "待开发" },
        { value: "2", name: "待发布" },
        { value: "3", name: "已部署" },
      ],
      activeName: "0",
      // 添加抽屉
      EditAccDrawer: false,
      tableData0: [],
      tableData1: [],
      tableData2: [],
      total0: 0, // 表格总数据
      total1: 0, // 表格总数据
      total2: 0, // 表格总数据

      // 新
      dataFrom: {
        pageNum: 1,
        pageSize: 3,
        status: "0",
      },

      dataFrom1: {
        pageNum: 1,
        pageSize: 3,
        status: "1",
      },
      dataFrom2: {
        pageNum: 1,
        pageSize: 3,
        status: "2",
      },

      systemList: [], //系统列表
      typeList: [], //类型列表
      addFrom: {
        systemName: "",
        type: "",
        content: "",
        estimateTime: "",
      },
      loadingTab: true,
    };
  },
  methods: {
    // 获取表格数据
    async getTableList(index) {
      if (index === -1) {
        for (var i = 0; i < 3; i++) {
          // this.dataFrom.status = i + "";
          if (i === 0) {
            const res = await iterationPageList(this.dataFrom);
            this.tableData0 = res.data.list;
            console.log(this.tableData0);
            this.total0 = parseInt(res.data.total);
          }
          if (i === 1) {
            const res = await iterationPageList(this.dataFrom1);
            this.tableData1 = res.data.list;
            console.log(this.tableData1);
            this.total1 = parseInt(res.data.total);
          }
          if (i === 2) {
            console.log(this.dataFrom2);
            const res = await iterationPageList(this.dataFrom2);
            this.tableData2 = res.data.list;
            console.log(this.tableData2);
            this.total2 = parseInt(res.data.total);
          }
        }
      } else {
        if (index === 0) {
          const res = await iterationPageList(this.dataFrom);
          this.tableData0 = res.data.list;
          this.total0 = parseInt(res.data.total);
        }
        if (index === 1) {
          const res = await iterationPageList(this.dataFrom1);
          this.tableData1 = res.data.list;
          this.total1 = parseInt(res.data.total);
        }
        if (index === 2) {
          const res = await iterationPageList(this.dataFrom2);
          this.tableData2 = res.data.list;
          this.total2 = parseInt(res.data.total);
        }
      }
      this.loadingTab = false;
    },

    // 获取下拉列表数据
    async getdataList() {
      const res1 = await iterationSystemList({});
      const res2 = await iterationTypeList({});
      this.systemList = res1.data;
      this.typeList = res2.data;
    },

    addList() {
      this.EditAccDrawer = true;
    },

    // 标签切换
    handleClick(tab, event) {
      console.log(this.activeName);
      let index = tab.index == 0 ? 3 : 15;
      this.dataFrom.pageSize = index;
      this.dataFrom.pageNum = 1;
      this.dataFrom1.pageSize = index;
      this.dataFrom1.pageNum = 1;
      this.dataFrom2.pageSize = index;
      this.dataFrom2.pageNum = 1;
      this.getTableList(tab.index - 1);
      this.activeName = tab.index;
    },

    // 提交添加内容
    async submilt() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const res = await iterationAdd(this.addFrom);
      if (res.status !== "success") {
        this.$message.error("添加失败");
        return;
      }
      this.$message.success("添加成功");
      this.addFrom.systemName = "";
      this.addFrom.type = "";
      this.addFrom.content = "";
      this.addFrom.estimateTime = "";
      this.EditAccDrawer = false;
      this.getTableList(-1);
      loading.close();
    },

    // 0
    // 监听pageSize事件
    handleSizeChange(newsize) {
      this.dataFrom.pageSize = newsize;
      // 重新获取数据
      this.getTableList(0);
    },
    handleCurrentChange(newpage) {
      this.dataFrom.pageNum = newpage;
      // 重新获取数据
      this.getTableList(0);
    },
    // 1
    // 监听pageSize事件
    handleSizeChange1(newsize) {
      this.dataFrom1.pageSize = newsize;
      // 重新获取数据
      this.getTableList(1);
    },
    handleCurrentChange1(newpage) {
      this.dataFrom1.pageNum = newpage;
      // 重新获取数据
      this.getTableList(1);
    },
    // 2
    // 监听pageSize事件
    handleSizeChange2(newsize) {
      this.dataFrom2.pageSize = newsize;
      // 重新获取数据
      this.getTableList(2);
    },
    handleCurrentChange2(newpage) {
      this.dataFrom2.pageNum = newpage;
      // 重新获取数据
      this.getTableList(2);
    },

    // 状态修改1
    async paymentByTrasferClick(item) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const res = await iterationStatusChange({
        iterationId: item.iterationId,
      });
      if (res.status !== "success") {
        this.$message.error("修改失败");
        return;
      }
      this.$message.success("修改成功");
      this.getTableList(-1);
      loading.close();
    },

    // 删除任务
    async registerDialog(item) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const res = await iterationDel({
        iterationId: item.iterationId,
      });
      if (res.status !== "success") {
        this.$message.error("删除失败");
        return;
      }
      this.$message.success("删除成功");
      this.getTableList(-1);
      loading.close();
    },
  },
  created() {
    this.getTableList(-1);
    this.getdataList();
  },
};
</script>

<style scoped>
.heard-top {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(192, 190, 190);
  margin-bottom: 10px;
}

/* 抽屉样式 */
.drawer-item {
  width: 90%;
  margin: auto;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.drawer-item .el-select,
.drawer-item .el-date-picker {
  width: 63%;
}
.drawer-itemss {
  width: 90%;
  height: auto;
  margin: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}
.drawer-itemss textarea {
  width: 63%;
  height: 80px;
  border: 1px solid #d9dbdd;
  resize: none;
  border-radius: 5px;
  padding: 8px;
  box-sizing: border-box;
}
input:focus,
textarea:focus {
  outline: none;
}
</style>