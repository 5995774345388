// 当前这个模块：API进行统一的管理
import requests from "./request";

let heards = {
    'Content-Type': 'application/json',
    'token': window.sessionStorage.getItem("token")
}
// 登录请求方法
export const LoginApi = (query1) => requests({
    // url: '/oam/user/checkLogin',
    url: '/oam/user/login', // TODO 动态路由 更改了登陆地址
    method: "post",
    data: query1
});

// 首页
// export const HomeList = (query1) => requests({
//     url: '/oam/OAMRecord/homePage',
//     method: "post",
//     data: query1,
//     headers: {
//         'Content-Type': 'application/json',
//         'token': window.sessionStorage.getItem("token")
//     }
// });

// 迭代记录
// 获取数据
export const iterationPageList = (query1) => requests({
    url: '/oam/OAMIteration/iterationPageList',
    method: "post",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});
// 获取系统端可选列表
export const iterationSystemList = (query1) => requests({
    url: '/oam/OAMIteration/iterationSystemList',
    method: "post",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});
// 获取系统端可选列表
export const iterationTypeList = (query1) => requests({
    url: '/oam/OAMIteration/iterationTypeList',
    method: "post",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});
// 添加迭代记录
export const iterationAdd = (query1) => requests({
    url: '/oam/OAMIteration/iterationAdd',
    method: "post",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});
// 迭代状态改变
export const iterationStatusChange = (query1) => requests({
    url: '/oam/OAMIteration/iterationStatusChange',
    method: "post",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});

// 迭代删除
export const iterationDel = (query1) => requests({
    url: '/oam/OAMIteration/iterationDel',
    method: "post",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});

// 机构设置------------------------------------------------
export const mechanismPageList = (query1) => requests({
    url: '/oam/mechanism/mechanismPageList',
    method: "post",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});
// 修改到到期时间
export const setEndTime = (query1) => requests({
    url: '/oam/signContract/setEndTime',
    method: "post",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});
// 删除机构
export const mechanismDel = (query1) => requests({
    url: '/oam/mechanism/mechanismDel',
    method: "post",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});

// 数据导入---------------------------------
export const uploadPageList = (query1) => requests({
    url: '/oam/OAMUpload/uploadPageList',
    method: "post",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});

// 修改状态
export const uploadDel = (query1) => requests({
    url: '/oam/OAMUpload/uploadDel',
    method: "post",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});


// // 品牌搜索
// export const brandList = (query1) => requests({
//     url: 'OAMUpload/brandList',
//     method: "post",
//     data: query1,
//     headers: heards
// });


// 品牌设计-------------------------------------

export const designPageList = (query1) => requests({
    url: '/oam/OAMDesign/designPageList',
    method: "post",
    data: query1,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
});


// 改变状态
export const uploadStatusChange = (obj) => requests({
    url: "/oam/OAMUpload/uploadStatusChange",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
})
