<template>
  <div class="APP">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {
    
  },
  data(){
    return {
      zoom: 1,
    };
  },
  mounted() {
    // window.onresize = () => {
    //   return (() => {
    //     // 基于 1920 宽度，监听当前浏览器视图的宽度进行页面大小缩放
    //     console.log(document.body);
    //     // this.zoom = document.body.clientWidth >= 1920 ? 1 : document.body.clientWidth/1920
    //     this.zoom = window.screen.width >= 1920 ? 1 : window.screen.width/1920
    //   })();
    // };
  },
  methods: {

  },
};
</script>


<style>
* {
  margin: 0px;
  padding: 0px;
}
.APP{
  height: 100vh;
}
/* 操作按钮样式 */
.ActionColumn{
  margin: auto; 
  text-align: left; 
} 
.ActionColumn .ActionColumn_h{  
  margin-right: 15px; 
  font-size: 14px;  
}
.ActionColumn .ActionColumn_h:hover{
  text-decoration: underline;
  font-weight: bold;
}
</style>
