/**
 * 格式日期 年-月-日
 * @param {number} date
 * @returns {string}
 */
export function formatYearMonthDay(date) {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    return [year, month, day].map(formatNumber).join('-')
}
export function formatYearMonthDayHourMinute(date) {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hour = date.getHours()
    const minute = date.getMinutes()
    return [year, month, day].map(formatNumber).join('-') + ' ' + [hour, minute].map(formatNumber).join('_')
}
const formatNumber = n => {
    n = n.toString()
    return n[1] ? n : '0' + n
}
