// 当前这个模块：API进行统一的管理
import requests from "./request";

export const financeuserList = (obj) => requests({
    url: "/financeReimbursement/userList?page=2&pageSize=2",
    method: "get",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
})

// 最新内容值班表内容

export const getDutyData = (obj) => requests({
    url: "/oam/OAMDuty/getDutyList",
    method: "get",
    params: obj,
    // headers: {
    //     'Content-Type': 'application/json',
    //     'token': window.sessionStorage.getItem("token")
    // }
})
// 值班表-值班记录列表（分页） 

export const dutyPageList = (obj) => requests({
    url: "/oam/OAMDuty/dutyPageList",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
})


// 值班表-售前列表
export const dutyPreSalesList = (obj) => requests({
    url: "/oam/OAMDuty/dutyPreSalesList",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
})


// 值班表-售后列表
export const dutyAfterSalesList = (obj) => requests({
    url: "/oam/OAMDuty/dutyAfterSalesList",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
})

// 值班表-技术列表
export const dutyIterationList = (obj) => requests({
    url: "/oam/OAMDuty/dutyIterationList",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
})

// 值班表-添加值班记录
export const dutyAdd = (obj) => requests({
    url: "/oam/OAMDuty/dutyAdd",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
})

// 值班表-删除值班记录
export const dutyDel = (obj) => requests({
    url: "/oam/OAMDuty/dutyDel",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
})

// 运维记录-运维记录列表（分页）
export const recordPageList = (obj) => requests({
    url: "/oam/OAMRecord/recordPageList",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
})

// 需求记录-需求记录列表（分页）
export const demandPageList = (obj) => requests({
    url: "/oam/OAMDemand/demandPageList",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
})

// 需求记录-需求类型
export const demandTypeList = (obj) => requests({
    url: "/oam/OAMDemand/demandTypeList",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
})

// 需求记录-添加需求记录
export const demandAdd = (obj) => requests({
    url: "/oam/OAMDemand/demandAdd",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
})

// 需求记录-改变状态
export const demandStatusChange = (obj) => requests({
    url: "/oam/OAMDemand/demandStatusChange",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
})

// 需求记录-删除需求记录
export const demandDel = (obj) => requests({
    url: "/oam/OAMDemand/demandDel",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
})

// 品牌搜索
export const brandList = (obj) => requests({
    url: "/oam/OAMUpload/brandList",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
})

// 机构列表
export const mechanismPageList = (obj) => requests({
    url: "/oam/mechanism/mechanismPageList",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
})

// 数据导入-添加导入记录
export const uploadAdd = (obj) => requests({
    url: "/oam/OAMUpload/uploadAdd",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
})

// 品牌设计-添加品牌设计需求
export const designAdd = (obj) => requests({
    url: "/oam/OAMDesign/designAdd",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
})

// 品牌设计-品牌设计确认
export const designDel = (obj) => requests({
    url: "/oam/OAMDesign/designDel",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
})


// 月度评价
export const evaluatePageList = (obj) => requests({
    url: "/oam/evaluate/evaluatePageList",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
        // 'token': "asddd"
    }
})

// 品牌设计-删除已设计文件
export const designDelete = (obj) => requests({
    url: "/oam/OAMDesign/designDel",
    method: "post",
    data: obj,
    headers: {
        'Content-Type': 'application/json',
        'token': window.sessionStorage.getItem("token")
    }
})


