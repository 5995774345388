import requests from "./request";

/**
 * 
 * 渠道市场
 * 
 */
// 渠道字典

// 渠道等级
export const channelGradeSearch = (query1) => requests({
    url: '/oam/type/channelGradeSearch',
    method: "post",
    data: query1,
    // headers: {
    //     'Content-Type': 'application/json',
    //     'token': window.sessionStorage.getItem("token")
    // }
});


// 渠道状态
export const channelStatusSearch = (query1) => requests({
    url: '/oam/type/channelStatusSearch',
    method: "post",
    data: query1,
    // headers: {
    //     'Content-Type': 'application/json',
    //     'token': window.sessionStorage.getItem("token")
    // }
});

// 渠道列表
export const channelListSearch = (query1) => requests({
    url: '/oam/type/channelListSearch',
    method: "post",
    data: query1,
});


// 负责人
export const staffSaleSearch = (query1) => requests({
    url: '/oam/type/staffSaleSearch',
    method: "post",
    data: query1,
});


// 意向清空塞查
export const clueIntentionSearch = (query1) => requests({
    url: '/oam/type/clueIntentionSearch',
    method: "post",
    data: query1,
});


// 联系人身份筛查
export const clueIdentitySearch = (query1) => requests({
    url: '/oam/type/clueIdentitySearch',
    method: "post",
    data: query1,
});

// 售后服务筛查
export const afterServiceSearch = (query1) => requests({
    url: '/oam/type/afterServiceSearch',
    method: "post",
    data: query1,
});

// 套餐列表
export const signContractStageList = (query1) => requests({
    url: '/oam/signContract/signContractStageList',
    method: "post",
    data: query1,
});



// __________________________________________
/**
 * 线索跟进
 */
// 获取数据
export const cluePageList = (query1) => requests({
    url: '/oam/clue/cluePageList',
    method: "post",
    data: query1,
}); 

// 线索新增
export const clueAdd = (query1) => requests({
    url: '/oam/clue/clueAdd',
    method: "post",
    data: query1,
}); 
// 编辑线索
export const clueUpd = (query1) => requests({
    url: '/oam/clue/clueUpd',
    method: "post",
    data: query1,
}); 
// 线索跟进
export const clueRecordAdd = (query1) => requests({
    url: `/oam/clue/clueRecordAdd`,
    method: "post",
    data: query1,
}); 

// 批量线索跟进
export const clueBatchRecordAdd = (query1) => requests({
    url: `/oam/clue/clueBatchRecordAdd`,
    method: "PUT",
    data: query1,
}); 

// 记录列表
export const clueRecordPageList = (query1) => requests({
    url: '/oam/clue/clueRecordPageList',
    method: "post",
    data: query1,
}); 

// 线索删除
export const clueDel = (query1) => requests({
    url: '/oam/clue/clueDel',
    method: "post",
    data: query1,
}); 

// 成交
export const financeAdd = (query1) => requests({
    url: '/oam/finance/financeAdd',
    method: "post",
    data: query1,
}); 


/**
 * 
 * 成交转态
 */
 export const dealFinancePageList = (query1) => requests({
    url: `/oam/finance/financePageList`,
    method: "POST",
    data: query1,
}); 

